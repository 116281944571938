import React, { ReactNode, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from '../ErrorFallback/ErrorFallback'
import { newrelicErrLogger } from '../../utils/ErrorHandler'
import { useStyles } from './RoutingApp.styles'
import HeaderRoutingApp from '../HeaderRoutingWrapper/HeaderRoutingWrapper'
import { useFlag } from '@labrav/flags'

interface RoutingAppProps extends Partial<ReactChildrenProps> {
  title?: string | ReactNode
  titleIconOrBadge?: React.FC
  actions?: React.FC
  component?: React.FC
  isSidePanelOpened?: boolean
  subHeading?: string
  isBack?: boolean
  isExternal?: boolean
  customNavButton?: CustomNavButtonProp
  onBack?: () => void
  paddingZero?: boolean
}
export type CustomNavButtonProp = {
  ButtonName: string
  link: any
  shouldShowCustomNavButton: boolean
}
export const WithErrorBoundary: React.FC<ReactChildrenProps> = (
  props: ReactChildrenProps
) => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={newrelicErrLogger}>
    {props.children}
  </ErrorBoundary>
)

const LayoutWrapper: React.FC<RoutingAppProps> = ({
  title,
  titleIconOrBadge,
  actions: Actions,
  component: Component,
  children,
  isSidePanelOpened = false,
  subHeading,
  isBack = false,
  isExternal = false,
  customNavButton,
  onBack,
  paddingZero,
}) => {
  const { flag: isLabraUiRevampEnable } = useFlag('labraUiRevamp')
  const classes = useStyles(
    isSidePanelOpened,
    isExternal,
    isLabraUiRevampEnable?.value,
    paddingZero
  )()
  const [pageYOffset, setPageYOffset] = useState(0)
  const onScrollValue = (pageYOffsetValue: number) => {
    setPageYOffset(pageYOffsetValue)
  }
  return (
    <div className={classes.root}>
      <div
        className={classes.content}
        onScroll={(e: any) => onScrollValue(e.target.scrollTop)}
        id="content-id"
      >
        <HeaderRoutingApp
          title={title}
          actions={Actions}
          pageYOffset={pageYOffset}
          subHeading={subHeading}
          isBack={isBack}
          customNavButton={customNavButton}
          onBack={onBack}
          isExternal={isExternal}
          titleIconOrBadge={titleIconOrBadge}
        />

        <div data-testid="app-body" className={classes.appBody}>
          {Component ? (
            <WithErrorBoundary>
              <Component />
            </WithErrorBoundary>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  )
}

const NoHeaderLayoutWrapper: React.FC<RoutingAppProps> = ({
  component: Component,
  children,
  isSidePanelOpened = false,
  isExternal = false,
}) => {
  const classes = useStyles(isSidePanelOpened, isExternal)()
  return (
    <div className={classes.root}>
      <div className={classes.content} id="content-id">
        <div data-testid="app-no-body">
          {Component ? (
            <WithErrorBoundary>
              <Component />
            </WithErrorBoundary>
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  )
}

export { LayoutWrapper, NoHeaderLayoutWrapper }
