import React, { useEffect, useState } from 'react'
import { UserRoleForTable, UserRole } from '../../utils/roles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from './AddUserForm.styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import CancelIcon from '@material-ui/icons/Cancel'
import { validate as isValidEmail } from 'email-validator'
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import {
  addRowForAddUser,
  addUserErrorData,
  hideAddUserForm,
  removeRowForAddUser,
  showAddUserForm,
  submitAddNewUsersData,
  updateRowForAddUser,
} from '../../modules/AddUserForm/actions'
import { useDispatch, useSelector } from '../../../store'
import {
  AddNewUsersState,
  ErrorUsers,
  HandleType,
  UserType,
} from '../../modules/AddUserForm/reducer'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { ButtonV2, PopoverHover } from '@labrav/react-components'
import { ArrayReducerRow } from '../../modules/arrayReducer'
import { extractData } from '../../modules/arrayReducer/utils'
import { useFlagValue } from '@labrav/flags'
import { AuthProvider } from '../../../oppsync/modules/userList/action'
import { isEmpty } from 'lodash'
import { isAtleastAndAtMostOneOwner } from '../../utils/isAtleastAndAtMostOneOwner'
import { LoadingTypes } from '../../modules/loading/reducer'
import { isLoading } from '../../utils/loadingState'
import { usePartner } from '../../utils/Hooks/hooks'
import { AuthProtocol, PartnerData } from '../../modules/partner/action'
import { ssoInviteDisableMessage } from '../../utils/messagesContants'

export const AddUserFormComponent = ({
  isAdmin = false,
  callBackAfterSuccess,
}: {
  isAdmin?: boolean
  callBackAfterSuccess?: () => void
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const RoleSelection = isAdmin ? UserRoleForTable : UserRole
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { loading: isFlagsLoading, value: auth0Enabled } = useFlagValue('auth0')
  const [errMsg, setErrMsg] = useState('')
  const { openForm, errors, loading } = useSelector<AddNewUsersState>(
    state => state.addNewUsers.metadata
  )
  const users = useSelector<ArrayReducerRow<UserType>[]>(
    state => state.addNewUsers.users
  )

  const [openDisabledMessage, setOpenDisabledMessage] = useState<boolean>(false)

  const submitLoading = useSelector<boolean>(state =>
    isLoading(state.loading)(LoadingTypes.ADD_NEW_USER)
  )

  const partnerData = useSelector<PartnerData | null>(
    state => state.PartnerData.user?.partnerData
  )
  const { partnerId, authProtocol } = partnerData || {}

  const canAddNewUser = isAdmin ? true : authProtocol === AuthProtocol.PASSWORD
  const handleOpen = () => {
    dispatch(showAddUserForm())
  }
  const handleClose = () => {
    dispatch(hideAddUserForm())
  }

  const validateForm = (
    newUsers: ArrayReducerRow<UserType>[],
    touched: boolean
  ) => {
    dispatch(addUserErrorData([]))
    const newErrorArray: ErrorUsers[] = []
    newUsers.map(({ data: { email } }, i) => {
      if (!isValidEmail(email)) {
        newErrorArray.push({
          index: i,
          error: 'invalid email',
          touched: touched,
        })

        return dispatch(addUserErrorData(newErrorArray))
      }
    })
  }
  // handle input change
  const handleInputChange = async (event: HandleType, idChanging: string) => {
    const updatedUser = users.find(({ id }) => id === idChanging)
    if (!updatedUser) {
      console.error(
        'updating user with id: ',
        idChanging,
        ' not possible because id was not found'
      )
      return
    }
    await dispatch(
      updateRowForAddUser(idChanging, {
        ...updatedUser.data,
        [event.name]: event.value,
      })
    )
  }

  // handle click event of the Remove button
  const handleRemoveClick = async (id: string) => {
    await dispatch(removeRowForAddUser(id))
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    dispatch(addRowForAddUser())
  }

  const renderRoles = Object.entries(RoleSelection).map(([key, value]) => (
    <MenuItem key={key} value={key}>
      {value}
    </MenuItem>
  ))

  const onSubmit = () => {
    dispatch(addUserErrorData([]))
    const isOwnerAdded = isAtleastAndAtMostOneOwner(users)
    if (!isOwnerAdded && isAdmin) {
      setErrMsg('Please add at least and at most one owner.')
    } else {
      setErrMsg('')
      if (partnerId) {
        dispatch(
          submitAddNewUsersData(
            extractData(users),
            partnerId,
            auth0Enabled ? AuthProvider.AUTH0 : AuthProvider.OKTA,
            isAdmin,
            callBackAfterSuccess
          )
        )
      }
    }
  }

  useEffect(() => {
    validateForm(users, false)
    if (isAdmin && !isEmpty(errMsg)) {
      const isOwnerAdded = isAtleastAndAtMostOneOwner(users)
      if (isOwnerAdded) {
        setErrMsg('')
      }
    }
  }, [users])

  const disableSubmit = errors.length > 0 || submitLoading

  if (isFlagsLoading) return <></>

  return (
    <div>
      <div className={classes.buttonGroup}>
        {!canAddNewUser ? (
          <PopoverHover
            tooltipPlacement="left-start"
            label={''}
            description={ssoInviteDisableMessage}
            open={openDisabledMessage}
            setOpen={setOpenDisabledMessage}
          >
            <AddNewUserButton
              isAddNewUserButtonDisabled={true}
              handleOpen={handleOpen}
            />
          </PopoverHover>
        ) : (
          <AddNewUserButton
            isAddNewUserButtonDisabled={false}
            handleOpen={handleOpen}
          />
        )}
      </div>
      <Dialog
        open={openForm}
        fullScreen={fullScreen}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        data-testid="add-user-form"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle data-testid="header-text">
          <Typography className={classes.dialogHeading}>
            {isAdmin ? 'Create partner & invite users' : 'Add user(s)'}
          </Typography>

          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.content}>
          {loading && <ProgressBar width={'100%'} />}
          <Typography variant="body2" className={classes.paragraph}>
            Please enter email address of the user(s) you want to invite.
          </Typography>
          <form autoComplete="off" data-testid="add-new-user-form">
            {users.map(({ id, data: user }, i) => {
              return (
                <div key={id} data-testid="add-user-row" className="box">
                  <Grid container spacing={3}>
                    <Grid item sm={12} md={7} style={{ width: '100%' }}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          autoFocus
                          name="email"
                          label="Email"
                          placeholder="Enter email"
                          margin="dense"
                          value={user.email}
                          onChange={e =>
                            handleInputChange(
                              { name: e.target.name, value: e.target.value },
                              id
                            )
                          }
                          className={classes.textFields}
                          required
                          inputProps={{ 'data-testid': 'input-email' }}
                          error={
                            errors.find(
                              ({ index, touched }) => index === i && touched
                            )?.error
                              ? true
                              : false
                          }
                          helperText={
                            errors.find(
                              ({ index, touched }) => index === i && touched
                            )?.error
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      md={5}
                      style={{ width: '100%', display: 'flex' }}
                    >
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          className={classes.selectLabel}
                          htmlFor="select-role"
                        >
                          Select a role
                        </InputLabel>
                        <Select
                          labelId="select-role"
                          inputProps={{
                            'data-testid': 'select-role',
                            'data-testvalue': user.role,
                            name: 'role',
                            id: 'select-role',
                          }}
                          value={user.role}
                          onChange={e =>
                            handleInputChange(
                              {
                                name: e.target.name as string,
                                value: e.target.value as string,
                              },
                              id
                            )
                          }
                          className={classes.select}
                        >
                          {renderRoles}
                        </Select>
                      </FormControl>
                      {users.length !== 1 && (
                        <>
                          <IconButton
                            className={classes.timesIcon}
                            onClick={() => handleRemoveClick(id)}
                            data-testid="remove-btn"
                          >
                            <CancelIcon fontSize="small" />
                          </IconButton>
                          <Divider className={classes.divider} />
                        </>
                      )}
                    </Grid>
                  </Grid>
                  {users.length !== 1 && (
                    <Divider className={classes.divider} />
                  )}
                  <div className="btn-box">
                    {users.length - 1 === i && (
                      <Link
                        component="button"
                        variant="body2"
                        onClick={handleAddClick}
                        className={
                          errors.length === 0
                            ? classes.linkBtn
                            : classes.linkBtnDisabled
                        }
                        data-testid="add-one-more-user-btn"
                        disabled={errors.length !== 0}
                      >
                        Add more users
                      </Link>
                    )}
                  </div>
                </div>
              )
            })}
          </form>
          {errMsg ? (
            <div className={classes.errorMessage}>{errMsg}</div>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions className={classes.actionBtns}>
          <ButtonV2
            styleType={'primary'}
            disabled={disableSubmit}
            type="submit"
            data-testid="save-btn"
            onClick={onSubmit}
          >
            {!loading ? 'Invite' : 'Inviting'}
          </ButtonV2>
          <ButtonV2
            onClick={handleClose}
            styleType={'outlined'}
            data-testid="cancel-btn"
          >
            Cancel
          </ButtonV2>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export interface AddNewUserButtonProps {
  isAddNewUserButtonDisabled: boolean
  handleOpen: () => void
}
export const AddNewUserButton = React.memo(
  ({ isAddNewUserButtonDisabled, handleOpen }: AddNewUserButtonProps) => {
    return (
      <ButtonV2
        styleType="primary"
        onClick={handleOpen}
        data-testid="add-user"
        disabled={isAddNewUserButtonDisabled}
      >
        <FontAwesomeIcon
          icon={faUserPlus}
          className="font-awesome-button-icon"
        />
        Add user
      </ButtonV2>
    )
  }
)
