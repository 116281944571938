import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  flyOutOnboardingCompletedSuccessfully,
  RequestFailureMessage,
} from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { camelize, snakeize } from 'casing'
import {
  fetchSellerData,
  getFlyOutOnboardingFormData,
  putOnboardingFormData,
} from '../../api/markeplace'
import { getErrorMessages } from '../../../common/utils/error'
import { errorLogger } from '../../../common/utils/errorLogger'
import { AppDispatch, RootState } from '../../../store'
import flyoutOnboardingFormData from '../../../mocks/flyouOnboardingMockData.json'
import { get, isEmpty, isEqual } from 'lodash'
import {
  FlyoutOnboardingPartnershipType,
  FlyoutOnboardingSectionName,
  Sections,
  TestConnectionStatusType,
} from './reducer'
import { CloudType } from '../../../common/modules/types'
import { FlyOutListingUserType } from '../../../admin/modules/flyOutSyncs/reducer'
import {
  PartnerData,
  PartnerType,
  updatePartner,
} from '../../../common/modules/partner/action'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'
import { getFlyoutListingData } from '../../../admin/modules/flyOutSyncs/actions'
import { getPageMarkerMapping } from '../../../common/utils/getPageMarkerMapping'
import { autoSaveThresoldTime } from '../../../common/utils/constants'

export enum FlyoutOnboardingForm {
  SET_FLYOUT_ONBOARDING_FORM = 'SET_FLYOUT_ONBOARDING_FORM',
  UPDATE_FLYOUT_ONBOARDING_FORM = 'UPDATE_FLYOUT_ONBOARDING_FORM',
  UPDATE_FLYOUT_TEST_CONNECTION_STATUS = 'UPDATE_FLYOUT_TEST_CONNECTION_STATUS',
  UPDATE_FLYOUT_CAS_TEST_CONNECTION_STATUS = 'UPDATE_FLYOUT_CAS_TEST_CONNECTION_STATUS',
}

export const getFlyOutOnboardingData =
  (partnerId: string, cloudType: CloudType) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.FLYOUT_ONBOARDING_DATA))
    try {
      const { data } = await getFlyOutOnboardingFormData(partnerId)
      const camelizedData = get(camelize(data), 'onboardingData') || {}
      if (isEmpty(camelizedData)) {
        await dispatch(
          setOnboardingFormData({ ...camelize(flyoutOnboardingFormData) })
        )
      } else {
        const updatedOnboardingData = JSON.parse(
          JSON.stringify(camelizedData).replaceAll('\\\\"', '"')
        )
        const updatedOnboardingJson = {
          ...updatedOnboardingData.details,
          lockMetadata: {
            ...updatedOnboardingData.lockMetadata,
          },
        }
        
        const user = getState().userProfile
        const pageMarkerMapping: Record<string, string> = getPageMarkerMapping()
        const timeDiff = Math.ceil(
          DateTime.now().diff(
            updatedOnboardingJson?.lockMetadata?.lastActivityAt
              ? DateTime.fromISO(
                  updatedOnboardingJson?.lockMetadata?.lastActivityAt
                )
              : DateTime.now(),
            ['seconds']
          ).seconds
        )
        if (
          timeDiff > autoSaveThresoldTime ||
          isEmpty(updatedOnboardingJson?.lockMetadata?.lastActivityAt)
        ) {
          const newWindowId = uuidv4()
          window.name = newWindowId
          updatedOnboardingJson.lockMetadata = {
            ...updatedOnboardingJson.lockMetadata,
            windowId: newWindowId,
            lockedBy: user.userProfile.id,
            lockedByName: `${user.userProfile.firstName} ${user.userProfile.lastName}`,
            lockByEmail: user.userProfile.email,
            lockAcquisitionProbe: true,
          }
          await dispatch(
            sendFlyOutOnboardingData({
              partnerId,
              isAutoSave: true,
              onboardingForm: updatedOnboardingJson,
              pageMarkerMapping,
              page: FlyoutOnboardingSectionName.LABRA_PARTNER_DETAILS,
              status: 'lockAcquire',
            })
          )
        } else {
          await dispatch(setOnboardingFormData({ ...updatedOnboardingJson }))
        }
      }
    } catch (error) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: false,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.FLYOUT_ONBOARDING_DATA))
    }
  }

export const setOnboardingFormData = (
  onboardingFormData: Record<string, unknown>
) => ({
  type: FlyoutOnboardingForm.SET_FLYOUT_ONBOARDING_FORM,
  payload: onboardingFormData,
})
export type FlyOutOnboardingChangeType = {
  sectionName: FlyoutOnboardingSectionName
  keyName: string
  value: string | boolean
}
export const updateFlyOutOnboardingData = (
  data: FlyOutOnboardingChangeType
) => ({
  type: FlyoutOnboardingForm.UPDATE_FLYOUT_ONBOARDING_FORM,
  payload: data,
})

interface SendFlyoutOnboardingProps {
  isAutoSave: boolean
  onboardingForm: Sections
  pageMarkerMapping: Record<string, string>
  page: FlyoutOnboardingSectionName
  partnerId: string
  status?: string
  callBackFlyoutOnboarding?: () => void
}

const isChangeInOnboardingData = (
  onboardingData: any,
  getOnboardingStateData: Sections
) => {
  const { lockMetadata: metaData, ...restOnboardingData } = onboardingData
  const { lockMetadata, ...restGetOnboardingStateData } = getOnboardingStateData
  return !isEqual(restOnboardingData, restGetOnboardingStateData)
}

export const sendFlyOutOnboardingData =
  ({
    partnerId,
    isAutoSave,
    onboardingForm,
    pageMarkerMapping,
    page,
    status,
    callBackFlyoutOnboarding,
  }: SendFlyoutOnboardingProps) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      !isAutoSave &&
        (await dispatch(startLoading(LoadingTypes.SUBMIT_FLYOUT_ONBOARDING)))
      const getOnboardingStateData =
        getState()?.flyOutOnboarding?.onboardingForm
      const isFormUpdated = isChangeInOnboardingData(
        onboardingForm,
        getOnboardingStateData
      )

      const onboardingProcessedData = processFlyoutOnboarding({
        isAutoSave,
        onboardingForm,
        page,
        pageMarkerMapping,
        status,
        isFormUpdated,
      })
      const onboardingPayload = JSON.parse(
        JSON.stringify(onboardingProcessedData).replaceAll('\\"', '\\\\\\"')
      )
      if (
        page === FlyoutOnboardingSectionName.LABRA_PARTNER_DETAILS &&
        !isAutoSave
      ) {
        await processPartnerDetailsData(partnerId, onboardingForm, dispatch)
      }
      const response = await putOnboardingFormData({
        partnerId,
        data: onboardingPayload,
      })
      if (response) {
        const camelizedOnboardingData = JSON.parse(
          JSON.stringify(get(camelize(response), 'data') || {}).replaceAll(
            '\\\\"',
            '"'
          )
        )
        const updatedOnboardingJson = {
          ...camelizedOnboardingData.details,
          lockMetadata: {
            ...camelizedOnboardingData.lockMetadata,
          },
        }
        if (!isAutoSave && updatedOnboardingJson.completed?.value) {
          await dispatch(
            updateAppAlert({
              message: flyOutOnboardingCompletedSuccessfully,
              messageType: 'SUCCESS',
              autoClose: true,
            })
          )
        }

        await dispatch(setOnboardingFormData(updatedOnboardingJson))

        if (status === 'lockAcquire' && callBackFlyoutOnboarding) {
          callBackFlyoutOnboarding()
        }

        if (updatedOnboardingJson.completed?.value) {
          setTimeout(async () => {
            await dispatch(getFlyoutListingData({ partnerId }))
          }, 1000)
        }
      }
    } catch (error: any) {
      if (!isAutoSave) {
        dispatch(
          updateAppAlert({
            message: getErrorMessages([RequestFailureMessage])(
              error.response as AxiosResponse<ErrorResponse>
            ),
            messageType: 'ERROR',
            autoClose: false,
          })
        )
        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      } else {
        return error
      }
    } finally {
      !isAutoSave &&
        (await dispatch(stopLoading(LoadingTypes.SUBMIT_FLYOUT_ONBOARDING)))
    }
  }

const processFlyoutOnboarding = ({
  isAutoSave,
  onboardingForm,
  pageMarkerMapping,
  page,
  status,
  isFormUpdated,
}: {
  isAutoSave: boolean
  onboardingForm: Sections
  pageMarkerMapping: Record<string, string>
  page: FlyoutOnboardingSectionName
  status?: string
  isFormUpdated?: boolean
}) => {
  return snakeize({
    ...onboardingForm,
    lockMetadata: {
      ...onboardingForm.lockMetadata,
      ...(isFormUpdated || status === 'lockAcquire'
        ? { lastActivityAt: DateTime.now().toUTC().toISO() }
        : {}),
      editingEnabled: status === 'lockAcquire',
    },
    ...(!isAutoSave && {
      pageMarker: {
        ...onboardingForm.pageMarker,
        [camelize(pageMarkerMapping[page])]: true,
      },
      completed: {
        value: checkAllCompleted({
          pageMarker: onboardingForm[FlyoutOnboardingSectionName.PAGE_MARKER],
          page,
          isManaged:
            onboardingForm[FlyoutOnboardingSectionName.LABRA_PARTNER_DETAILS]
              .isManaged,
        }),
      },
    }),
  })
}

export const checkAllCompleted = ({
  pageMarker,
  page,
  isManaged,
}: {
  page: FlyoutOnboardingSectionName
  pageMarker: Record<string, boolean>
  isManaged?: boolean
}) => {
  if (page === FlyoutOnboardingSectionName.LABRA_PARTNER_DETAILS) {
    return isManaged || pageMarker.page2
  } else if (page === FlyoutOnboardingSectionName.CLOUD_SETTINGS) {
    return pageMarker.page1
  }
}

export const flyOutTestConnection =
  (
    partnerId: string,
    cloudRoleArn: string,
    updateFlyoutTestConnection: (status: TestConnectionStatusType) => void
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await updateFlyoutTestConnection('loading')
    try {
      const { data } = await fetchSellerData({
        partnerId,
        metadata: {
          ...snakeize({ operations: 'test_connection', cloudRoleArn }),
        } as Record<string, unknown>,
      })
      const camelizedData = camelize(data)
      const tested = get(
        camelizedData,
        'operationsResult[0].testConnection.connected',
        false
      )

      const statusValue = tested ? 'success' : 'failure'
      await updateFlyoutTestConnection(statusValue)
    } catch (error) {
      await updateFlyoutTestConnection('failure')
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    }
  }
export const flyOutCasTestConnection =
  (
    partnerId: string,
    cloudRoleArn: string,
    cloudCasS3BucketName: string,
    cloudCasSnsTopic: string,
    cloudCasRoleArn: string,
    updateFlyoutTestConnection: (status: TestConnectionStatusType) => void
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await updateFlyoutTestConnection('loading')
    try {
      const { data } = await fetchSellerData({
        partnerId,
        metadata: {
          ...snakeize({
            operations: 'test_cas_connection',
            cloudRoleArn,
            cloudCasS3BucketName,
            cloudCasSnsTopic,
            cloudCasRoleArn,
          }),
        } as Record<string, unknown>,
      })
      const camelizedData = camelize(data)
      const tested = get(
        camelizedData,
        'operationsResult[0].testCasConnection.connected',
        false
      )
      const statusResult = tested ? 'success' : 'failure'
      await updateFlyoutTestConnection(statusResult)
    } catch (error) {
      await updateFlyoutTestConnection('failure')
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    }
  }
export const setFlyOutTestConnectionStatus = (
  status: TestConnectionStatusType
) => ({
  type: FlyoutOnboardingForm.UPDATE_FLYOUT_TEST_CONNECTION_STATUS,
  payload: status,
})
export const setFlyOutCasTestConnectionStatus = (
  status: TestConnectionStatusType
) => ({
  type: FlyoutOnboardingForm.UPDATE_FLYOUT_CAS_TEST_CONNECTION_STATUS,
  payload: status,
})
export const processPartnerDetailsData = async (
  partnerId: string,
  onboardingData: Sections,
  dispatch: AppDispatch
) => {
  const sectionDetails =
    onboardingData[FlyoutOnboardingSectionName.LABRA_PARTNER_DETAILS]
  const isIsv =
    sectionDetails.partnershipType === FlyoutOnboardingPartnershipType.ISV
  const shouldSendPartnershipType =
    sectionDetails.listingType !== FlyOutListingUserType.MANAGED
  let payload: Partial<PartnerData> = {
    companyName: sectionDetails.companyName,
    industry: sectionDetails.industry,
    websiteUrl: sectionDetails.companyWebsite,
    isIsv: isIsv,
  }
  if (shouldSendPartnershipType) {
    payload = {
      ...payload,
      partnershipType: sectionDetails.partnershipType,
    }
  }
  // Dispatch the updatePartner action
  await dispatch(updatePartner(partnerId || '', PartnerType.User, payload, {}))
}
