import { Reducer } from 'redux'
import { FlyOutProductFormChangeType, FlyoutProductForm } from './action'

import { KeyValue, LockMetadata } from '../flyOutOnboarding/reducer'
import { FlyOutListingUserType } from '../../../admin/modules/flyOutSyncs/reducer'
import { PlanType } from '@labrav/react-components'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export interface FlyoutProductMigrationDetails {
  products: string[]
}

export interface FlyoutCloudProductDetails {
  productType: PlanType
  productName: string
  listingType?: FlyOutListingUserType
}

export interface NotificationRecipients {
  registrationCompleted: string[]
  privateOfferRenewalReminder: string[]
  registrationPending: string[]
  subscribeFail: string[]
  entitlementRenew: string[]
  entitlementExpired: string[]
  entitlementUpgrade: string[]
  unsubscribePending: string[]
  unsubscribeSuccess: string[]
  privateOfferExpired: string[]
  privateOfferAccepted: string[]
  privateOfferCreated: string[]
  privateOfferExpiringSoon: string[]
  awsPrivateOfferRestrictionSuccess: string[]
  meteringSent: string[]
  awsProductListingLive: string[]
  awsProductListingRejected: string[]
  freeTrialActivated: string[]
  freeTrialDeactivated: string[]
  awsResellerAgreementCreated: string[]
  awsResellerAgreementDeactivated: string[]
  awsResellerAgreementExpiringSeller: string[]
  awsResellerAgreementExpiredSeller: string[]
}

export interface ContractDimension {
  id: number
  name: string
  label: string
  price: number | null
  price1m: number
  price12m: number
  price24m: number
  price36m: number
  labelDescription: string
  isAdditionalUsageAllowed: boolean | null
  checkBoxAdditionalUsageAllowed: boolean | null
  additionalUsagePrice: number | null
  subscriptionPrice: number | null
  additionalUsageDescription: string
  dimensionUnit?: string
}

export interface FlyoutProductGeneralInformation {
  sku: string
  productLogoUrl: string
  headerImg: string
  shortProductDescription: string
  productDescription: string
  productVideoLink: string
  highlights: string[]
  supportResources: string
  resourceUrls: KeyValue[]
  productCategories: []
  searchKeywords: string
  eula: string
  eulaUrl: string
  refundPolicy: string
  allowedAccounts: string
  multipleDimensionsAllowed: boolean
  excludedCountries: string[]
  includedCountries: string[]
  termsAndCondition: boolean
  buyerNotificationsAllowed: boolean
  countryAvailability?: string
  countriesSelected?: string[]
  title: string
  saasUrl: string
  companyName: string
  isUsGovProduct: boolean
}

export interface FlyoutProductProductDimensions {
  dimensionCategoryName: string
  contractDuration: string[]
  dimensions: ContractDimension[]
  additionalDimensions?: ContractDimension[]
  formFilled: boolean
}

export type FlyOutProductTerm = {
  label: string
  value: boolean
  type: 'checkbox' | 'radio'
  subTermsWhenTrue?: FlyOutProductTerm
  subTermsWhenFalse?: FlyOutProductTerm
}
export type FlyOutProductTermsAndConditions = Record<
  string,
  FlyOutProductTerm[]
>

export type pageMarkerType = {
  [x: string]: boolean
}

export type FlyOutProductAgreementTerm = {
  label: string
  value: boolean
  type: 'checkbox' | 'radio'
  options?: FlyOutProductTerm[]
  subTermsWhenTrue?: FlyOutProductTerm & { points: string[] }
  subTermsWhenFalse?: FlyOutProductTerm & { points: string[] }
}
export type FlyOutProductAgreement = Record<
  string,
  FlyOutProductAgreementTerm[]
>

export enum FlyoutProductAddEditSectionName {
  PAGE_MARKER = 'pageMarker',
  CLOUD_PRODUCT_DETAILS = 'productDetails',
  GENERAL_INFORMATION = 'generalInformation',
  PRODUCT_DIMENSIONS = 'productDimensions',
  LANDING_PAGE_DETAILS = 'infoCollectionFormMetaData',
  NOTIFICATIONS_RECIPIENTS = 'notificationRecipients',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
  MIGRATION_DETAILS = 'migrationDetails',
  LOCK_METADATA = 'lockMetadata',
  AGREEMENT = 'agreement',
}

export interface FlyoutProductInfoCollectionFormMetaData {
  bgColor: string
  buttonColor: string
  buttonTextColor: string
  bgImageUrl: string
  redirectUrl: string
  redirectSeconds: number
  submissionSuccessMessage: string
  headingMessage: string
  privacyPolicyLink: string
  emailCustomMessage: string
  existingBuyerMessage: string
  existingBuyerRedirectSeconds: number
  existingBuyerRedirectUrl?: string
  logoUrl: string
  buyerFormFields?: FlyoutProductBuyerFormFields[]
  redirectionSettings: FlyoutProdctRedirectionSettings
}

export enum ApplicationAccessMethod {
  TESTING_CREDENTIALS = 'TESTING_CREDENTIALS',
  INSTRUCTIONS = 'INSTRUCTIONS',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
}

export interface TestingCredentials {
  email: string
  password: string
}

export interface FlyoutProdctRedirectionSettings {
  isPublicFacingSignupPageAvailable: boolean
  applicationLoginPageUrl: string
  demoVideoUrl: string
  demoDocumentUrl: string
  sendDemoToBuyer: boolean
  customInstructions: string
  applicationAccessMethod: ApplicationAccessMethod
  testingCredentials: TestingCredentials
}

export interface FlyoutProductBuyerFormFields {
  title: string
  dataType: string
  isRequired: boolean
  validationType: string | null
  minLength: number
  maxLength: number
}

export type FlyoutProductSections = {
  [FlyoutProductAddEditSectionName.CLOUD_PRODUCT_DETAILS]: FlyoutCloudProductDetails
  [FlyoutProductAddEditSectionName.PAGE_MARKER]: pageMarkerType
  [FlyoutProductAddEditSectionName.GENERAL_INFORMATION]: FlyoutProductGeneralInformation
  [FlyoutProductAddEditSectionName.PRODUCT_DIMENSIONS]: FlyoutProductProductDimensions
  [FlyoutProductAddEditSectionName.LANDING_PAGE_DETAILS]: FlyoutProductInfoCollectionFormMetaData
  [FlyoutProductAddEditSectionName.NOTIFICATIONS_RECIPIENTS]: NotificationRecipients
  [FlyoutProductAddEditSectionName.TERMS_AND_CONDITIONS]: FlyOutProductTermsAndConditions
  [FlyoutProductAddEditSectionName.MIGRATION_DETAILS]: FlyoutProductMigrationDetails
  [FlyoutProductAddEditSectionName.LOCK_METADATA]: LockMetadata
  [FlyoutProductAddEditSectionName.AGREEMENT]: FlyOutProductAgreement
}
export interface StatusListingResObj {
  Draft: string
  Limited?: string
  'Labra-testing'?: string
  'AWS-testing'?: string
  Public?: string
  Rejected?: string
  Restricted?: string
}
export interface StatusListingItem {
  status: string
  completed?: string
  icon: IconDefinition
  color: string
  background: string
  sortOrder: number
  display?: boolean
}
export interface PoductObjectInCreation {
  productForm: FlyoutProductSections
  listingType: FlyOutListingUserType
  errorOnSubmit: string
}
export interface ProductObjectDetail {
  productForm: FlyoutProductSections
  listingType: FlyOutListingUserType
  status: string
  auditJson: StatusListingResObj
  productName: string
  marketplaceUrl?: string
}

export interface BuyerFormFieldsBackend {
  displayOrder: number
  title: string
  placeHolder: string
  dataType: string
  isCustom: boolean
  isRequired: boolean
  validationType: string | null
  maxLength: number
  minLength: number
}

export interface ProductInCreation {
  currentProductInCreation: PoductObjectInCreation
  currentProductDetail: ProductObjectDetail
}
const initialState: ProductInCreation = {
  currentProductInCreation: {
    productForm: {} as FlyoutProductSections,
    listingType: FlyOutListingUserType.NEW,
    errorOnSubmit: '',
  },
  currentProductDetail: {
    productForm: {} as FlyoutProductSections,
    listingType: FlyOutListingUserType.NEW,
    status: '',
    auditJson: {} as StatusListingResObj,
    productName: '',
    marketplaceUrl: '',
  },
}

type actionType =
  | {
      type: FlyoutProductForm.SET_FLYOUT_PRODUCT_FORM
      payload: FlyoutProductSections
    }
  | {
      type: FlyoutProductForm.SET_FLYOUT_PRODUCT_DETAIL_FORM
      payload: ProductObjectDetail
    }
  | {
      type: FlyoutProductForm.UPDATE_FLYOUT_PRODUCT_FORM
      payload: FlyOutProductFormChangeType
    }
  | {
      type: FlyoutProductForm.SET_FLYOUT_PRODUCT_LISTING_TYPE
      payload: FlyOutListingUserType
    }
  | {
      type: FlyoutProductForm.SET_ERROR_ON_FINAL_SUBMIT
      payload: string
    }

export const reducer: Reducer<ProductInCreation, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FlyoutProductForm.SET_FLYOUT_PRODUCT_FORM: {
      return {
        ...state,
        currentProductInCreation: {
          ...state.currentProductInCreation,
          productForm: action.payload,
        },
      }
    }
    case FlyoutProductForm.SET_FLYOUT_PRODUCT_DETAIL_FORM: {
      return {
        ...state,
        currentProductDetail: action.payload,
      }
    }
    case FlyoutProductForm.UPDATE_FLYOUT_PRODUCT_FORM: {
      const { sectionName, keyName, value } = action.payload
      return {
        ...state,
        currentProductInCreation: {
          ...state.currentProductInCreation,
          productForm: {
            ...state.currentProductInCreation.productForm,
            [sectionName]: {
              ...state.currentProductInCreation.productForm[sectionName],
              [keyName]: value,
            },
          },
        },
      }
    }
    case FlyoutProductForm.SET_FLYOUT_PRODUCT_LISTING_TYPE: {
      return {
        ...state,
        currentProductInCreation: {
          ...state.currentProductInCreation,
          listingType: action.payload,
        },
      }
    }
    case FlyoutProductForm.SET_ERROR_ON_FINAL_SUBMIT: {
      return {
        ...state,
        currentProductInCreation: {
          ...state.currentProductInCreation,
          errorOnSubmit: action.payload,
        },
      }
    }
    default: {
      return state
    }
  }
}
