import { LandingPageWrapper } from '../../../components/LandingPageWrapper/LandingPageWrapper'
import { useActiveProducts } from '../../../utils/Hooks/activeProducts'
import { CurrentProductWrapper } from '../../../components/CurrentProductWrapper/CurrentProductWrapper'
import { usePartnerUser } from '../../../utils/Hooks/partner'
import { useTimeZone } from '../../../utils/Hooks/time'
import { useOnboarding } from '../../../utils/Hooks/onboarding'
import { LoadingLogo } from '@labrav/react-components'
import { useGetUsers } from '../../../utils/Hooks/useGetUsers'
import { useSellerInfo } from '../../../utils/Hooks/useSellerInfo'

export const LandingPageRouting = () => {
  const activeProducts = useActiveProducts()
  const { loading: partnerLoading } = usePartnerUser()
  const { loading: timezoneLoading } = useTimeZone()
  const { loading: onboardingLoading } = useOnboarding()
  const { loading: getUsersLoading } = useGetUsers()
  const { loading: sellerInfoLoading } = useSellerInfo()
  if (
    partnerLoading ||
    timezoneLoading ||
    onboardingLoading ||
    getUsersLoading 
  ) {
    return <LoadingLogo />
  }

  return (
    <CurrentProductWrapper activeProducts={activeProducts}>
      <LandingPageWrapper activeProducts={activeProducts} />
    </CurrentProductWrapper>
  )
}

export default LandingPageRouting
