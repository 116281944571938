import React from 'react'

import { Divider } from '@material-ui/core'
import { UserListState } from '../../modules/userList/reducer'
import { SidePanel } from '../../../common/components/SidePanel/SidePanel'
import { RoleStatusInfo } from './RoleStatusInfo/RoleStatusInfo'
import { UserBasicInfo } from './UserBasicInfo/UserBasicInfo'
import { useStyles } from './UserSidePanel.styles'
import { UserPasswordInfo } from './UserPasswordInfo/UserPasswordInfo'
import { useDispatch, useSelector } from '../../../store'
import {
  clearUserDataBE,
  hideUserSidePanel,
} from '../../modules/userList/action'
import { useUserType } from '../../../common/utils/Hooks/usePartnerData'

export const UserSidePanel: React.FC = () => {
  const classes = useStyles()
  const partnerType = useUserType()
  const { isUserSidePanelOpen, user, userLoader } = useSelector<UserListState>(
    state => state.userList[partnerType]
  )
  const sidePanelAlert = useSelector<boolean | undefined>(
    state => state.appAlert.sidePanelAlert
  )

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(hideUserSidePanel())
    dispatch(clearUserDataBE())
  }
  return (
    <SidePanel
      overFlowY
      width={800}
      heading={user?.name ?? ''}
      open={isUserSidePanelOpen}
      onClose={handleClose}
      anchor="right"
      showProgressBar={userLoader}
      showAlert={sidePanelAlert}
    >
      <div data-testid="userSidePanel" className={classes.root}>
        {user == null ? (
          <span>No data to show</span>
        ) : (
          <>
            <div className={classes.userBasicInfoContainer}>
              <UserBasicInfo user={user} />
            </div>
            <Divider className={classes.divider} />
            <div>
              <RoleStatusInfo user={user} />
            </div>
            <div className={classes.editPasswordsContainer}>
              <UserPasswordInfo user={user} />
            </div>
          </>
        )}
      </div>
    </SidePanel>
  )
}
