import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { isLoading as Loading } from '../../utils/loadingState'
import { useDispatch, useSelector } from '../../../store'
import { CRMTypes } from '../constants'
import {
  FormData,
  getCRMFormData,
} from '../../modules/onboarding/crmAuth/action'
import { LoadingTypes } from '../../modules/loading/reducer'

export type crmAuth = {
  deletedAt: string
  crmIdFromCRMAuth: string
  crmType?: CRMTypes
  crmAuth?: FormData
  isLoading: boolean
}

export const useCrmAuthInfo = (crmId: string | null | undefined) => {
  const dispatch = useDispatch()
  const [isCrmSelected, setIsCrmSelected] = React.useState(false)
  const { deletedAt, crmIdFromCRMAuth, crmType, crmAuth, isLoading } =
    useSelector<crmAuth>(state => ({
      deletedAt: state.CRMAuth.crmFormData?.deleted_at || '',
      crmIdFromCRMAuth: state.CRMAuth.crmFormData?.crm_id || '',
      crmType: state.CRMAuth.crmFormData?.crm_type as CRMTypes,
      crmAuth: state.CRMAuth.crmFormData
        ? state.CRMAuth.crmFormData
        : undefined,
      isLoading: Loading(state.loading)(LoadingTypes.CRM_AUTH),
    }))
  const isNewCosellPipeline =
    crmType !== CRMTypes.salesforce && crmType !== CRMTypes.connectwise

  useEffect(() => {
    if (crmId && isEmpty(crmAuth) && !isEmpty(crmId)) {
      dispatch(getCRMFormData(crmId))
    }
    isEmpty(crmIdFromCRMAuth)
      ? setIsCrmSelected(false)
      : setIsCrmSelected(!isEmpty(crmId) && isEmpty(deletedAt))
  }, [crmIdFromCRMAuth, crmAuth, crmId, deletedAt])

  return {
    deletedAt,
    crmIdFromCRMAuth,
    isCrmSelected,
    crmType,
    crmAuth,
    isLoading,
    isNewCosellPipeline,
  }
}
