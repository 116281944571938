import { AnyAction } from 'redux'
import { AppDispatch, RootState } from '../../../store'
import { Product, ProductDimension } from '../productsListing/reducer'
import { camelize, snakeize } from 'casing'
import { get, isEmpty, isNil, toLower, toUpper } from 'lodash'
import emptyResaleAuthorization from '../../../mocks/emptyResaleAuthorization.json'
import {
  KeyValue,
  createOfferErrors,
  getUpdatedDimensions,
} from '../privateOffer/privateOfferCreation/actions'
import { Dimension, EulaType, Price, PriceTypes } from '../privateOffer/reducer'
import { getFilteredInstallments } from '../privateOffer/privateOfferCreation/actions'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { getErrorMessages } from '../../../common/utils/error'
import { AxiosResponse } from 'axios'
import { defaultCrmType } from '../../../common/utils/constants'
import { Installments } from '../privateOffer/reducer'
import {
  RequestFailureMessage,
  resaleAuthorizationCreationSuccess,
  resaleAuthorizationSaveSuccess,
} from '../../../common/utils/messagesContants'
import {
  getSingleResaleAuthorization,
  patchResellerData,
  postResaleAuthorization,
} from '../../api/markeplace'
import { errorLogger } from '../../../common/utils/errorLogger'
import { mapObjectPropertyEmptyToNull } from '../../../common/utils/mapObjectPropertyEmptyToNull'
import { ResaleAuthorizationWithErrors } from './reducer'
import {
  ContractDurationType,
  DiscountType,
  ResellerInfo,
} from '../../../admin/modules/resaleAuthorizations/reducer'
import { isNonEmptyNumber } from '../../../common/utils/validateMinMax'

export enum ResaleAuthorizationCreationActionTypes {
  SET_CREATE_RESALE_AUTHORIZATIONS_DATA = 'SET_CREATE_RESALE_AUTHORIZATIONS_DATA',
  ADD_ROWS_ON_RESALE_AUTHORIZATION = 'ADD_ROWS_ON_RESALE_AUTHORIZATION',
  DELETE_ROWS_ON_RESALE_AUTHORIZATION = 'DELETE_ROWS_ON_RESALE_AUTHORIZATION',
  UPDATE_VALUES_OF_ARRAY_ON_RESALE_AUTHORIZATION = 'UPDATE_VALUES_OF_ARRAY_ON_RESALE_AUTHORIZATION',
  UPDATE_RESALE_AUTHORIZATION_CREATION_ERRORS = 'UPDATE_RESALE_AUTHORIZATION_CREATION_ERRORS',
  SET_ERRORS_FOR_MULTIPLE_FIELDS = 'SET_ERRORS_FOR_MULTIPLE_FIELDS',
  CLEAR_RESALE_AUTHORIZATION_IN_CREATION = 'CLEAR_RESALE_AUTHORIZATION_IN_CREATION',
  UPDATE_RESALE_AUTHORIZATIONS = 'UPDATE_RESALE_AUTHORIZATIONS',
  UPDATE_DIMENSION_ON_RESALE_AUTHORIZATION = 'UPDATE_DIMENSION_ON_RESALE_AUTHORIZATION',
  UPDATE_PRICES_ON_RESALE_AUTHORIZATION_DIMENSIONS = 'UPDATE_PRICES_ON_RESALE_AUTHORIZATION_DIMENSIONS',
  ADD_DIMENSION_ROWS_ON_RESALE_AUTHORIZATIONS = 'ADD_DIMENSION_ROWS_ON_RESALE_AUTHORIZATIONS',
  UPDTE_RESALE_AUTHORIZATION_HAS_BEEN_SENT = 'UPDTE_RESALE_AUTHORIZATION_HAS_BEEN_SENT',
  UPDATE_NUMBER_OF_TIMES_SAVED_ON_RESALE_AUTHORIZATION = 'UPDATE_NUMBER_OF_TIMES_SAVED_ON_RESALE_AUTHORIZATION',
  ADD_FILES_ON_RESALE_AUTHORIZATION = 'ADD_FILES_ON_RESALE_AUTHORIZATION',
}
export const getPricesArray = (dimensions: Dimension[]) => {
  // Prices Durations which are selected for one dimensions will be selected for all the dimensions, so taking only first one and getting non null fields
  let pricesArray: string[] = []
  const dimension = dimensions.find(
    dimension => !dimension.isAdditionalUsageAllowed
  )
  if (dimension?.prices) {
    const prices = dimension.prices as PriceTypes
    pricesArray = (Object.keys(prices) as [keyof PriceTypes]).filter(
      (price: keyof PriceTypes) => prices[price] !== null
    )
  }
  return pricesArray
}
export const arrayFieldsForValidations = [
  'agreementRecipients',
  'metaData',
  'installmentInfo',
  'dimensions',
]

const validateFields = [
  'companyName',
  'agreementName',
  'agreementRecipients',
  'metaData',
  'emailCustomMessage',
  'buyerAwsAccountId',
  'percentageDiscount',
  'installmentInfo',
  'dimensions',
  'resellersInfo',
  'discountType',
  'contractDurationType',
  'contractDurationInMonthsForEndCustomer',
  'agreementExpirationDate',
]

export const getCurrentResaleAuthorizationInCreation =
  ({
    partnerId,
    productId,
    resaleAuthorizationId,
    crmOpportunityId,
  }: {
    partnerId: string
    productId: string
    resaleAuthorizationId: string
    crmOpportunityId?: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const cloudMarketplace = 'AWS' //getState().productListingFilters.cloudMarketplace
    const product =
      getState().productsListing[cloudMarketplace].products.rows.find(
        product => product.productId === productId
      ) || ({} as Product)
    const planType = product?.productType
    let offerData = {}
    if (isEmpty(resaleAuthorizationId)) {
      const updatedDimensions = getUpdatedDimensions(
        product?.productDimensions || [],
        planType,
        true
      )
      offerData = {
        ...emptyResaleAuthorization,
        crm_opportunity_id: crmOpportunityId ?? null,
        email_custom_message: product?.emailCustomMessage || '',
        dimensions: [...updatedDimensions],
      }
    } else {
      const { data } = await getSingleResaleAuthorization(
        partnerId,
        resaleAuthorizationId,
        crmOpportunityId
      )
      const { createdAt, products, ...agreementData } = camelize(data)
      agreementData.agreementDurationType = toUpper(
        agreementData.agreementDurationType
      )
      agreementData.resellerLicenseAgreementType = toUpper(
        agreementData.resellerLicenseAgreementType
      )
      agreementData.discountType = toUpper(agreementData.discountType)
      agreementData.resellersInfo = isEmpty(agreementData.resellersInfo)
        ? [
            {
              labraPlatformResellerId: '',
              resellerAwsAccount_id: '',
            },
          ]
        : [...agreementData.resellersInfo]
      const additionalUsageDimensions = getUpdatedDimensions(
        product?.productDimensions || [],
        planType,
        true
      )
      const updatedDimensions =
        agreementData?.discountType === DiscountType.DISCOUNT
          ? additionalUsageDimensions
          : [...get(products, '[0].dimensions', [])]
      offerData = { ...agreementData, dimensions: updatedDimensions }
    }
    const pricesArray: string[] = getPricesArray(
      product?.productDimensions as Dimension[]
    )
    const finalData = { ...camelize(offerData), pricesArray }
    const { resellersInfo, ...errors } = createOfferErrors(
      finalData,
      arrayFieldsForValidations,
      true
    )
    dispatch(
      setResaleAuthorizationInCreation({
        productId,
        data: { ...finalData },
        errors,
        noOfTimesSaved: isEmpty(resaleAuthorizationId) ? 0 : 2,
      })
    )
  }

export type setResaleAuthorizationInCreationProps = {
  productId: string
  data: Record<string, unknown>
  errors: Record<string, unknown>
  noOfTimesSaved: number
}
export const setResaleAuthorizationInCreation = ({
  productId,
  data,
  errors,
  noOfTimesSaved = 0,
}: setResaleAuthorizationInCreationProps) =>
  ({
    type: ResaleAuthorizationCreationActionTypes.SET_CREATE_RESALE_AUTHORIZATIONS_DATA,
    payload: {
      productId,
      resaleAuthorizationData: data,
      errors,
      noOfTimesSaved,
    },
  } as unknown as AnyAction)

export const setErrorsOnResaleAuthorizationCreation = (
  key: string,
  productId: string,
  field?: string,
  index?: number,
  standardDimensions?: ProductDimension[]
) => ({
  type: ResaleAuthorizationCreationActionTypes.UPDATE_RESALE_AUTHORIZATION_CREATION_ERRORS,
  payload: { key, productId, field, index, standardDimensions },
})

export const setValuesInArrayOfResaleAuthorizations = (
  key: string,
  index: number,
  resaleAuthorizationData: KeyValue,
  productId: string
) => ({
  type: ResaleAuthorizationCreationActionTypes.UPDATE_VALUES_OF_ARRAY_ON_RESALE_AUTHORIZATION,
  payload: { key, resaleAuthorizationData, index, productId },
})

export const clearResaleAuthorizationInCreation = (productId: string) => ({
  type: ResaleAuthorizationCreationActionTypes.CLEAR_RESALE_AUTHORIZATION_IN_CREATION,
  payload: productId,
})

export const addRowsOnResaleAuthorization = (
  key: string,
  productId: string,
  type = 'standard'
) => ({
  type: ResaleAuthorizationCreationActionTypes.ADD_ROWS_ON_RESALE_AUTHORIZATION,
  payload: { key, productId, type },
})
export const deleteRowsOnResaleAuthorization =
  (key: string, index: number, productId: string) =>
  async (dispatch: AppDispatch) => {
    await dispatch(deleteRows(key, index, productId))
    if (validateFields.includes(key)) {
      await dispatch(setErrorsOnResaleAuthorizationCreation(key, productId))
    }
  }
export const deleteRows = (key: string, index: number, productId: string) => ({
  type: ResaleAuthorizationCreationActionTypes.DELETE_ROWS_ON_RESALE_AUTHORIZATION,
  payload: { key, index, productId },
})

export const updatevaluesInArrayOfResaleAuthorizations =
  (
    key: string,
    index: number,
    resaleAuthorizationData: KeyValue,
    productId: string
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const cloudType = state.productListingFilters.cloudMarketplace
    const standardDimensions = state.productsListing[
      cloudType
    ].products.rows.filter(val => val.productId === productId)[0]
      .productDimensions
    await dispatch(
      setValuesInArrayOfResaleAuthorizations(
        key,
        index,
        resaleAuthorizationData,
        productId
      ) as unknown as AnyAction
    )
    if (validateFields.includes(key)) {
      await dispatch(
        setErrorsOnResaleAuthorizationCreation(
          key,
          productId,
          resaleAuthorizationData.key,
          index,
          standardDimensions
        ) as unknown as AnyAction
      )
    }
  }
export const updateResaleAuthorizationInCreation =
  (data: KeyValue, productId: string) => async (dispatch: AppDispatch) => {
    await dispatch(setResaleAuthorizationsData(data, productId))
    if (validateFields.includes(data.key)) {
      await dispatch(
        setErrorsOnResaleAuthorizationCreation(data.key as string, productId)
      )
    }
  }

export const setResaleAuthorizationsData = (
  data: KeyValue,
  productId: string
) => ({
  type: ResaleAuthorizationCreationActionTypes.UPDATE_RESALE_AUTHORIZATIONS,
  payload: { resaleAuthorizationData: data, productId },
})

export const updateDimensionOnResale = (
  index: number,
  productId: string,
  dimension: Dimension
) => ({
  type: ResaleAuthorizationCreationActionTypes.UPDATE_DIMENSION_ON_RESALE_AUTHORIZATION,
  payload: { productId, dimension, index },
})

export const updatePricesOnResaleDimensions =
  (productId: string, index: number, priceData: KeyValue) =>
  async (dispatch: AppDispatch) => {
    await dispatch(setPricesOnResale(productId, index, priceData))
    await dispatch(
      setErrorsOnResaleAuthorizationCreation(
        'dimensions',
        productId,
        priceData.key,
        index
      ) as unknown as AnyAction
    )
  }

export const setPricesOnResale = (
  productId: string,
  index: number,
  priceData: KeyValue
) => ({
  type: ResaleAuthorizationCreationActionTypes.UPDATE_PRICES_ON_RESALE_AUTHORIZATION_DIMENSIONS,
  payload: { productId, index, priceData },
})
export const updateHasBeenSent = (hasBeenSent: boolean, productId: string) => ({
  type: ResaleAuthorizationCreationActionTypes.UPDTE_RESALE_AUTHORIZATION_HAS_BEEN_SENT,
  payload: { hasBeenSent, productId },
})

export const updateNoOfTimesSaved = (productId: string) => ({
  type: ResaleAuthorizationCreationActionTypes.UPDATE_NUMBER_OF_TIMES_SAVED_ON_RESALE_AUTHORIZATION,
  payload: productId,
})

const returnNecessaryFieldResaleAuthorization = (
  resaleAuthorizationState: ResaleAuthorizationWithErrors
) => {
  const {
    errors,
    dimensions,
    pricesArray,
    hasBeenSent,
    noOfTimesSaved,
    resellerName,
    ...remainingResaleFields
  } = resaleAuthorizationState
  return remainingResaleFields
}

interface getDiscountedPriceProps {
  price: number | string | null
  percentageDiscount?: number
}

export const getDiscountedPrice = ({
  price,
  percentageDiscount,
}: getDiscountedPriceProps) => {
  if (isNil(price)) return null
  if (!percentageDiscount) return Number(price)
  return parseFloat(
    (
      Number(price) -
      (Number(price) * Number(percentageDiscount)) / 100
    ).toFixed(3)
  )
}

interface getPricesOrPriceProps {
  price?: Price
  prices?: Price
  quantity?: number | string
  isAdditional: boolean
  resaleAuthorizationState: ResaleAuthorizationWithErrors
}

export const getResalePriceKeySnakeizConversion: (key: string) => string = (
  key: string
) => {
  switch (key) {
    case 'price1m':
      return 'price_1m'
    case 'price12m':
      return 'price_12m'
    case 'price24m':
      return 'price_24m'
    case 'price36m':
      return 'price_36m'
    case 'additionalUsagePrice':
      return 'additional_usage_price'
    case 'subscriptionPrice':
      return 'subscription_price'
    default:
      return ''
  }
}

export const getPricesOrPriceOrQuantity = ({
  price,
  prices,
  quantity,
  isAdditional,
  resaleAuthorizationState,
}: getPricesOrPriceProps) => {
  const { contractDurationType, percentageDiscount, discountType } =
    resaleAuthorizationState
  const nullPriceObject = {
    price_1m: null,
    price_12m: null,
    price_24m: null,
    price_36m: null,
    additional_usage_price: null,
    subscription_price: null,
  }

  if (isAdditional && prices)
    return {
      prices: {
        ...nullPriceObject,
        additional_usage_price: getDiscountedPrice({
          percentageDiscount,
          price: (prices as PriceTypes)?.additionalUsagePrice,
        }),
      },
    }

  if (discountType === DiscountType.FLEXIBLE_PAYMENT_TERMS) {
    return { prices: nullPriceObject, quantity }
  } else {
    if (contractDurationType === ContractDurationType.CUSTOM) {
      return {
        prices: nullPriceObject,
        price,
      }
    } else {
      if (!prices) return {}
      const returnPrices = nullPriceObject as Record<string, number | null>
      for (const key in prices as PriceTypes) {
        if (Object.prototype.hasOwnProperty.call(prices, key)) {
          returnPrices[getResalePriceKeySnakeizConversion(key)] =
            getDiscountedPrice({ percentageDiscount, price: get(prices, key) })
        }
      }
      return {
        prices: returnPrices,
      }
    }
  }
}

const getDimensionsWithSpecificProperty = (
  productDimensions: ProductDimension[]
) => {
  return productDimensions.map(
    ({
      additionalUsuageDescription,
      valueDataType,
      id,
      freeTrial,
      ...remainingDimension
    }) => ({ ...remainingDimension, dimensionId: id })
  )
}

export const getResellerProductDimensions = ({
  resaleAuthorizationState,
  product,
}: {
  resaleAuthorizationState: ResaleAuthorizationWithErrors
  product: Product
}) => {
  const { dimensions } = resaleAuthorizationState

  const resaleDimensions =
    resaleAuthorizationState.discountType === DiscountType.DISCOUNT
      ? (getDimensionsWithSpecificProperty(
          product?.productDimensions || []
        ) as Dimension[])
      : dimensions

  if (!resaleDimensions || !resaleDimensions.length) return []

  const updatedDimensions = resaleDimensions?.map(
    ({ price, prices, quantity, ...remainingDimension }) => ({
      ...snakeize(remainingDimension),
      ...getPricesOrPriceOrQuantity({
        isAdditional:
          remainingDimension.isAdditionalUsageAllowed ||
          remainingDimension.type === 'additional_usage',
        price,
        prices,
        quantity,
        resaleAuthorizationState,
      }),
    })
  )

  return [
    {
      ...snakeize({
        awsProductId: resaleDimensions[0].awsProductId,
        productName: product.productName,
      }),
      dimensions: updatedDimensions,
    },
  ]
}

const mapResellersInfo = ({
  resellersInfo,
}: {
  resellersInfo: ResellerInfo[]
}) => {
  const resellersInfoData = resellersInfo
    .filter(
      reseller =>
        reseller.labraPlatformResellerId || reseller.resellerAwsAccountId
    )
    .map(
      ({
        labraPlatformResellerId,
        resellerAwsAccountId,
        resellerCompanyName,
      }: ResellerInfo) => ({
        labraPlatformResellerId,
        resellerAwsAccountId,
        resellerCompanyName,
      })
    )
  return resellersInfoData.length ? { resellersInfo: resellersInfoData } : {}
}

export const sendResaleAuthorization =
  ({
    partnerId,
    productId,
    saveAsDraft,
    crmOpportunityId,
    crmObjectLink,
  }: {
    partnerId: string
    productId: string
    saveAsDraft?: boolean
    crmOpportunityId?: string
    crmObjectLink?: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      //TODO LT-5308: When Azure enable for resale then we have to do dynamic
      const cloudMarketplace = 'AWS' //getState().productListingFilters.cloudMarketplace
      const product =
        getState().productsListing[cloudMarketplace].products?.rows.find(
          product => product.productId === productId
        ) || ({} as Product)

      const resaleAuthorizationState =
        getState().resaleAuthorizationsInCreation
          .currentResaleAuthorizationInCreation[productId]
      const {
        discountType,
        agreementDurationType,
        resellerLicenseAgreementType,
        percentageDiscount,
        agreementExpirationDate,
        buyerAwsAccountId,
        awsAgreementId,
        resellersInfo,
        contractDurationType,
        contractDurationInMonthsForEndCustomer,
        companyName,
        ...resaleAuthorization
      } = returnNecessaryFieldResaleAuthorization(resaleAuthorizationState)
      const {
        givenName: firstName,
        familyName: lastName,
        email,
      } = getState().userProfile.userProfile
      const authorizationStakeholders = [
        {
          firstName: firstName || '',
          lastName: lastName || '',
          email: email || '',
        },
      ]
      const resaleAuthorizationToBeSent = { ...resaleAuthorization } as Record<
        string,
        unknown
      >
      let crmMetadata = {} as Record<string, unknown>
      if (crmOpportunityId && crmObjectLink) {
        crmMetadata = {
          crmType: defaultCrmType,
          crmObjectLink,
        }
      }
      resaleAuthorizationToBeSent.discountType = discountType
        ? toLower(discountType)
        : null

      resaleAuthorizationToBeSent.agreementDurationType = toLower(
        agreementDurationType
      )

      resaleAuthorizationToBeSent.resellerLicenseAgreementType =
        toLower(resellerLicenseAgreementType) || null

      resaleAuthorizationToBeSent.draft = !!saveAsDraft

      resaleAuthorizationToBeSent.percentageDiscount = percentageDiscount
        ? percentageDiscount
        : null

      resaleAuthorizationToBeSent.contractDurationInMonthsForEndCustomer =
        isNonEmptyNumber(contractDurationInMonthsForEndCustomer)
          ? contractDurationInMonthsForEndCustomer
          : null

      const updatedResaleAuthorization = {
        ...resaleAuthorizationToBeSent,
        ...mapObjectPropertyEmptyToNull({
          agreementExpirationDate,
          buyerAwsAccountId,
          awsAgreementId,
          contractDurationType,
          companyName,
        }),
      }
      const { id, ...resaleAuthorizationToBeSentWithoutId } =
        updatedResaleAuthorization

      resaleAuthorizationToBeSentWithoutId.installmentInfo =
        getFilteredInstallments(
          resaleAuthorizationToBeSentWithoutId.installmentInfo as unknown as Installments[]
        )
      resaleAuthorizationToBeSentWithoutId.agreementStakeholders = [
        ...authorizationStakeholders,
      ]
      resaleAuthorizationToBeSentWithoutId.endCustomerEulaFiles =
        resaleAuthorization?.endCustomerEulaType !== EulaType.CUSTOM
          ? []
          : resaleAuthorization?.endCustomerEulaFiles
      resaleAuthorizationToBeSentWithoutId.resellerLicenseAgreementFiles =
        resellerLicenseAgreementType !== EulaType.CUSTOM
          ? []
          : resaleAuthorization?.resellerLicenseAgreementFiles

      const awsProductId = product?.productDimensions
        ? product.productDimensions[0].awsProductId || product.productId
        : product.productId
      const dataToBeSent = {
        ...snakeize({
          ...resaleAuthorizationToBeSentWithoutId,
          ...(crmOpportunityId ? { crmMetadata } : {}),
          ...mapResellersInfo({
            resellersInfo,
          }),
          awsProductId,
        }),
        products: getResellerProductDimensions({
          resaleAuthorizationState,
          product,
        }),
      }
      if (isEmpty(id)) {
        const { data } = await postResaleAuthorization({
          partnerId,
          data: {
            ...dataToBeSent,
          },
          saveAsDraft,
        })
        await dispatch(
          updateResaleAuthorizationInCreation(
            { key: 'id', value: camelize(data).labraAwsResellerAgreementId },
            productId
          ) as unknown as AnyAction
        )
      } else {
        await patchResellerData(
          partnerId,
          id as string,
          { ...dataToBeSent },
          saveAsDraft
        )
      }
      if (!saveAsDraft) {
        await dispatch(updateHasBeenSent(true, productId))
      } else {
        await dispatch(updateNoOfTimesSaved(productId))
      }
      dispatch(
        updateAppAlert({
          message: saveAsDraft
            ? resaleAuthorizationSaveSuccess
            : resaleAuthorizationCreationSuccess,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const addFilesOnResaleAuthorization = (
  productId: string,
  files: string[],
  key: 'endCustomerEulaFiles' | 'resellerLicenseAgreementFiles'
) => ({
  type: ResaleAuthorizationCreationActionTypes.ADD_FILES_ON_RESALE_AUTHORIZATION,
  payload: { productId, files, key },
})
