import { AnyAction } from 'redux'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { camelize } from 'casing'
import { getReports } from '../../api/markeplace'
import { getErrorMessages } from '../../../common/utils/error'
import { DateRange, ReportsPayload } from './reducer'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { AppDispatch, RootState } from '../../../store'
import { snakeCase } from 'lodash'
import { errorLogger } from '../../../common/utils/errorLogger'
import FileDownload from 'js-file-download'
import { useCurrentCloudProvider } from '../../../common/utils/Hooks/useCurrentCloudProvider'
import reportsData from '../../../mocks/revenueInsightsMockData.json'

export enum RevenueTrackingActionTypes {
  SET_REPORTS_DATA = 'SET_REPORTS_DATA',
  REVENUE_SET_PAGE_NUMBER = 'REVENUE_SET_PAGE_NUMBER',
  REVENUE_SET_LIMIT = 'REVENUE_SET_LIMIT',
  SET_PRODUCT_ID = 'SET_PRODUCT_ID',
  CLEAR_REVENUE_REPORTS = 'CLEAR_REVENUE_REPORTS',
  SET_DATE_RANGE = 'SET_DATE_RANGE',
}
export type GetRevenueInsightsReport = {
  partnerId: string
  reportType: string
  cloudMarketplace: string
  finalStartDate: string
  finalEndDate: string
  downloadReport: boolean
  pageSize: number
  pageNumber: number
  productId?: string
  sortBy?: string
  sortOrder?: string
}

export const getRevenueInsights =
  (downloadReport: boolean) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))

    if (!downloadReport) {
      await dispatch(clearRevenueReports())
    }
    try {
      const { pageSize, pageNumber } = getState().revenueInsightsV2
      if (!downloadReport) {
        const data = reportsData
        await dispatch(setReportsData(camelize(data), pageSize))
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setReportsData = (data: ReportsPayload, pageSize: number) => ({
  type: RevenueTrackingActionTypes.SET_REPORTS_DATA,
  payload: { ...data, pageSize },
})

export const updateRevenuePageSize =
  (pageSize: number) => async (dispatch: AppDispatch) => {
    await dispatch(setRevenuePageSize(pageSize))
    await dispatch(getRevenueInsights(false) as unknown as AnyAction)
  }

export const setRevenuePageSize = (pageSize: number) => ({
  type: RevenueTrackingActionTypes.REVENUE_SET_LIMIT,
  payload: pageSize,
})

export const setRevenuePageNumber = (pageNumber: number) => ({
  type: RevenueTrackingActionTypes.REVENUE_SET_PAGE_NUMBER,
  payload: pageNumber,
})

export const clearRevenueReports = () => ({
  type: RevenueTrackingActionTypes.CLEAR_REVENUE_REPORTS,
})

export const setPageOneForRevenue = () => async (dispatch: AppDispatch) => {
  await dispatch(setRevenuePageNumber(1))
}

export const updateDateRange =
  (dates: DateRange) => async (dispatch: AppDispatch) => {
    const { startDate, endDate } = dates
    await dispatch(setDateRange({ startDate, endDate }))
    await dispatch(clearRevenueReports())
    await dispatch(setRevenuePageNumber(1))
  }
export const setDateRange = (dates: DateRange) => ({
  type: RevenueTrackingActionTypes.SET_DATE_RANGE,
  payload: dates,
})
