import { Reducer } from 'redux'
import { CloudMarketplace } from '../productsListing/reducer'
import { ProductListingFilterType } from './action'
import { RawValue, getItem, setItem } from '../../../common/utils/localStorage'

export enum FlyoutFunction {
  'REVENUE' = 'revenue',
  'PRIVATE_OFFERS' = 'privateOffers',
  'RESALE_AUTHORIZATIONS' = 'resaleAuthorizations',
  'SUBSCRIPTION' = 'subscription'
}
export interface ProductListingFilters {
  cloudMarketplace: CloudMarketplace
}

//getInitalCloudMarketplace will check first cloudType from url if it's present then use this [used while link open from hubspot or email]
//if not in then check fomm localstorage if got then use that
//else default AWS will use.

const getInitalCloudMarketplace = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const cloudTypeFromUrl = urlParams.get('cloudType')
  if (cloudTypeFromUrl) {
    return cloudTypeFromUrl as CloudMarketplace
  }
  const fromLocal = localStorage.getItem('cloudType')
  return fromLocal
    ? ((JSON.parse(fromLocal) as RawValue).value as CloudMarketplace)
    : 'AWS'
}

type actionType = {
  type: ProductListingFilterType.SET_CLOUD_MARKETPLACE
  payload: CloudMarketplace
}
export const initialState: ProductListingFilters = {
  cloudMarketplace: getInitalCloudMarketplace(),
}
export const reducer: Reducer<ProductListingFilters, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ProductListingFilterType.SET_CLOUD_MARKETPLACE: {
      setItem('cloudType', action.payload)
      return {
        ...state,
        cloudMarketplace: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
