import React, { lazy, Suspense, useEffect, useRef } from 'react'

import { Navigate, Route, Routes } from 'react-router-dom'
import { SidePanelWrapper as SidePanelWrapperV1 } from '../SidePanelWrapper/SidePanelWrapper'

import { Product } from '../../modules/partner/action'
import { ProductType } from '../../modules/partner/reducer'
import { UserProfile } from '../../pages/components/UserProfile/UserProfile'
import { useSelector } from '../../../store'
import { ProductRoutingMap } from '../../utils/productRoutingMap'
import { ProductSelectorWrapper } from '../ProductSelector/ProductSelectorWrapper'

import { PageNotFound } from '../PageNotFound/PageNotFound'

import { UserAndPermission } from '../../../oppsync/pages/components/UserAndPermission/UserAndPermission'
import { isEmpty, uniq } from 'lodash'
import RouteApp from '../../pages/components/RouteApp/RouteApp'
import { useFlagValue } from '@labrav/flags'
import { SidePanelWrapperV2 } from '../SidePanelWrapperV2/SidePanelWrapperV2'
import { LayoutWrapper } from '../RoutingApp/RoutingApp'
import { CloudChangeV2 } from '../CloudChangeV2/CloudChangeV2'
import { CoSellPipeline } from '../../../oppsync/components/CoSell/CoSellPipeline'
import { ReferralForm } from '../../../oppsync/components/CoSell/ReferralForm/ReferralForm'
import { usePartnerId } from '../../utils/Hooks/usePartnerData'

export type LandingPageWrapperProps = {
  activeProducts: Product[]
}

const ProductRoutes: Record<
  Product,
  React.LazyExoticComponent<() => JSX.Element>
> = {
  oppsync: lazy(
    () => import('../../../oppsync/pages/OppSyncApplicationRoutings')
  ),
  cloudfaas: lazy(
    () =>
      import('../../../common/pages/components/LandingPage/CloudfaasTempPage')
  ),
  flyout: lazy(
    () => import('../../../common/pages/components/LandingPage/CmcmLandingPage')
  ),
}

const SettingsComponent = lazy(
  () => import('../../../common/components/Settings')
)

export const LandingPageWrapper: React.FC<LandingPageWrapperProps> = ({
  activeProducts,
}) => {
  const partnerId = usePartnerId()
  const prevPartnerIdRef = useRef<string | undefined>(partnerId)
  const pathName = window.location.pathname
  const isNotInProductSelection = pathName !== '/product-selection'
  const currentProduct = useSelector<ProductType | undefined>(
    state => state.PartnerData.user.currentProduct
  )
  const { value: isLabraCustomerOnboarding } = useFlagValue(
    'labraCustomerOnboarding'
  )
  const { value: cloudSettingsRevamp } = useFlagValue('cloudSettingsRevamp')

  const Settings = cloudSettingsRevamp ? (
    <LayoutWrapper
      title={'Settings'}
      component={SettingsComponent}
      paddingZero
    />
  ) : (
    <RouteApp title="Settings" component={SettingsComponent} />
  )

  const SidePanelWrapper = isLabraCustomerOnboarding
    ? SidePanelWrapperV2
    : SidePanelWrapperV1

  useEffect(() => {
    if (prevPartnerIdRef.current && partnerId !== prevPartnerIdRef.current) {
      window.location.reload()
    }
    prevPartnerIdRef.current = partnerId
  }, [partnerId])

  return (
    <>
      <SidePanelWrapper
        isNotInProductSelection={isNotInProductSelection}
        currentProduct={currentProduct}
        activeProducts={activeProducts}
      />
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route
            path="/user/profile"
            element={<RouteApp title="My profile" component={UserProfile} />}
          />
          <Route
            path="/users"
            element={
              <RouteApp
                title="User & Permission"
                component={UserAndPermission}
              />
            }
          />

          <Route path="/settings/*" element={Settings} />
          <Route
            path="/product-selection"
            element={<ProductSelectorWrapper />}
          />
          {(activeProducts.length > 0 || !isEmpty(currentProduct)) &&
            redirectToProduct(
              ProductRoutingMap,
              activeProducts,
              currentProduct
            )}
          <Route path="/" element={<Navigate to="/product-selection" />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </>
  )
}

const redirectToProduct = (
  ProductRoutingMap: Record<Product, string>,
  activeProducts: Product[],
  currentProduct?: Product
) => {
  const activeAndCurrentProduct = currentProduct
    ? uniq(activeProducts.concat(currentProduct))
    : activeProducts

  return [
    ...activeAndCurrentProduct.map(product => {
      const Comp = ProductRoutes[product]
      return (
        <Route
          key={product + 'activeAndCurrentProduct'}
          path={`/${ProductRoutingMap[product]}/*`}
          element={<Comp />}
        />
      )
    }),
    <Route
      key="redirect-to-product-or-product-selection"
      path="/"
      element={
        activeProducts.length > 0 ? (
          <Navigate to={`/${ProductRoutingMap[activeProducts[0]]}`} />
        ) : (
          <Navigate to={'/product-selection'} />
        )
      }
    />,
  ]
}
