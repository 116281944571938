import { PlanType } from '@labrav/react-components'
import { Reducer } from 'redux'
import { FreeTrialStatus } from '../freeTrials/reducer'
import { Features } from '../privateOffer/reducer'
import {
  AzurePriceInputOption,
  AzureRecurrentPriceField,
  EulaType,
  IncludedQuantity,
  PlanTypeGCP,
  Price,
} from '../privateOffer/reducer'
import { ProductsListingActionTypes } from './actions'

export interface links {
  self: string
  nextPage?: string
  previousPage?: string
}

export type CloudMarketplace = 'AWS' | 'GCP' | 'AZURE' | 'REDHAT'

export enum FlyOutAWSProductListingType {
  'SAAS' = "SAAS",
  'PROFESSIONAL_SERVICES' = "PROFESSIONAL_SERVICES",
  'CONTAINER' = "CONTAINER"
}

export interface FreeTrial {
  freeTrialStatus?: FreeTrialStatus
  freeTrialEnabled?: boolean
  eulaType?: EulaType
  eulaFiles?: string[]
  freeTrial?: boolean
  freeTrialEnabledAt?: string
  labraFreeTrialId?: string
  freeTrialOfferId?: string
  freeTrialExpiresInDays?: number
  cloudFreeTrialOfferId?: string
  cloudFreeTrialOfferName?: string
  enabled?: boolean
  quantity?: number
}

export enum ProductStatus {
  PRODUCT_STATUS_PUBLIC = 'Public',
  PRODUCT_STATUS_IN_PROGRESS = 'In-progress',
  PRODUCT_STATUS_LABRA_TESTING = 'Labra-testing',
  PRODUCT_STATUS_LIMITED = 'Limited',
  PRODUCT_STATUS_DRAFT = 'Draft',
  PRODUCT_STATUS_CREATION_FAILED = 'Failed',
  PRODUCT_STATUS_AWS_TESTING = 'AWS-testing',
  PRODUCT_STATUS_RESTRICTED = 'Restricted',
  PRODUCT_STATUS_REJECTED = 'Rejected',
}
export enum ContainerPricingModel {
  CONTRACT = 'CONTRACT',
  HOURLY = 'HOURLY',
  USAGE = 'USAGE',
  FREE = 'FREE',
  BYOL = 'BYOL',
  MONTHLY = 'MONTHLY'
}

export enum PriceTypes {
  'PRICE_1M' = 'price1m',
  'PRICE_12M' = 'price12m',
  'PRICE_24M' = 'price24m',
  'PRICE_36M' = 'price36m',
  'ADDITIONAL_USAGE_PRICE' = 'additionalUsagePrice',
  'SUSCRIPTION_PRICE' = 'subscriptionPrice',
}

export const resalePricesKey: string[] = [
  'price1m',
  'price12m',
  'price24m',
  'price36m',
  'additionalUsagePrice',
  'subscriptionPrice',
]

export interface CommonDimensionField {
  dimensionId?: string
  id?: string
  label: string
  name: string
  valueDataType?: string
  price?: Price
  createdAt?: string
  currency: string
}

export interface ProductDimension extends CommonDimensionField {
  type?: string
  labelDescription: string
  isAdditionalUsageAllowed?: boolean
  additionalUsuageDescription?: string
  prices?: Price
  pricePerName: string
  awsProductId: string
  freeTrial?: FreeTrial
}
export interface AzurePlanDimensionMeter {
  cloudDimensionId: string
  id?: string
  azureDimensionId: string
  label: string
  name: string
  valueDataType?: string
  createdAt?: string
  currency: string
  includedQuantities: IncludedQuantity[]
  pricePerPaymentInUsd: number
  unitOfMeasure: string
}

export interface AzurePlanDimension {
  meters: AzurePlanDimensionMeter[]
  priceInputOption: AzurePriceInputOption
}

export interface GcpPlanPrice {
  price1m: number | null
  price12m: number | null
  price24m: number | null
  price36m: number | null
}
export interface ProductPlan {
  planId: string
  azurePlanId?: string
  cloudPlanId?: string
  displayName: string
  description: string
  minQuantity?: number
  maxQuantity?: number
  planType?: PlanTypeGCP
  prices?: GcpPlanPrice
  dimensions: AzurePlanDimension | CommonDimensionField[]
  pricing?: AzureRecurrentPriceField[]
  features?: Features[]
}

export interface Product {
  productId: string
  productName: string
  cloudMarketplaceProductId: string
  numberOfContracts: number
  numberOfActiveContracts?: number
  cloudMarketplace: CloudMarketplace
  status: ProductStatus
  marketplaceUrl: string
  productDimensions?: ProductDimension[]
  sellerId: string
  createdAt: string
  productType?: PlanType
  updatedAt: string
  freeTrial?: FreeTrial
  links: links
  emailCustomMessage?: string
  plans?: ProductPlan[]
  containerPricingModel?:ContainerPricingModel
}

export interface ProductsListingState {
  selectedProduct?: string
  products: {
    rows: Product[]
  }
}

export const initialState: ProductsListingState = {
  products: {
    rows: [],
  },
}

type actionType =
  | {
      type: ProductsListingActionTypes.SET_PRODUCTS
      payload: ProductsListingState
    }
  | {
      type: ProductsListingActionTypes.CLEAR_PRODUCTS
    }
  | {
      type: ProductsListingActionTypes.UPDATE_CLOUD_FREE_TRIAL_OFFER_ID
      payload: { productId: string; cloudFreeTrialOfferId: string }
    }
  | {
      type: ProductsListingActionTypes.SET_SELECTED_PRODUCT
      payload: string
    }

export const reducer: Reducer<ProductsListingState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ProductsListingActionTypes.SET_PRODUCTS: {
      return {
        ...state,
        products: action.payload.products,
      }
    }
    case ProductsListingActionTypes.CLEAR_PRODUCTS: {
      return {
        ...state,
        products: {
          rows: [],
        },
      }
    }
    case ProductsListingActionTypes.UPDATE_CLOUD_FREE_TRIAL_OFFER_ID: {
      const { productId, cloudFreeTrialOfferId } = action.payload
      const rows = [
        ...state.products.rows.map(product => {
          if (product.productId === productId) {
            const freeTrial = {
              ...product.freeTrial,
              cloudFreeTrialOfferId,
            }
            return {
              ...product,
              freeTrial,
            }
          } else {
            return product
          }
        }),
      ]
      return {
        ...state,
        products: {
          rows,
        },
      }
    }
    case ProductsListingActionTypes.SET_SELECTED_PRODUCT: {
      return {
        ...state,
        selectedProduct: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
