import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { AxiosResponse } from 'axios'
import {
  CancelPrivateOfferFailureMessage,
  CancelPrivateOfferProgressMessage,
  PatchPrivateOfferGeneralMessage,
  PatchPrivateOffersFailureMessage,
  PatchPrivateOffersSuccessMessage,
  RequestFailureMessage,
} from '../../../common/utils/messagesContants'
import { AnyAction } from 'redux'
import { AppDispatch, RootState } from '../../../store'
import {
  getPrivateOffersData,
  getSinglePrivateOffer,
  patchPrivateOffersData,
  getSearchedData,
} from '../../api/markeplace'
import { camelize, snakeize } from 'casing'
import { getErrorMessages } from '../../../common/utils/error'
import { errorLogger } from '../../../common/utils/errorLogger'
import { EulaType, OfferStatus } from './reducer'
import { CloudMarketplace, Product } from '../productsListing/reducer'
import {
  getAzurePrivateOffersData,
  getAzureSinglePrivateOffer,
  patchAzurePrivateOffersData,
} from '../../api/azure'
import {
  getGCPPrivateOffersData,
  getGcpSinglePrivateOffer,
  patchGCPPrivateOffersData,
} from '../../api/gcp'
import { getConvertedGCPPlanDataForOffer } from './privateOfferCreation/actions'
import { isEmpty } from 'lodash'

export enum PrivateOfferActionTypes {
  SET_PRIVATE_OFFERS_DATA = 'SET_PRIVATE_OFFERS_DATA',
  PRIVATE_OFFERS_SET_PAGE_NUMBER = 'PRIVATE_OFFERS_SET_PAGE_NUMBER',
  PRIVATE_OFFERS_SET_LIMIT = 'PRIVATE_OFFERS_SET_LIMIT',
  UPDATE_PRIVATE_OFFERS_DATA = 'UPDATE_PRIVATE_OFFERS_DATA',
  CLEAR_PRIVATE_OFFERS_DATA = 'CLEAR_PRIVATE_OFFERS_DATA',
  SET_CURRENT_PRIVATE_OFFER = 'SET_CURRENT_PRIVATE_OFFER',
  CLEAR_CURRENT_PRIVATE_OFFER = 'CLEAR_CURRENT_PRIVATE_OFFER',
}

const getPrivateOfferAPIBasedOnCloud = async (
  partnerId: string,
  cloud: CloudMarketplace,
  privateOfferId: string,
  crmOpportunityId?: string
) => {
  switch (cloud) {
    case 'AWS': {
      return getSinglePrivateOffer(partnerId, privateOfferId, crmOpportunityId)
    }
    case 'AZURE': {
      return getAzureSinglePrivateOffer(
        partnerId,
        privateOfferId,
        crmOpportunityId
      )
    }
    case 'GCP': {
      return getGcpSinglePrivateOffer(
        partnerId,
        privateOfferId,
        crmOpportunityId
      )
    }
  }
}

const getPrivateOffersAPIBasedOnCloud = async (
  partnerId: string,
  cloud: CloudMarketplace,
  pageSize?: number,
  pageNumber?: number,
  productId?: string,
  crmOpportunityId?: string,
  input?: string,
  selectedInput?: string,
  sectionName?: string
) => {
  switch (cloud) {
    case 'AWS': {
      if (isEmpty(input)) {
        return getPrivateOffersData({
          partnerId,
          pageSize,
          pageNumber,
          productId,
          crmOpportunityId,
        })
      } else {
        const response = await getSearchedData({
          partnerId,
          productId,
          input,
          selectedInput,
          pageSize,
          pageNumber,
          sectionName
        })
        const numberOfPage = response?.data?.pagination_object?.current_page
        const totalCount = response?.data.total_count
        const rows = response?.data.records.map((recordItem: any) => ({
          ...recordItem._source,
          private_offer_id: recordItem._source.id,
        }))
        return {
          ...response,
          data: {
            private_offers: {
              page_size: pageSize,
              page_number: numberOfPage,
              rows: rows,
              total_count: totalCount,
            },
          },
        }
      }
    }
    case 'AZURE': {
      return getAzurePrivateOffersData({
        partnerId,
        pageSize,
        pageNumber,
        productId,
        crmOpportunityId,
      })
    }
    case 'GCP': {
      return getGCPPrivateOffersData({
        partnerId,
        pageSize,
        pageNumber,
        productId,
        crmOpportunityId,
      })
    }
  }
}

const mapPrivateOffer = (offerItem: any, idKey: string) => {
  const { [idKey]: privateOfferId, eulaFiles, ...remainingOffer } = offerItem
  return {
    privateOfferId,
    eulaFile: eulaFiles ? [...(eulaFiles as string[])] : [],
    ...remainingOffer,
  }
}
export const processPrivateOffers = async ({
  cloudMarketplace,
  response,
}: {
  cloudMarketplace: CloudMarketplace
  response: any
}) => {
  if (cloudMarketplace !== 'AZURE' && cloudMarketplace !== 'GCP') {
    return response?.data.private_offers
  }
  let updatedOffers
  const responseData = camelize(response?.data)
  if (cloudMarketplace === 'AZURE') {
    updatedOffers = responseData?.privateOffers.map((offerItem: any) =>
      mapPrivateOffer(offerItem, 'id')
    )
  } else {
    updatedOffers = responseData?.privateOffers.rows.map((offerItem: any) =>
      mapPrivateOffer(offerItem, 'labraOfferId')
    )
  }
  const privateOffers = {
    rows: updatedOffers,
    pageNumber: responseData?.pageNumber ?? 0,
    pageSize: responseData?.pageSize ?? 10,
    totalCount: responseData?.totalCount ?? 0,
    sortBy: responseData?.sortBy ?? '',
    sortOrder: responseData?.sortOrder ?? 'desc',
    links: responseData?.links ?? {},
  }
  return privateOffers
}

export const getPrivateOffers =
  ({
    partnerId,
    cloudMarketplace,
    pageSize,
    pageNumber,
    productId,
    crmOpportunityId,
    input,
    selectedInput,
    sectionName
  }: {
    partnerId: string
    cloudMarketplace: CloudMarketplace
    pageSize?: number
    pageNumber?: number
    productId?: string
    crmOpportunityId?: string
    input?: string
    selectedInput?: string
    sectionName?: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const response = await getPrivateOffersAPIBasedOnCloud(
        partnerId,
        cloudMarketplace,
        pageSize,
        pageNumber,
        productId,
        crmOpportunityId,
        input,
        selectedInput,
        sectionName
      )
      const privateOffers = await processPrivateOffers({
        cloudMarketplace,
        response,
      })
      await dispatch(setPrivateOffersData(camelize(privateOffers)))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      await dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setPrivateOffersData = (data: Record<string, unknown>) =>
  ({
    type: PrivateOfferActionTypes.SET_PRIVATE_OFFERS_DATA as PrivateOfferActionTypes.SET_PRIVATE_OFFERS_DATA,
    payload: {
      data: data.rows,
      pageNumber: data.pageNumber ?? 0,
      pageSize: data.pageSize ?? 10,
      totalCount: data.totalCount ?? 0,
      sortBy: data.sortBy ?? '',
      sortOrder: data.sortOrder ?? 'desc',
      links: data.links ?? {},
    },
  } as unknown as AnyAction)

export const setPageNumber = (pageNumber: number) =>
  ({
    type: PrivateOfferActionTypes.PRIVATE_OFFERS_SET_PAGE_NUMBER,
    payload: pageNumber,
  } as unknown as AnyAction)

export const updatePageSize =
  ({
    partnerId,
    cloudMarketplace,
    pageSize,
    productId,
    crmOpportunityId,
  }: {
    partnerId: string
    cloudMarketplace: CloudMarketplace
    pageSize: number
    productId?: string
    crmOpportunityId?: string
  }) =>
  async (dispatch: AppDispatch) => {
    await dispatch(setPageSize(pageSize))
    await dispatch(
      getPrivateOffers({
        partnerId,
        cloudMarketplace,
        pageSize,
        pageNumber: 1,
        productId,
        crmOpportunityId,
      }) as unknown as AnyAction
    )
  }

export const setPageSize = (pageSize: number) => ({
  type: PrivateOfferActionTypes.PRIVATE_OFFERS_SET_LIMIT,
  payload: pageSize,
})

export const updateOffer = (
  key: string,
  value: string,
  privateOfferId: string
) =>
  ({
    type: PrivateOfferActionTypes.UPDATE_PRIVATE_OFFERS_DATA as PrivateOfferActionTypes.UPDATE_PRIVATE_OFFERS_DATA,
    payload: {
      privateOfferId: privateOfferId,
      key: key,
      value: value,
    },
  } as unknown as AnyAction)

export const patchOfferData =
  (partnerId: string, privateOfferId: string, crmOpportunityId?: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const privateOffers = getState().privateOffers.privateOffers
      const { awsOfferId, cloudOfferUrl } = privateOffers[privateOfferId]
      await patchPrivateOffersData(
        partnerId,
        privateOfferId,
        snakeize({
          crmOpportunityId,
          awsOfferId: awsOfferId ?? '',
          cloudOfferUrl: cloudOfferUrl ?? '',
        })
      )
      dispatch(
        updateAppAlert({
          message: PatchPrivateOffersSuccessMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([PatchPrivateOffersFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

const mapSinglePrivateOffer = (
  data: any,
  idKey: string,
  cloudMarketplace: string
) => {
  const { eulaFiles, ...remainingOffer } = camelize(data)
  const commonData = {
    privateOfferId: remainingOffer[idKey],
    eulaFile: eulaFiles || [],
    ...remainingOffer,
  }
  if (cloudMarketplace === 'AZURE') {
    return {
      ...commonData,
      azurePricingType:
        remainingOffer?.plans?.length > 0
          ? remainingOffer?.plans[0].discountType
          : '',
      eulaType: EulaType.CUSTOM,
    }
  }
  return commonData
}
export const processSinglePrivateOffer = async ({
  cloudMarketplace,
  response,
  selectedProduct,
}: {
  cloudMarketplace: CloudMarketplace
  response: any
  selectedProduct?: Product
}) => {
  switch (cloudMarketplace) {
    case 'AZURE': {
      const privateOffer = mapSinglePrivateOffer(
        response?.data,
        'id',
        cloudMarketplace
      )
      const { id, ...restPrivateoffer } = privateOffer
      return restPrivateoffer
    }
    case 'GCP': {
      const {
        links,
        eulaFiles,
        labraOfferId,
        contractDuration,
        paymentType,
        labraPlanId,
        offerMetadata,
        plan: notUsedPlan,
        tcv,
        installmentSchedule,
        individualDiscount,
        ...remainingData
      } = camelize(response?.data)

      const offerPlan = getConvertedGCPPlanDataForOffer(
        response?.data,
        selectedProduct
      )

      return {
        ...remainingData,
        eulaFile: eulaFiles || [],
        privateOfferId: labraOfferId,
        notes: offerMetadata?.notes,
        emailCustomMessage: offerMetadata?.emailCustomMessage,
        ...offerPlan,
      }
    }
    default: {
      return response?.data
    }
  }
}

export const clearPrivateOffers = () =>
  ({
    type: PrivateOfferActionTypes.CLEAR_PRIVATE_OFFERS_DATA,
  } as unknown as AnyAction)

export const getCurrentPrivateOffer =
  (
    partnerId: string,
    privateOfferId: string,
    cloudMarketplace: CloudMarketplace,
    productId?: string,
    crmOpportunityId?: string
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(clearPrivateOffers())
    const selectedProduct =
      getState().productsListing[cloudMarketplace].products.rows.find(
        product => product.productId === productId
      ) || ({} as Product)
    try {
      const response = await getPrivateOfferAPIBasedOnCloud(
        partnerId,
        cloudMarketplace,
        privateOfferId,
        crmOpportunityId
      )
      const privateOffer = await processSinglePrivateOffer({
        cloudMarketplace,
        response,
        selectedProduct,
      })
      dispatch(setCurrentPrivateOffer(camelize(privateOffer)))
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setCurrentPrivateOffer = (data: Record<string, unknown>) =>
  ({
    type: PrivateOfferActionTypes.SET_CURRENT_PRIVATE_OFFER,
    payload: data,
  } as unknown as AnyAction)

export const clearCurrentPrivateOffers = () =>
  ({
    type: PrivateOfferActionTypes.CLEAR_CURRENT_PRIVATE_OFFER,
  } as unknown as AnyAction)

const patchOfferBasedOnCloud = async (
  cloud: CloudMarketplace,
  partnerId: string,
  privateOfferId: string,
  data: Record<string, unknown>,
  crmOpportunityId?: string
) => {
  switch (cloud) {
    case 'AWS': {
      return await patchPrivateOffersData(
        partnerId,
        privateOfferId,
        snakeize({ crmOpportunityId, ...data })
      )
    }
    case 'AZURE': {
      return await patchAzurePrivateOffersData(
        partnerId,
        privateOfferId,
        snakeize({ crmOpportunityId, ...data })
      )
    }
    case 'GCP': {
      return await patchGCPPrivateOffersData(
        partnerId,
        privateOfferId,
        snakeize({ crmOpportunityId, ...data })
      )
    }
  }
}

export const updatePrivateOfferForUser =
  (
    privateOfferId: string,
    data: Record<string, unknown>,
    crmOpportunityId?: string
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const cloud = getState().productListingFilters.cloudMarketplace
    await dispatch(startLoading(LoadingTypes.GENERAL))
    const { offerStatus, operation } = data
    const isCallForCancel =
      offerStatus === OfferStatus.PRIVATE_OFFER_RESTRICTION_IN_PROGRESS ||
      operation === 'UPDATE'
    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      await patchOfferBasedOnCloud(
        cloud,
        partnerId,
        privateOfferId,
        snakeize({ crmOpportunityId, ...data })
      )
      const successMessage = isCallForCancel
        ? CancelPrivateOfferProgressMessage
        : PatchPrivateOfferGeneralMessage
      dispatch(
        updateAppAlert({
          message: successMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      await dispatch(
        getCurrentPrivateOffer(
          partnerId,
          privateOfferId,
          cloud,
          getState().productsListing[cloud].selectedProduct
        ) as unknown as AnyAction
      )
    } catch (error: any) {
      const errorMessage =
        offerStatus === OfferStatus.PRIVATE_OFFER_RESTRICTION_IN_PROGRESS
          ? CancelPrivateOfferFailureMessage
          : RequestFailureMessage
      dispatch(
        updateAppAlert({
          message: getErrorMessages([errorMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
