import { AxiosResponse } from 'axios'
import { API } from '../../../common/api'
import {
  azureEngineServiceUrl,
  cloudAccountServiceUrl,
} from '../../../common/utils/constants'
import { Account } from '../../../common/modules/cmcm/types'
import { CloudType, cloudTypeToApiValue } from '../../../common/modules/types'
import { ProductType } from '../../../common/modules/partner/reducer'

export const fetchFlyoutAccountDetails = (
  name: string,
  cloudType: string,
  productId: string,
  partnerId: string
) => {
  return API.get(`${cloudAccountServiceUrl}/templates`, {
    params: {
      cloud_type: cloudType,
      partner_id: partnerId,
      product_id: productId,
    },
  })
}
export const getUrlFromCloudAccount = (partnerId: string) => {
  return API.get(
    `${cloudAccountServiceUrl}/oauth/${partnerId}/auth_response/azure`
  )
}
export const sendAzureData = (
  dataToSend: Record<string, unknown>,
  partnerId: string
) => {
  return API.post(
    `${cloudAccountServiceUrl}/oauth/${partnerId}/tokens/azure`,
    dataToSend
  )
}
export const sendAzurePartnerData = (
  dataToSend: Record<string, unknown>,
  partnerId: string
) => {
  return API.put(`${azureEngineServiceUrl}/partners/${partnerId}`, dataToSend)
}
export const postFlyoutAccountDetails = (data: Record<string, unknown>) => {
  return API.post(`${cloudAccountServiceUrl}/accounts`, data)
}

export const fetchOrganizationAccounts = (
  organizationId: string,

  cloudType: CloudType,

  productId?: ProductType
): Promise<AxiosResponse<{ rows: Account[] }>> =>
  API.get(`${cloudAccountServiceUrl}/accounts`, {
    params: {
      cloud_type: cloudTypeToApiValue(cloudType),
      organization_id: organizationId,
      product_id: productId ? productId : '',
    },
  })

export const patchAccount = (accountId: string, data: unknown) => {
  return API.patch(`${cloudAccountServiceUrl}/accounts/${accountId}`, data)
}

export const testTemplateEndpoint = (
  templateId: string,
  cloudType: CloudType,
  iamRoleArn: string,
  partnerId: string
) => {
  return API.get(
    `${cloudAccountServiceUrl}/templates/${templateId}/verifications`,
    {
      params: {
        cloud_type: cloudType,
        iam_role_arn: iamRoleArn,
        partner_id: partnerId,
      },
    }
  )
}
