//TBD REMOVE  Next, please fill out the data fields mapping form below message when new onboarding workflow is done
export const CRMAuthSuccessMessage =
  'Your CRM connection is authenticated and linked now.'
export const CRMAuthFailureMessage =
  'There was some problem while authenticating your CRM.'
export const AWSSettingsProgressMessage =
  'Your AWS setting is in progress and will be completed once your integration is moved to the production environment post UAT completion.'
export const AWSSettingsCompletionMessage =
  'Your AWS setting was completed successfully.'
export const AWSSettingsRejectionMessage = 'Your AWS setting failed.'
export const CRMSettingsINProgressMessage =
  'CRM setting is in progress. Please submit mapping for approval after adding rules of your choice.'
export const CRMSettingsReadyForReviewMessage =
  'Your CRM setting is in progress.'
export const CRMSettingsCompletionMessage =
  'Your CRM setting was completed successfully.'
export const CRMSettingsRejectionMessage =
  'Your CRM setting is rejected. Please contact your account manager for assistance.'
export const RequestFailureMessage = 'Request failed. Please try again!'
export const SupportRequestSuccess =
  'Your support request was submitted successfully. We will get back to you shortly.'
export const UserUpdationSuccessMessage = 'User profile successfully updated.'
export const UserUpdationFailureMessage = 'Error while updating user.'
export const SomeThingWentWrong = 'Something went wrong.'
export const SyncStatusFail = 'Sync update failed.'
export const PartnerUpdateSuccess = 'Partner updated successfully.'
export const PartnerUpdateError =
  'There was an error trying to update the partner'
export const S3ConnectionSuccessMessage = 'S3 connected successfully.'
export const S3ConnectionFailureMessage = 'S3 connection failed.'
export const DeleteSuccessMessage = 'Data cleared successfully.'
export const AddUserRequestSuccess =
  'Invitation sent successfully to all the users.'
export const InviteUserRequestSuccess =
  'Invitation sent successfully to the user.'
export const InviteUserUpdateRequestSuccess =
  'User details updated successfully.'
export const UpdateCRMSchemaSuccessMessage = 'CRM schema updated successfully.'
export const CRMConnectionSuccessMessage = 'CRM successfully connected.'
export const CRMConnectionFailureMessage = 'There was some problem while connecting your CRM.'
export const CRMDisconnectSuccessMessage = 'CRM successfully disconnected.'
export const CRMDisconnectFailureMessage =
  'There was some problem while disconnecting your CRM.'
export const MissingMappedFieldsMessage =
  'Your selected CRM account do not have some mapping fields. Please check CRM mapping fields below for more details'
export const DuplicatePhoneMessage = 'Phone number already added.'
export const DuplicateEmailMessage = 'Email already added.'
export const NotificationSavedSuccessMessage =
  'Information successfully updated.'
export const disconnectModalTitleSlack = 'Disconnect Slack Integration'
export const disconnectModalSubtitleSlack =
  'Do you want to disconnect your slack integration?'
export const disconnectModalAcceptButtonSlack = 'Confirm'
export const MappingNoWarningsMessage = 'No Mapping Error Found.'
export const AccountCreationSuccessMessage = 'Account created successfully.'
export const AccountUpdateSuccessMessage = 'Account updated successfully.'
export const MeteringSuccessMessage =
  'Metering sent successfully.'
export const PatchPrivateOffersSuccessMessage =
  'Offer URL / Offer ID successfully updated.'
export const PatchPrivateOffersFailureMessage =
  'Offer URL / Offer ID update failed.'
export const PatchPrivateOfferGeneralMessage =
  'Private offer has been successfully updated.'
export const CancelPrivateOfferProgressMessage =
  'Private offer cancellation in progress.'
export const CancelPrivateOfferFailureMessage =
  'Private offer cancellation failed.'
export const CopiedToClipboardSuccessMessage = 'Private offer URL copied.'
export const CopiedToClipboardUnsuccessfulMessage =
  'Private offer URL copying failed.'
export const DefaultErrorOnFetchData = 'Error getting data, please try again'
export const DefaultErrorOnSubmitData = 'Error sending data, please try again'
export const DefaultSuccesrOnSubmitData = 'Successfully submitted'
export const UploadToS3Unsuccessful = 'Error while uploading eula files'
export const freeTrialRequestSuccess =
  'Free Trial request received successfully. You will be notified on email once it is live.'
export const freeTrialRequestUnsuccess =
  'Free Trial request failed. Please try again.'
export const freeTrialOfferIdPatchRequestSuccess =
  'Offer ID updated succesfully.'
export const freeTrialOfferIdPatchRequestUnsuccess =
  'Failed to update Offer ID.'
export const freeTrialDeactivateRequestSuccess =
  'Free Trial deactivation request received successfully. You will be notified on email once it is deactivated.'
export const freeTrialDeactivateRequestUnsuccess =
  'Free Trial deactivation request failed. Please try again.'
export const ResellerPatchSuccessMessage =
  'Agreement Id/Status updated successfully'
export const defaultFieldsError =
  'Some of the form fields below need to be corrected, please review and fix them below.'
export const cosellDefaultFieldsError =
  'Please complete all required fields to be able to proceed.'
export const privateOfferCreationSuccess =
  'Private offer creation was successful.'
export const privateOfferSaveSuccess = 'Private offer saved successfully'
export const PatchResaleAuthorizationGeneralMessage =
  'Resale authorization has been successfully updated.'
export const resaleAuthorizationCreationSuccess =
  'Resale authorization creation was successful.'
export const resaleAuthorizationSaveSuccess =
  'Resale authorization saved successfully'
export const offerFailedMessage = 'Offer creation has failed.'
export const offerCancelledMessage = 'This offer has been cancelled.'
export const offerExpiredMessage = 'This offer has expired.'
export const offerExpiringSoonMessage = 'Offer is going to expire soon.'
export const ListingTypeUpdationUnsuccessful = 'Listing Type cannot be updated.'
export const WaitForMigrationSync =
  "Fetching all your private offers, please don't refresh your page until you get a success message."
export const productStatusUpdateSuccessMessage =
  'Status updated to Active sucessfully'
export const productUpdateSnsMessage = 'Successfully subscribed to SNS Topics.'
export const productUpdateSnsErrorMessage =
  'Oops! Something went wrong. Could not subscribe to SNS Topics. Please try after some time.'
export const FlyOutSettingsSuccessMessage =
  'Your details and AWS connection has been added successfully.'
export const offerCancellationInProgressMessage =
  'Offer cancellation in progress.'
export const flyOutOnboardingSentSuccessfully =
  'Onboarding Data updated successfully.'
export const flyOutOnboardingCompletedSuccessfully =
  'Your onboarding is successfully completed. You can now get started with creating your first listing from the Product listings page.'
export const flyOutOnboardingMigrationCompletedSuccessfully =
  'Your product(s) have been successfully selected and are in queue for migration.'
export const flyOutOnboardingMigrationFailedMessage =
  'Your product(s) could not be selected for migration. Please try again!'
export const mandatoryFieldMessage =
  'Enter a non-empty value without spaces in start and end'
export const awsAccountIdFieldValidationMessage =
  'Please provide a 12 digit correct AWS Account ID'
export const awsAccountIdIntegerValidationMessage =
  'Please provide a positive integer value'
export const casSNSTopicValidationMessage =
  'Please provide a valid CAS SNS topic ARN'
export const iamArnValidationMessage = 'Please provide a valid IAM ARN'
export const casRoleNameArnValidationMessage =
  'Please provide a valid CAS ROLE ARN'
export const casS3ValidationMessage = 'Please provide a valid CAS S3 bucket ARN'
export const accountNameValidationMessage =
  'Please enter a valid AWS account name'
export const arnTestConnectionFailureMessage =
  'Test connection failed. Try again.'
export const sellerCountryOriginValidationMessage =
  'Country has to be added to be able to proceed.'
export const websiteValidationMessage = 'Website is required'
export const supportContactsMaxLimitMessage = 'Max 2000 characters are allowed'
export const emptySpacesMessage =
  'Values cannot start or end with whitespace characters (space, tab, newline)'
export const websiteValidationCheckMessage =
  'Provide a valid URL (Beginning with https://)'
export const productCategoriesProfessionalServicesMessage =
  'Must select at least 1 professional service category from Assessments, Implementation, Managed Services, Premium Support, or Training.'
export const mandatoryEmailMessasge = 'At least one email is required'
export const mandatoryNumberFieldMessage = 'This field cannot be empty'
export const mandatoryUsGovInTitleErrMessage =
  'Product title must include "GovCloud" if it\'s exclusively for GovCloud.'
export const mandatoryUsGovInTitleMessage =
  'You must include "GovCloud" somewhere in your product title.'
export const mustHaveMoreWordsForGovCloudMessage =
  'Please enter the complete product title.'
export const paymentDateMessage = 'Payment date is required.'
export const paymentDateValidationMessage =
  'The payment date cannot be after the contract duration date.'
export const enterNumberMessage = 'Please enter a number.'
export const enterAmountMessage = 'Please enter amount.'
export const amountGreaterThanZeroMsg =
  'amount must be greater than or equal to 0.'
export const threeDecimalMsg = 'Only 3 decimal places allowed.'
export const duplicateDateMessage = 'Duplicate payment dates are not allowed.'
export const onePaymentBeforeServiceDateMsg =
  'Only one payment date is allowed before offer expiration date.'
export const nonEmptySpaceMessage =
  'Provide a non empty value without spaces in beginning'
export const enterNumberMsg = 'Please enter a number.'
export const enterQuantityMsg = 'Please enter quantity.'
export const quantityNegativeMsg = 'Quantity cannot take negative values.'
export const qauntityDecimalMsg = 'Quantity cannot take decimal values.'
export const quantityMoreThan0Msg = 'Quantity should be more than 0.'
export const enterPriceMsg = 'Please enter price.'
export const productDimMessage =
  'Please choose at least one product dimension to proceed.'
export const enterUsageDuration =
  'Please provide the usage duration for the offer.'
export const usageDurationNegativeMsg =
  'Usage duration cannot take negative values.'
export const usageDurationDecimalMsg =
  'Usage duration cannot take decimal values.'
export const usageDurationMoreThan0Msg = 'Usage duration should be more than 0.'
export const awsAccountIdRegex = /^\d{12}$/
export const awsRegexValidationMessage =
  'AWS Account ID cannot contain special characters.'
export const missignFieldMessage = 'Please provide the missing field.'
export const monthsDurationMessage =
  'Duration must be less than or equal to 60 months.'
export const daysDurationMessage =
  'Duration must be less than or equal to 1095 days.'
export const monthsDurationRegex = /^(?!0)([1-9]|[1-5][0-9]|60)$/
export const dyasDurationRegex =
  /^(?!0)([1-9][0-9]{0,2}|10[0-8][0-9]|109[0-5])$/
export const dimensionPriceValidationMessage =
  'Please provide dimension price for the offer.'
export const priceGreaterThanZeroMessage = 'value must be greater than 0'
export const threeDecimalMessage = 'Only 3 decimal places allowed.'
export const doubleQuoteMessage = 'Values cannot contain double quotes.'
export const ssoInviteDisableMessage = 'Please ask your SSO admin to invite the team members to ensure they have the appropriate permissions and access.'

export const productCreationSuccess = 'Listing created successfully.'
export const draftProductCreationSuccess = 'Draft listing has been saved successfully.'
export const clearDraftProductCreationSuccess = 'Your listing form has been cleared successfully.'

export const clearDraftProductCreationFail = 'Your listing form could not be cleared. Please try again.'
export const draftProductCreationFail = 'Draft could not be submitted. Please try after some time.'

export const migrationProductRequiredMessage = 'Select at least one product to proceed.'