import { isEmpty } from 'lodash'

export const isOneHourContractDuration = (value?: string) => {
  return value === '1H'
}

export const canAddDuration = (containerOfferServiceLength?: string | null) => {
  return (
    containerOfferServiceLength === null ||
    (containerOfferServiceLength &&
      !['', '1H', '1Y', '2Y', '3Y', '1M', '12M', '24M', '36M'].includes(
        containerOfferServiceLength
      ))
  )
}

export const getDisplayContractDurationValue = (contractDuration?: string) => {
  if (isEmpty(contractDuration)) {
    return ''
  }

  if (contractDuration === '1H') {
    return 'Hourly'
  } else {
    const contractDurationValue = contractDuration?.slice(0, -1)
    if (contractDuration?.endsWith('M')) {
      return `${contractDurationValue} ${
        contractDurationValue === '1' ? 'month' : 'months'
      }`
    } else if (contractDuration?.endsWith('Y')) {
      return `${contractDurationValue} ${
        contractDurationValue === '1' ? 'year' : 'years'
      }`
    } else if (contractDuration?.endsWith('D')) {
      return `${contractDurationValue} ${
        contractDurationValue === '1' ? 'day' : 'days'
      }`
    }
  }
}
