import { CloudType } from '@labrav/react-components'
import { AnyAction, Reducer } from 'redux'
import { TemplateRows } from '../../../oppsync/modules/productAccount/reducer'
import { CLOUD_ACCOUNT_ACTIONS } from './actions'

export enum AccountType {
  ROOT,
  ORGANIZATION_UNIT,
  MEMBER,
}

export type Account = {
  id: string
  cloudType: CloudType
  organizationId: string
  accountDetails: {
    id: string
    externalAccountId: string
    name: string
    type: AccountType
    cloudAccountId: string
    roles: { rows: TemplateRows[] }
    createdAt?: string
    status?: string
  }
}

export type CloudAccountState = Account[] | null

export const initialState = null

export const reducer: Reducer<CloudAccountState> = (
  state = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case CLOUD_ACCOUNT_ACTIONS.SET_ACCOUNTS: {
      return action.payload
    }
    default:
      return state
  }
}
