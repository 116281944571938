import { useSelector } from '../../../store'
import { PartnerType } from '../../modules/partner/action'
import { ProductType } from '../../modules/partner/reducer'
import { isAdminRole } from '../isAdminRole'

export const useUserType = () => {
  return useSelector<PartnerType>(state => {
    const isAdmin = isAdminRole(state.userProfile.userProfile)
    return isAdmin ? PartnerType.Admin : PartnerType.User
  })
}

export const usePartnerId = () => {
  const userType = useUserType()
  return useSelector<string | undefined>(
    state => state.PartnerData[userType]?.partnerData?.partnerId
  )
}

export const useCurrentProduct = (defaultProduct: ProductType) => {
  const userType = useUserType()
  return useSelector<ProductType>(
    state => state.PartnerData[userType]?.currentProduct || defaultProduct
  )
}

export const useSubscribedProducts = () => {
  return useSelector(state => {
    const defaultSubscribedProducts = {
      OPPSYNC: {
        cloudProviders: ["aws"],
      },
      FLYOUT: {
        cloudProviders: ["aws"],
      },
    }
    const subscribedProducts =
      state?.PartnerData?.user?.partnerData?.subscribedProducts ||
      defaultSubscribedProducts
    return subscribedProducts
  })
}

export const useCrmType = () => {
  return useSelector(state => {
    const crmType = state?.PartnerData?.user?.partnerData?.crmType
    return crmType ?? 'labra'
  })
}
