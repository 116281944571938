import {
  CloudProvider,
  PartnerActionTypes,
  PartnerData,
  PartnerIAMPolicy,
} from './action'
import { syncHistoryPayload } from '../../../oppsync/modules/syncHistory/reducer'
import { CloudType } from '../types'
import { TestButtonStatus } from '@labrav/react-components/lib/@types/components/TestButtonComponent'

export type ProductType = 'oppsync' | 'cloudfaas' | 'flyout'
export interface PartnerDataState {
  partnerData: null | PartnerData
  selectedPartnerId: string
  isLoading: boolean
  error: unknown
  lastSyncDate: string
  lastSyncStatus: string
  currentProduct?: ProductType
}
const initialState: PartnerDataState = {
  isLoading: false,
  error: null,
  partnerData: null,
  selectedPartnerId: '',
  lastSyncDate: '',
  lastSyncStatus: '',
}

type Action =
  | { type: PartnerActionTypes.SET_PARTNER; payload: PartnerData }
  | { type: PartnerActionTypes.SET_SELECTED_PARTNER_ID; payload: string }
  | { type: PartnerActionTypes.GET_PARTNER_FAILED; payload: unknown }
  | { type: PartnerActionTypes.PARTNER_LOADING; payload: boolean }
  | { type: PartnerActionTypes.SET_CRM_ID; payload: string }
  | { type: PartnerActionTypes.PARTNER_CLEANUP }
  | { type: PartnerActionTypes.SET_LAST_SYNC; payload: syncHistoryPayload }
  | { type: PartnerActionTypes.SET_CURRENT_PRODUCT; payload: ProductType }
  | { type: PartnerActionTypes.CLEAR_CURRENT_PRODUCT }
  | { type: PartnerActionTypes.SET_CLOUD; payload: CloudProvider | CloudType }
  | { type: PartnerActionTypes.SET_IAM_POLICY; payload: PartnerIAMPolicy }
  | {
      type: PartnerActionTypes.SET_S3_STATUS
      payload: { status: TestButtonStatus; env: 'production' | 'staging' }
    }
  | {
      type: PartnerActionTypes.SET_IF_AWS_CUSTOM_CATALOG_UPLOAD
      payload: boolean
    }

export const PartnerReducer = (
  state: PartnerDataState = initialState,
  action: Action
) => {
  switch (action.type) {
    case PartnerActionTypes.SET_PARTNER: {
      const partnerDataPayload = {
        ...state.partnerData,
        ...action.payload,
        // activeCloudProvider: state.partnerData?.activeCloudProvider,
      }
      return {
        ...state,
        isLoading: false,
        error: null,
        partnerData: partnerDataPayload,
      }
    }
    case PartnerActionTypes.SET_CLOUD: {
      const cloud = action.payload.toUpperCase() as CloudType
      return {
        ...state,
        partnerData: {
          ...state.partnerData,
          activeCloudProvider: cloud,
        },
      }
    }
    case PartnerActionTypes.SET_SELECTED_PARTNER_ID:
      return { ...state, selectedPartnerId: action.payload }

    case PartnerActionTypes.PARTNER_LOADING:
      return { ...state, isLoading: true, error: null }

    case PartnerActionTypes.GET_PARTNER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case PartnerActionTypes.SET_CRM_ID: {
      return {
        ...state,
        isLoading: false,
        error: null,
        partnerData: { ...state.partnerData, crmId: action.payload },
      }
    }
    case PartnerActionTypes.SET_LAST_SYNC: {
      return {
        ...state,
        isLoading: false,
        error: null,
        lastSyncDate: action.payload.data.syncs[0].completedAt,
        lastSyncStatus: action.payload.data.syncs[0].status,
      }
    }
    case PartnerActionTypes.SET_CURRENT_PRODUCT: {
      return {
        ...state,
        isLoading: false,
        error: null,
        currentProduct: action.payload,
      }
    }
    case PartnerActionTypes.PARTNER_CLEANUP:
      return initialState

    case PartnerActionTypes.CLEAR_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: undefined,
      }
    case PartnerActionTypes.SET_S3_STATUS:
      return {
        ...state,
        partnerData: {
          ...state.partnerData,
          s3Status: {
            ...state.partnerData?.s3Status,
            [action.payload.env]: action.payload.status,
          },
        },
      }

    case PartnerActionTypes.SET_IAM_POLICY:
      switch (action.payload.env) {
        case 'production':
          return {
            ...state,
            partnerData: {
              ...state.partnerData,
              iamPolicyProduction: action.payload.value,
            },
          }

        case 'staging':
          return {
            ...state,
            partnerData: {
              ...state.partnerData,
              iamPolicyStaging: action.payload.value,
            },
          }
      }
      break
    case PartnerActionTypes.SET_IF_AWS_CUSTOM_CATALOG_UPLOAD:
      return {
        ...state,
        partnerData: {
          ...state.partnerData,
          hasAWSCustomCatalog: action.payload,
        },
      }

    default:
      return state
  }
}
