import { AnyAction } from 'redux'
import {
  startLoading,
  stopLoading,
} from '../../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../../common/modules/appAlert/actions'
import { getErrorMessages } from '../../../../common/utils/error'
import {
  privateOfferCreationSuccess,
  privateOfferSaveSuccess,
  RequestFailureMessage,
} from '../../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { newrelicErrLogger } from '../../../../common/utils/ErrorHandler'
import emptyPrivateOffer from '../../../../mocks/emptyPrivateOffers.json'
import emptyAzurePrivateOffer from '../../../../mocks/emptyAzurePrivateOffer.json'
import emptyGcpPrivateOffer from '../../../../mocks/emptyGcpPrivateOffer.json'
import { camelize, snakeize } from 'casing'
import { AppDispatch, RootState } from '../../../../store'
import { PlanType } from '@labrav/react-components'
import {
  CloudMarketplace,
  CommonDimensionField,
  ContainerPricingModel,
  Product,
  ProductDimension,
  ProductPlan,
} from '../../productsListing/reducer'
import {
  OffersWithErrors,
  getErrorObjectForAField,
  getUpdatedErrors,
  createErrorObjectForPlan,
  convertProductPlanPricingToOffer,
} from './reducer'
import {
  AzureOfferPlan,
  AzurePOPricing,
  AzurePriceInputOption,
  AzurePricingType,
  Dimension,
  DimensionType,
  EntitlementOption,
  EulaType,
  GCPInstallments,
  GCPOfferPlan,
  GCPOfferPlanDimesnion,
  GcpPostPaymentTypes,
  Installments,
  OfferStatus,
  MetaData,
  OfferRecipients,
  PaymentType,
  PlanTypeGCP,
  PriceTypes,
  PrivateOffer,
  OfferStakeholders,
} from '../reducer'
import { cloneDeep, get, isEmpty, isNil, lowerCase, upperCase } from 'lodash'
import {
  getSinglePrivateOffer,
  patchPrivateOffersData,
  postPrivateOffer,
} from '../../../api/markeplace'
import { defaultCrmType } from '../../../../common/utils/constants'
import { ResellerInfo } from '../../../../admin/modules/resaleAuthorizations/reducer'
import {
  getAzureSinglePrivateOffer,
  patchAzurePrivateOffersData,
  postAzurePrivateOffer,
} from '../../../api/azure'
import { DateTime } from 'luxon'
import { isNonEmptyNumber } from '../../../../common/utils/validateMinMax'
import { getDiscountedPriceValue } from '../../../../common/utils/getDiscountedPriceValue'
import {
  getGcpSinglePrivateOffer,
  putGCPPrivateOffersData,
} from '../../../api/gcp'
import { useSelector } from 'react-redux'
import { UserState } from '../../../../oppsync/modules/userList/reducer'
import { isAdminOrOwner } from '../../../../common/utils/isAdminRole'
import { isOneHourContractDuration } from '../../../utils/containerOfferHelperFunctions'
import { PartnerType } from '../../../../common/modules/partner/action'
export enum PrivateOfferCreationActionTypes {
  SET_CREATE_PRIVATE_OFFERS_DATA = 'SET_CREATE_PRIVATE_OFFERS_DATA',
  UPDATE_PRIVATE_OFFERS = 'UPDATE_PRIVATE_OFFERS',
  ADD_ROWS_ON_PRIVATE_OFFER = 'ADD_ROWS_ON_PRIVATE_OFFER',
  DELETE_ROWS_ON_PRIVATE_OFFER = 'DELETE_ROWS_ON_PRIVATE_OFFER',
  UPDATE_VALUES_OF_ARRAY_ON_PRIVATE_OFFER = 'UPDATE_VALUES_OF_ARRAY_ON_PRIVATE_OFFER',
  UPDATE_PRIVATE_OFFER_CREATION_ERRORS = 'UPDATE_PRIVATE_OFFER_CREATION_ERRORS',
  SET_ERRORS_FOR_MULTIPLE_FIELDS = 'SET_ERRORS_FOR_MULTIPLE_FIELDS',
  ADD_EULA_FILES_ON_PRIVATE_OFFERS = 'ADD_EULA_FILES_ON_PRIVATE_OFFERS',
  UPDTE_PRIVATE_OFFER_HAS_BEEN_SENT = 'UPDTE_PRIVATE_OFFER_HAS_BEEN_SENT',
  CLEAR_PRIVATE_OFFER_IN_CREATION = 'CLEAR_PRIVATE_OFFER_IN_CREATION',
  UPDATE_STANDARD_DIMENSIONS_ON_PRIVATE_OFFER = 'UPDATE_STANDARD_DIMENSIONS_ON_PRIVATE_OFFER',
  UPDATE_NUMBER_OF_TIMES_SAVED_ON_PRIVATE_OFFER = 'UPDATE_NUMBER_OF_TIMES_SAVED_ON_PRIVATE_OFFER',
  UPDATE_PRODUCT_PLAN_ON_PRIVATE_OFFER = 'UPDATE_PRODUCT_PLAN_ON_PRIVATE_OFFER',
  UPDATE_PRIVATE_OFFER_SINGLE_FIELD_ERROR = 'UPDATE_PRIVATE_OFFER_SINGLE_FIELD_ERROR',
}
const validateFields = [
  'installments',
  'offerExpirationDate',
  'dimensions',
  'offerRecipients',
  'companyName',
  'awsAccountId',
  'emailCustomMessage',
  'privateOfferName',
  'serviceLength',
  'metaData',
  'subscriptionEndDate',
  'billingAccountId',
  'offerStartDate',
  'variableStartDate',
  'acceptBy',
  'plans',
  'preparedBy',
  'agreementStartDate',
]

export const arrayFieldsForValidations = [
  'installments',
  'dimensions',
  'offerRecipients',
  'metaData',
]

const getUpdatedDimensionsForEmptyPOAndResaleSubscription = (
  productDimensions: ProductDimension[],
  isResale?: boolean
) => {
  return productDimensions.map(dimension => {
    const {
      id,
      createdAt,
      price,
      prices,
      freeTrial,
      valueDataType,
      ...remainingData
    } = dimension
    const updatedDimension: ProductDimension = {
      ...remainingData,
      dimensionId: id,
      type: remainingData.isAdditionalUsageAllowed
        ? 'additional_usage'
        : 'standard',
    }
    if (isResale) {
      updatedDimension.prices = prices
    } else {
      updatedDimension.price = (prices as PriceTypes)
        ?.subscriptionPrice as unknown as PriceTypes
    }
    return updatedDimension
  })
}
const getUpdatedDimensionsForEmptyPOAndResaleContract = (
  productDimensions: ProductDimension[],
  isResale = false
) => {
  const additionalUsageDimensions =
    productDimensions.filter(dimension => dimension.isAdditionalUsageAllowed) ||
    []
  return additionalUsageDimensions.map(dimension => {
    const {
      id,
      createdAt,
      price,
      prices,
      freeTrial,
      valueDataType,
      ...remainingData
    } = dimension
    const updatedDimension = {
      ...remainingData,
      price: (prices as PriceTypes)?.additionalUsagePrice ?? null,
      dimensionId: id,
      type: 'additional_usage',
    }
    if (isResale) {
      return {
        ...updatedDimension,
        prices,
      }
    } else {
      return updatedDimension
    }
  })
}
const getAdditionalUsageDimensionsForContainer = (
  productDimensions: ProductDimension[]
) => {
  return productDimensions.map(dimension => {
    const {
      id,
      createdAt,
      price,
      prices,
      freeTrial,
      valueDataType,
      ...remainingData
    } = dimension

    const updatedDimension = {
      ...remainingData,
      price: (prices as PriceTypes)?.additionalUsagePrice ?? null,
      dimensionId: id,
      type: 'additional_usage',
      isAdditionalUsageAllowed: true,
    }

    return updatedDimension
  })
}
const containerDimensions = (product: Product) => {
  const additionalDimensions = getAdditionalUsageDimensionsForContainer(
    product.productDimensions as ProductDimension[]
  )
  if (
    product.containerPricingModel === ContainerPricingModel.HOURLY ||
    product.containerPricingModel === ContainerPricingModel.USAGE
  ) {
    const dimensionsWithType = product?.productDimensions?.map(dimension => ({
      ...dimension,
      type: 'standard',
      dimensionId: dimension?.id,
    }))

    const updatedDimensions = [
      ...(dimensionsWithType || []),
      ...additionalDimensions,
    ]
    return updatedDimensions
  } else {
    const updatedDimensions = [
      {
        label: '',
        labelDescription: '',
        type: 'standard',
        isAdditionalUsageAllowed: false,
        currency: 'USD',
      },
    ]
    return updatedDimensions
  }
}

export const getUpdatedDimensionsForDraftPO = (
  dimensions: Dimension[],
  productId: string
) => {
  return dimensions.map((dimension: Dimension) => {
    const { id, createdAt, ...remainingDimension } = dimension
    remainingDimension.awsProductId = productId
    return remainingDimension
  })
}
export const getUpdatedDimensions = (
  dimensions: ProductDimension[],
  planType?: PlanType,
  isResale?: boolean
) => {
  if (planType === PlanType.SAAS_SUBSCRIPTION) {
    return getUpdatedDimensionsForEmptyPOAndResaleSubscription(
      dimensions,
      isResale
    )
  } else {
    return getUpdatedDimensionsForEmptyPOAndResaleContract(dimensions, isResale)
  }
}

const getInitialPrivateOfferDataBasedOnCloud = (
  cloud: CloudMarketplace,
  product: Product,
  getState: () => RootState,
  crmOpportunityId?: string,
  labraNewUiRevamp?: boolean,
  partnerType: PartnerType = PartnerType.User,
) => {
  const crm_opportunity_id = crmOpportunityId ?? null
  const email_custom_message = product?.emailCustomMessage || ''
  const { email, firstName, lastName, givenName, familyName } =
    getState().userProfile.userProfile
  const { offerRecipients } =
    getState().privateOffersInCreation.currentPrivateOfferInCreation[
      product?.productId
    ] || []
  const users = getState().userList[partnerType].userList.users || []
  const adminUsers = users.filter((user: any) => isAdminOrOwner(user))
  const firstAdminUser = adminUsers.slice(0, 1)

  switch (cloud) {
    case 'AWS': {
      const planType = product?.productType

      const initialOfferRecipients = labraNewUiRevamp
        ? (offerRecipients && offerRecipients.length) > 0
          ? offerRecipients
          : [{ email: '', title: '', firstName: '', lastName: '' }]
        : []

      const convertedDimensions = getUpdatedDimensions(
        product?.productDimensions || [],
        planType
      ) as Dimension[]
      return {
        ...emptyPrivateOffer,
        crm_opportunity_id,
        email_custom_message,
        entitlement_option:
          labraNewUiRevamp && product.productType === PlanType.CONTAINER
            ? product.containerPricingModel === ContainerPricingModel.CONTRACT
              ? EntitlementOption.SINGLE
              : EntitlementOption.MANY
            : EntitlementOption.SINGLE,
        eula_type:
          labraNewUiRevamp && product.productType === PlanType.CONTAINER
            ? 'PUBLIC'
            : planType === PlanType.PROFESSIONAL_SERVICES
            ? 'CUSTOM'
            : 'STANDARD',
        awsProductId: product?.productId,
        ...(planType === PlanType.SAAS_CONTRACT ? {variable_start_date: true} : {}),
        dimensions:
          labraNewUiRevamp && product.productType === PlanType.CONTAINER
            ? containerDimensions(product)
            : convertedDimensions,
        offer_recipients: initialOfferRecipients,
        ...(labraNewUiRevamp && product.productType === PlanType.CONTAINER
          ? {
              container_offer_serviceLength:
                product?.containerPricingModel !==
                ContainerPricingModel.CONTRACT
                  ? '1Y'
                  : '12M',
            }
          : {}),
        ...(labraNewUiRevamp && product.productType === PlanType.CONTAINER
          ? {
              installments: [
                { paymentDate: '', amount: null, currency: 'USD' },
              ],
              usageDuration: null,
            }
          : {}),
        ...(labraNewUiRevamp && product.productType === PlanType.CONTAINER
          ? { meta_data: [{ key: '', value: '' }] }
          : {}),
        ...(labraNewUiRevamp && product.productType === PlanType.CONTAINER
          ? {
              offerStakeholders: crmOpportunityId
                ? []
                : [
                    {
                      email: firstAdminUser[0].email,
                      name: firstAdminUser[0].name,
                    },
                  ],
              customOfferStakeholders: [],
            }
          : {}),
      }
    }
    case 'AZURE': {
      return {
        ...emptyAzurePrivateOffer,
        crm_opportunity_id,
        email_custom_message,
        azurePricingType: '',
        variable_start_date: undefined,
        prepared_by: email || '',
      }
    }
    case 'GCP': {
      return {
        ...emptyGcpPrivateOffer,
        crm_opportunity_id,
        email_custom_message,
        individual_discount: false,
        offerStakeholders: crmOpportunityId
          ? []
          : [
              {
                firstName: firstName || givenName,
                lastName: lastName || familyName,
                email: email,
              },
            ],
      }
    }
  }
}

const getContainerServiceLengthValueFromOffer = (
  offerResonse: PrivateOffer,
  product?: Product
) => {
  const containerOfferServiceLength =
    offerResonse.containerOfferServiceLength as string

  if (product?.productType === PlanType.CONTAINER) {
    return ['1M', '12M', '24M', '36M'].includes(containerOfferServiceLength)
      ? containerOfferServiceLength
      : containerOfferServiceLength.replace('M', '')
  } else {
    return ['1H', '1Y', '2Y', '3Y'].includes(containerOfferServiceLength)
      ? containerOfferServiceLength
      : containerOfferServiceLength.replace('D', '')
  }
}

const mapOfferAWSOfferToContainerOffer = (
  offerResonse: PrivateOffer,
  getState: () => RootState,
  product?: Product,
  partnerType: PartnerType = PartnerType.User,
) => {
  const { offerStakeholders, offerRecipients, metaData, installments } =
    offerResonse
  const users = getState().userList[partnerType].userList.users.map(({ email }) => email)
  const stackHolderExisting =
    (offerStakeholders || [])?.filter(item => users.includes(item.email)) || []
  const stackHolderCustom =
    (offerStakeholders || [])?.filter(item => !users.includes(item.email)) || []

  const installmentsInfo =
    (installments || []).length > 0
      ? installments
      : [{ paymentDate: '', amount: null, currency: 'USD' }]

  return {
    offerStakeholders: [...stackHolderExisting],
    customOfferStakeholders: [...stackHolderCustom],
    offerRecipients:
      (offerRecipients || []).length > 0
        ? offerRecipients
        : [{ email: '', title: '', firstName: '', lastName: '' }],
    metaData: (metaData || []).length > 0 ? metaData : [{ key: '', value: '' }],
    containerOfferServiceLength: getContainerServiceLengthValueFromOffer(
      offerResonse,
      product
    ),
    installments: installmentsInfo,
    ...getContainerOfferDimensions(offerResonse, product),
  }
}

const getContainerOfferDimensions = (
  offerResonse: PrivateOffer,
  product?: Product
) => {
  const standardDimensionEmpty =
    (offerResonse?.dimensions || []).filter(
      dimensionItem => dimensionItem.type === 'standard'
    ).length === 0

  const dimensionsWithType = (product?.productDimensions || [])?.map(
    dimension => ({
      ...dimension,
      type: 'standard',
      dimensionId: dimension?.id,
    })
  )

  if (isOneHourContractDuration(offerResonse?.containerOfferServiceLength)) {
    return {
      dimensions: getUpdatedDimensionsForDraftPO(
        (offerResonse?.dimensions || [])?.concat(
          dimensionsWithType as unknown as Dimension[]
        ),
        product?.productId!
      ),
    }
  } else if (
    (product?.containerPricingModel === ContainerPricingModel.CONTRACT ||
      (product?.containerPricingModel === ContainerPricingModel.USAGE &&
        offerResonse?.flexiblePaymentTerms)) &&
    standardDimensionEmpty
  ) {
    return {
      dimensions: getUpdatedDimensionsForDraftPO(
        (offerResonse?.dimensions || [])?.concat([
          {
            label: '',
            labelDescription: '',
            type: 'standard',
            isAdditionalUsageAllowed: false,
            currency: 'USD',
          },
        ]),
        product?.productId!
      ),
    }
  } else {
    return {}
  }
}

export const getPrivateOfferDataFromAPIResponse = async (
  cloud: CloudMarketplace,
  {
    partnerId,
    privateOfferId,
    crmOpportunityId,
    productId,
    product,
  }: {
    productId: string
    partnerId: string
    privateOfferId: string
    crmOpportunityId?: string
    product?: Product
  },
  dispatch: AppDispatch,
  getState?: () => RootState
) => {
  try {
    switch (cloud) {
      case 'AWS': {
        const { data } = await getSinglePrivateOffer(
          partnerId,
          privateOfferId,
          crmOpportunityId
        )
        const { productName, links, ...remainingData } = camelize(data)

        return {
          ...remainingData,
          awsProductId: productId,
          offerExpirationDate: convertDateFromAPIResponse(
            (remainingData as PrivateOffer)?.offerExpirationDate
          ),
          ...( product?.productType === PlanType.SAAS_CONTRACT && remainingData.variableStartDate === false  ? {
            agreementStartDate: convertDateFromAPIResponse(
              (remainingData as PrivateOffer)?.agreementStartDate
            ),
          } : {}),
          dimensions: getUpdatedDimensionsForDraftPO(
            remainingData.dimensions || [],
            productId
          ),
          ...(product?.productType === PlanType.CONTAINER && getState
            ? {
                ...mapOfferAWSOfferToContainerOffer(
                  remainingData as PrivateOffer,
                  getState,
                  product
                ),
              }
            : {}),
        }
      }
      case 'AZURE': {
        const { data } = await getAzureSinglePrivateOffer(
          partnerId,
          privateOfferId,
          crmOpportunityId,
          productId
        )
        const { links, id, eulaFiles, plans, ...remainingData } = camelize(data)

        const planMapping = plans.map((plan: any) => {
          const productPlans = product?.plans ? [...product.plans] : []
          const currentProductPlan = productPlans.find(
            planItem => planItem.planId === plan?.labraPlanId
          )
          const originalPricingData = currentProductPlan
            ? convertProductPlanPricingToOffer(currentProductPlan)
            : {}
          const isLabraDiscountPrice =
            plan.discountType === AzurePricingType.LABRA_PERCENTAGE

          const updatedPlanPricing = {
            ...plan.pricing,
            recurrentPrice: {
              ...plan.pricing.recurrentPrice,
              userLimits: {
                min: plan?.pricing?.recurrentPrice?.userLimits?.min,
                max: plan?.pricing?.recurrentPrice?.userLimits?.max,
                minEnable: true,
                maxEnable: true,
              },
            },
          }
          return {
            ...plan,
            ...(isLabraDiscountPrice
              ? {
                  pricing: { ...originalPricingData },
                  originalPricing: { ...originalPricingData },
                }
              : {
                  pricing: { ...updatedPlanPricing },
                  originalPricing: { ...originalPricingData },
                }),
          }
        })
        return {
          ...remainingData,
          draft: true,
          eulaFile: eulaFiles || [],
          privateOfferId: id,
          azurePricingType: plans.length > 0 ? plans[0].discountType : '',
          plans: [...planMapping],
          eulaType: EulaType.CUSTOM,
          offerExpirationDate: convertDateFromAPIResponse(
            (remainingData as PrivateOffer)?.offerExpirationDate
          ),
          acceptBy: convertDateFromAPIResponse(
            (remainingData as PrivateOffer)?.acceptBy
          ),
          offerStartDate: convertDateFromAPIResponse(
            (remainingData as PrivateOffer)?.offerStartDate
          ),
        }
      }
      case 'GCP': {
        const { data } = await getGcpSinglePrivateOffer(
          partnerId,
          privateOfferId,
          crmOpportunityId,
          productId
        )
        const {
          links,
          eulaFiles,
          labraOfferId,
          contractDuration,
          paymentFrequency,
          labraPlanId,
          offerMetadata,
          plan: notUsedPlan,
          tcv,
          installmentSchedule,
          individualDiscount,
          ...remainingData
        } = camelize(data)

        const offerPlan = getConvertedGCPPlanDataForOffer(data, product)

        return {
          ...remainingData,
          eulaFile: eulaFiles || [],
          offerExpirationDate: convertDateFromAPIResponse(
            (remainingData as PrivateOffer)?.offerExpirationDate
          ),
          privateOfferId: labraOfferId,
          notes: offerMetadata?.notes,
          emailCustomMessage: offerMetadata?.emailCustomMessage,
          ...offerPlan,
        }
      }
    }
  } catch (err: any) {
    dispatch(
      updateAppAlert({
        message: getErrorMessages([RequestFailureMessage])(
          err.response as AxiosResponse<ErrorResponse>
        ),
        messageType: 'ERROR',
        autoClose: true,
      })
    )
  }
}

export const getConvertedGCPPlanDataForOffer = (
  apiOfferResponseData: any,
  product?: Product
) => {
  const {
    labraPlanId,
    paymentFrequency,
    individualDiscount,
    installmentSchedule,
    tcv,
    contractDuration,
  } = camelize(apiOfferResponseData)
  const { plan } = apiOfferResponseData
  let offerPlan = {}
  if (plan) {
    const {
      metric_pricing,
      installment_info,
      payment_frequency,
      ...remainingPlan
    } = plan
    const otherPlanInfo = camelize(remainingPlan)
    const productPlan = product?.plans?.find(
      planItem => planItem.planId === labraPlanId
    )
    const planType = productPlan?.planType

    const planMeters =
      planType === PlanTypeGCP.USAGE ||
      (planType === PlanTypeGCP.FLAT_FEE_USAGE &&
        GcpPostPaymentTypes.includes(paymentFrequency))
        ? convertGCPPlanMeterObjectToArray(plan?.metric_pricing, productPlan)
        : undefined

    const planInstallments =
      paymentFrequency === PaymentType.CUSTOM
        ? {
            installmentInfo: plan?.installment_info.map(
              (installmentInfoItem: any) => ({
                flatFeePrice: installmentInfoItem?.flat_fee_price,
                installmentDate: convertDateFromAPIResponse(
                  installmentInfoItem?.installment_date as string
                ),
                metricPricing:
                  planType === PlanTypeGCP.FLAT_FEE_USAGE
                    ? convertGCPPlanMeterObjectToArray(
                        installmentInfoItem?.metric_pricing,
                        productPlan
                      )
                    : undefined,
              })
            ),
          }
        : {}

    const discountValue = !individualDiscount
      ? {
          ...((planType === PlanTypeGCP.USAGE ||
            (planType === PlanTypeGCP.FLAT_FEE_USAGE &&
              GcpPostPaymentTypes.includes(paymentFrequency))) &&
          planMeters
            ? { discount: get(planMeters, '[0].discount', undefined) }
            : {}),
          ...(planType === PlanTypeGCP.FLAT_FEE_USAGE &&
          paymentFrequency === PaymentType.CUSTOM &&
          planInstallments.installmentInfo
            ? {
                discount: get(
                  planInstallments.installmentInfo[0],
                  'metricPricing[0].discount',
                  undefined
                ),
              }
            : {}),
        }
      : {}
    offerPlan = {
      plan: {
        ...otherPlanInfo,
        planId: labraPlanId,
        description: productPlan?.description,
        paymentType: paymentFrequency,
        planType,
        tcv,
        installmentSchedule: lowerCase(installmentSchedule),
        individualDiscount: individualDiscount,
        serviceLength: contractDuration,
        ...discountValue,
        ...planInstallments,
        ...(planMeters
          ? {
              metricPricing: planMeters,
            }
          : {}),
      },
    }
  }
  return offerPlan
}

export const convertGCPPlanMeterObjectToArray = (
  planMeters: any,
  productPlan?: ProductPlan
) => {
  const meters: GCPOfferPlanDimesnion[] = []
  if (productPlan) {
    if (planMeters) {
      const productPlanMeters = productPlan?.dimensions
      Object.keys(planMeters).forEach(planMeterItem => {
        const productPlanDimension = (
          productPlanMeters as CommonDimensionField[]
        ).find(dimensionItem => dimensionItem.id === planMeterItem)

        meters.push({
          id: planMeterItem,
          price: planMeters[planMeterItem].price,
          discount: planMeters[planMeterItem].discount,
          currency: productPlanDimension?.currency || 'USD',
          label: productPlanDimension?.label || '',
          name: productPlanDimension?.name || '',
        })
      })
    } else {
      return getDimensionFromProduct(productPlan)
    }
  }
  return meters
}

export const convertDateFromAPIResponse = (date?: string) => {
  return date
    ? DateTime.fromISO(date).toFormat('yyyy-MM-dd').toLocaleString()
    : null
}

export const getArrayFieldsForValidation = (cloud: CloudMarketplace) => {
  switch (cloud) {
    case 'AWS': {
      return arrayFieldsForValidations
    }
    case 'AZURE': {
      return ['offerRecipients', 'metaData', 'plans']
    }
    case 'GCP': {
      return []
    }
    case 'REDHAT': {
      return []
    }
  }
}
export const getCurrentPrivateOfferInCreation =
  ({
    productId,
    partnerId,
    privateOfferId,
    crmOpportunityId,
    labraNewUiRevamp = false,
  }: {
    productId: string
    partnerId: string
    privateOfferId: string
    crmOpportunityId?: string
    labraNewUiRevamp?: boolean
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(clearPrivateOfferInCreation(productId))
    const cloudMarketplace = getState().productListingFilters.cloudMarketplace
    const product =
      getState().productsListing[cloudMarketplace].products.rows.find(
        product => product.productId === productId
      ) || ({} as Product)
    let offerData = {}

    if (isEmpty(privateOfferId)) {
      offerData = {
        ...getInitialPrivateOfferDataBasedOnCloud(
          cloudMarketplace,
          product,
          getState,
          crmOpportunityId,
          labraNewUiRevamp
        ),
      }
    } else {
      offerData = await getPrivateOfferDataFromAPIResponse(
        cloudMarketplace,
        {
          productId,
          partnerId,
          privateOfferId,
          crmOpportunityId,
          product,
        },
        dispatch,
        getState
      )
    }
    const finalData = camelize({
      ...offerData,
    })
    const errors =
      cloudMarketplace === 'GCP'
        ? {}
        : createOfferErrors(
            finalData,
            getArrayFieldsForValidation(cloudMarketplace)
          )
    await dispatch(
      setPrivateOfferInCreation({
        productId,
        data: { ...finalData },
        errors,
        noOfTimesSaved: isEmpty(privateOfferId) ? 0 : 2,
      })
    )
  }
export type setPrivateOfferInCreationProps = {
  productId: string
  data: Record<string, unknown>
  errors: Record<string, unknown>
  noOfTimesSaved: number
}
export const setPrivateOfferInCreation = ({
  productId,
  data,
  errors,
  noOfTimesSaved = 0,
}: setPrivateOfferInCreationProps) =>
  ({
    type: PrivateOfferCreationActionTypes.SET_CREATE_PRIVATE_OFFERS_DATA,
    payload: { productId, privateOfferData: data, errors, noOfTimesSaved },
  } as unknown as AnyAction)

export const updatePrivateOfferInCreation =
  (data: KeyValue, productId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const cloudType = getState().productListingFilters.cloudMarketplace
    await dispatch(setPrivateOffersData(data, productId))
    if (validateFields.includes(data.key)) {
      await dispatch(
        setErrorsOnPrivateOfferCreation(
          data.key as string,
          productId,
          cloudType
        )
      )
    }
  }
export const updateStandardDimensionsOnPrivateOffer = (
  index: number,
  productId: string,
  dimension: Dimension
) => ({
  type: PrivateOfferCreationActionTypes.UPDATE_STANDARD_DIMENSIONS_ON_PRIVATE_OFFER,
  payload: { index, productId, dimension },
})
export const setPrivateOffersData = (data: KeyValue, productId: string) => ({
  type: PrivateOfferCreationActionTypes.UPDATE_PRIVATE_OFFERS,
  payload: { privateOfferData: data, productId },
})
export const addRowsOnPrivateOffer = (
  key: string,
  productId: string,
  type = 'standard'
) => ({
  type: PrivateOfferCreationActionTypes.ADD_ROWS_ON_PRIVATE_OFFER,
  payload: { key, productId, type },
})
export const deleteRowsOnPrivateOffer =
  (key: string, index: number, productId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const cloudType = getState().productListingFilters.cloudMarketplace
    await dispatch(deleteRows(key, index, productId))
    if (validateFields.includes(key)) {
      await dispatch(setErrorsOnPrivateOfferCreation(key, productId, cloudType))
    }
  }
export const deleteRows = (key: string, index: number, productId: string) => ({
  type: PrivateOfferCreationActionTypes.DELETE_ROWS_ON_PRIVATE_OFFER,
  payload: { key, index, productId },
})
export type KeyValue = {
  key: string
  value: string | boolean | number | ResellerInfo[] | null | undefined
}
export const updatevaluesInArrayOfPrivateOffers =
  (key: string, index: number, privateOfferData: KeyValue, productId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const cloudType = state.productListingFilters.cloudMarketplace
    const standardDimension = state.productsListing[
      cloudType
    ].products.rows.filter(val => val.productId === productId)[0]
      .productDimensions

    await dispatch(
      setValuesInArrayOfPrivateOffers(
        key,
        index,
        privateOfferData,
        productId
      ) as unknown as AnyAction
    )
    if (validateFields.includes(key)) {
      const timeZone = getState().userProfile.userProfile.timeZone || 'UTC'
      await dispatch(
        setErrorsOnPrivateOfferCreation(
          key,
          productId,
          cloudType,
          standardDimension,
          privateOfferData.key,
          index,
          timeZone
        ) as unknown as AnyAction
      )
    }
  }

export const setValuesInArrayOfPrivateOffers = (
  key: string,
  index: number,
  privateOfferData: KeyValue,
  productId: string
) => ({
  type: PrivateOfferCreationActionTypes.UPDATE_VALUES_OF_ARRAY_ON_PRIVATE_OFFER,
  payload: { key, privateOfferData, index, productId },
})
export const updateNoOfTimesSaved = (productId: string) => ({
  type: PrivateOfferCreationActionTypes.UPDATE_NUMBER_OF_TIMES_SAVED_ON_PRIVATE_OFFER,
  payload: productId,
})

export const setErrorsOnPrivateOfferCreation = (
  key: string,
  productId: string,
  cloudType: CloudMarketplace,
  standardDimensions?: ProductDimension[],
  field?: string,
  index?: number,
  timeZone?: string
) => ({
  type: PrivateOfferCreationActionTypes.UPDATE_PRIVATE_OFFER_CREATION_ERRORS,
  payload: {
    key,
    productId,
    cloudType,
    field,
    index,
    timeZone,
    standardDimensions,
  },
})

export const setSingleFieldErrorOnPrivateOfferCreation = (
  key: string,
  productId: string,
  errMsg: string
) => ({
  type: PrivateOfferCreationActionTypes.UPDATE_PRIVATE_OFFER_SINGLE_FIELD_ERROR,
  payload: {
    key,
    productId,
    errMsg,
  },
})

export const addEulaFilesOnPrivateOffer = (
  productId: string,
  eulaFiles: string[]
) => ({
  type: PrivateOfferCreationActionTypes.ADD_EULA_FILES_ON_PRIVATE_OFFERS,
  payload: { productId, eulaFiles },
})
export const getFilteredDimensions = (dimensions: Dimension[]) => {
  return dimensions.filter(dimension => {
    if (!isEmpty(dimension.isAdditionalUsageAllowed)) {
      if (!isEmpty(dimension.dimensionId)) {
        return dimension
      }
    } else {
      if (!isEmpty(dimension.name) && !isEmpty(dimension.labelDescription)) {
        return dimension
      }
    }
  })
}
export const getFilteredInstallments = (installments: Installments[]) => {
  return installments.filter(installment => {
    if (
      installment.amount &&
      installment.amount !== null &&
      !isEmpty(installment.paymentDate)
    ) {
      return installment
    }
  })
}
export const getZeroDollarPricing = (dimensions: Dimension[]) => {
  return dimensions.some(dimension => {
    if (dimension?.price && Number(dimension?.price) !== 0) {
      return false
    }
    return true
  })
}

export const returnOnlySpecificDimensionsField = (dimensions: Dimension[]) => {
  return dimensions.map(
    ({
      currency,
      type,
      awsProductId,
      createdAt,
      crmObjectId,
      dimensionId,
      isAdditionalUsageAllowed,
      labelDescription,
      label,
      name,
      price,
      quantity,
    }) => ({
      currency,
      type,
      awsProductId,
      createdAt,
      crmObjectId,
      dimensionId,
      isAdditionalUsageAllowed,
      labelDescription,
      label,
      name,
      price,
      quantity,
    })
  )
}

const getPlansforPayload = (
  plans?: AzureOfferPlan[],
  azurePricingType?: AzurePricingType,
  selectedProduct?: Product
) => {
  if (plans && azurePricingType && selectedProduct) {
    const updatedPlans = cloneDeep(plans).map(
      ({
        displayName,
        labraPlanId,
        offerPricingType,
        discountPercentage,
        pricing,
        originalPricing,
        description,
      }) => {
        const pricingData = {
          ...getAzurePricingObject({
            pricing,
            originalPricing,
            azurePricingType,
            discountPercentage,
          }),
        }

        const disountData =
          azurePricingType === AzurePricingType.LABRA_PERCENTAGE
            ? { discountPercentage }
            : {}

        return {
          displayName,
          labraPlanId,
          description,
          cloudProductId: selectedProduct.cloudMarketplaceProductId,
          cloudProductName: selectedProduct.productName,
          offerPricingType,
          discountType: azurePricingType,
          ...pricingData,
          ...disountData,
        }
      }
    )
    return [...updatedPlans]
  } else {
    return []
  }
}

const getAzurePricingObject = ({
  pricing,
  originalPricing,
  azurePricingType,
  discountPercentage,
}: {
  pricing: AzurePOPricing
  originalPricing?: AzurePOPricing
  azurePricingType?: AzurePricingType
  discountPercentage?: number
}) => {
  const { userLimits, prices, ...remainingRecurrentPrice } =
    pricing.recurrentPrice

  const isDiscounted = azurePricingType === AzurePricingType.LABRA_PERCENTAGE
  const updatedUserLimits = userLimits
    ? {
        min: userLimits.minEnable
          ? userLimits.min
          : originalPricing?.recurrentPrice.userLimits.min,
        max: userLimits.maxEnable
          ? userLimits.max
          : originalPricing?.recurrentPrice.userLimits.max,
      }
    : null
  const recurrentPricingPayload = {
    ...remainingRecurrentPrice,
    prices: prices.map((priceObject, idx) => ({
      ...priceObject,
      pricePerPaymentInUsd:
        isDiscounted && originalPricing?.recurrentPrice?.prices[idx]
          ? getDiscountedPriceValue({
              price:
                originalPricing.recurrentPrice.prices[idx]
                  .pricePerPaymentInUsd!,
              discount: discountPercentage,
            })
          : priceObject?.pricePerPaymentInUsd,
    })),
    userLimits:
      updatedUserLimits &&
      isNonEmptyNumber(updatedUserLimits.min) &&
      isNonEmptyNumber(updatedUserLimits.max)
        ? { ...updatedUserLimits }
        : null,
  }

  let mmetersPayload = {}
  pricing?.customMeters?.meters &&
    Object.keys(pricing?.customMeters?.meters).forEach(meterKey => {
      const currentMeter = { ...pricing?.customMeters?.meters[meterKey] }
      mmetersPayload = {
        ...mmetersPayload,
        [meterKey]: {
          ...currentMeter,
          pricePerPaymentInUsd:
            isDiscounted && originalPricing?.customMeters?.meters[meterKey]
              ? getDiscountedPriceValue({
                  price:
                    originalPricing.customMeters.meters[meterKey]
                      .pricePerPaymentInUsd!,
                  discount: discountPercentage,
                })
              : currentMeter?.pricePerPaymentInUsd,
          includedQuantities: pricing?.customMeters?.meters[
            meterKey
          ].includedQuantities.map(includedQuantityItem => ({
            billingTerm: includedQuantityItem.billingTerm,
            isInfinite: includedQuantityItem.isInfinite,
            ...(!includedQuantityItem.isInfinite
              ? { quantity: includedQuantityItem.quantity }
              : {}),
          })),
        },
      }
    })

  return {
    pricing: {
      recurrentPrice: { ...recurrentPricingPayload },
      customMeters: {
        priceInputOption: AzurePriceInputOption.USD,
        meters: {
          ...mmetersPayload,
        },
      },
    },
  }
}

export const getGCPPlanDataBasedOnType = (
  plan: GCPOfferPlan,
  individualDiscount?: boolean
) => {
  const commonPlanPayload = {
    id: plan?.planId,
    currency: 'USD',
  }
  if (plan?.planType === PlanTypeGCP.FLAT_FEE) {
    if (plan?.paymentType === PaymentType.CUSTOM) {
      return {
        ...commonPlanPayload,
        installmentInfo:
          plan?.serviceLength && plan?.installmentSchedule
            ? (plan?.installmentInfo || [])?.map(
                ({ flatFeePrice, installmentDate }) => ({
                  flatFeePrice,
                  installmentDate: DateTime.fromISO(
                    installmentDate as string
                  ).toISO(),
                })
              )
            : [],
      }
    } else {
      return {
        ...commonPlanPayload,
        flatFeePrice: plan?.flatFeePrice,
      }
    }
  } else if (plan?.planType === PlanTypeGCP.FLAT_FEE_USAGE) {
    if (plan?.paymentType === PaymentType.CUSTOM) {
      return {
        ...commonPlanPayload,
        installmentInfo:
          plan?.serviceLength && plan?.installmentSchedule
            ? (plan?.installmentInfo || [])?.map(
                ({ flatFeePrice, installmentDate, metricPricing }) => ({
                  flatFeePrice,
                  installmentDate: DateTime.fromISO(
                    installmentDate as string
                  ).toISO(),
                  metricPricing:
                    individualDiscount ||
                    (!individualDiscount && isNonEmptyNumber(plan?.discount))
                      ? (metricPricing || [])?.map(
                          ({ discount, id, price }) => ({
                            price,
                            cloudDimensionId: id,
                            discount: individualDiscount
                              ? discount
                              : plan?.discount,
                          })
                        )
                      : [],
                })
              )
            : [],
      }
    } else {
      return {
        ...commonPlanPayload,
        flatFeePrice: plan?.flatFeePrice,
        metricPricing:
          individualDiscount ||
          (!individualDiscount && isNonEmptyNumber(plan?.discount))
            ? (plan?.metricPricing || [])?.map(({ discount, id, price }) => ({
                price,
                cloudDimensionId: id,
                discount: individualDiscount ? discount : plan?.discount,
              }))
            : [],
      }
    }
  } else {
    return {
      ...commonPlanPayload,
      metricPricing:
        individualDiscount ||
        (!individualDiscount && isNonEmptyNumber(plan?.discount))
          ? (plan?.metricPricing || [])?.map(({ discount, id, price }) => ({
              price,
              cloudDimensionId: id,
              discount: individualDiscount ? discount : plan?.discount,
            }))
          : [],
    }
  }
}

const getOfferRecipients = (offerRecipients: OfferRecipients[]) => {
  return offerRecipients.filter(
    offerRecipientItem =>
      !isEmpty(offerRecipientItem.firstName) ||
      !isEmpty(offerRecipientItem.lastName) ||
      !isEmpty(offerRecipientItem.email) ||
      !isEmpty(offerRecipientItem.title)
  )
}

const getOfferMetaData = (metaData: MetaData[]) => {
  return metaData.filter(
    metaDataItem => !isEmpty(metaDataItem.key) || !isEmpty(metaDataItem.value)
  )
}

type PrivateOfferSpecificData = {
  containerOfferServiceLength: string
  usageDuration: string
  customOfferStakeholders: OfferStakeholders[]
  dimensions: Dimension[]
}

const getContainerOfferTransformForFormik = (
  privateOffer: OffersWithErrors,
  privateOfferSpecificData: PrivateOfferSpecificData,
  selectedProduct: Product
) => {
  const {
    containerOfferServiceLength,
    usageDuration,
    customOfferStakeholders,
    dimensions,
  } = privateOfferSpecificData

  const containerOfferServiceLengthValue = containerOfferServiceLength as string
  const contractDurationVal =
    selectedProduct.containerPricingModel === ContainerPricingModel.CONTRACT
      ? containerOfferServiceLengthValue +
        (containerOfferServiceLengthValue.endsWith('M') ? '' : 'M')
      : containerOfferServiceLengthValue +
        (containerOfferServiceLengthValue.endsWith('Y') ||
        containerOfferServiceLengthValue.endsWith('H')
          ? ''
          : 'D')

  return {
    usageDuration:
      !isNil(usageDuration) && usageDuration !== '' ? usageDuration : null,
    offerRecipients: getOfferRecipients(privateOffer?.offerRecipients),
    metaData: getOfferMetaData(privateOffer?.metaData),
    offerStakeholders: privateOffer?.offerStakeholders?.concat(
      (customOfferStakeholders || []) as OfferStakeholders[]
    ),
    containerOfferServiceLength: contractDurationVal,
    dimensions: isOneHourContractDuration(
      privateOffer?.containerOfferServiceLength
    )
      ? ((dimensions || []) as Dimension[]).filter(
          dimensionItem => dimensionItem.isAdditionalUsageAllowed
        )
      : dimensions,
  }
}

const getPayloadDataBasedOnCloud = (
  cloud: CloudMarketplace,
  privateOfferData: OffersWithErrors,
  productId: string,
  getState: () => RootState,
  saveAsDraft?: boolean,
  crmOpportunityId?: string
) => {
  const selectedProduct =
    getState().productsListing[cloud].products?.rows.find(
      product => product.productId === productId
    ) || ({} as Product)
  const { errors, hasBeenSent, noOfTimesSaved, ...privateOffer } =
    privateOfferData
  const {
    givenName: firstName,
    familyName: lastName,
    email,
  } = getState().userProfile.userProfile

  switch (cloud) {
    case 'AWS': {
      let privateOfferToBeSent = {} as Record<string, unknown>
      const offerStakeholders = [
        {
          firstName: firstName || '',
          lastName: lastName || '',
          email: email || '',
        },
      ]
      if (privateOffer.flexiblePaymentTerms) {
        const dimensions = privateOffer.dimensions!.map(dimension => {
          if (dimension.isAdditionalUsageAllowed) {
            const { type, ...remainingDimension } = dimension
            return remainingDimension
          }
          const { type, price, ...updatedDimension } = dimension
          return updatedDimension
        })
        const { installments, ...updatedOffer } = privateOffer
        privateOfferToBeSent = {
          ...updatedOffer,
          dimensions,
          installmentInfo: [...installments!],
        }
      } else {
        const dimensions = privateOffer.dimensions!.map(dimension => {
          if (dimension.isAdditionalUsageAllowed) {
            const { type, prices, ...remainingDimension } = dimension
            return remainingDimension
          }
          const { type, prices, quantity, ...updatedDimension } = dimension
          return updatedDimension
        })
        const { installments, ...updatedOffer } = privateOffer
        privateOfferToBeSent = {
          ...updatedOffer,
          dimensions,
          installmentInfo: installments,
        }
      }
      const zeroDollarPricing = getZeroDollarPricing(privateOffer.dimensions!)
      privateOfferToBeSent.zeroDollarPricing = zeroDollarPricing
      if (isEmpty(privateOffer.subscriptionEndDate)) {
        const { subscriptionEndDate, ...remainingOffer } = privateOfferToBeSent
        privateOfferToBeSent = { ...remainingOffer }
      }
      privateOfferToBeSent.dimensions = returnOnlySpecificDimensionsField(
        getFilteredDimensions(
          privateOfferToBeSent.dimensions as unknown as Dimension[]
        )
      )
      privateOfferToBeSent.installmentInfo = getFilteredInstallments(
        privateOfferToBeSent.installmentInfo as unknown as Installments[]
      )
      privateOfferToBeSent.offerExpirationDate = isEmpty(
        privateOfferData.offerExpirationDate
      )
        ? null
        : privateOfferData.offerExpirationDate
      privateOfferToBeSent.offerStakeholders = [...offerStakeholders]
      const {
        usageDuration,
        variableStartDate, 
        agreementStartDate,
        customOfferStakeholders,
        dimensions,
        containerOfferServiceLength,
        ...remainingOfferToBesent
      } = privateOfferToBeSent
      return {
        ...remainingOfferToBesent,
        ...( selectedProduct?.productType === PlanType.SAAS_CONTRACT ? { variableStartDate, agreementStartDate: !isEmpty(agreementStartDate) ? agreementStartDate : null }: {}),
        ...(selectedProduct?.productType === PlanType.CONTAINER
          ? {
              ...getContainerOfferTransformForFormik(
                privateOfferData,
                {
                  usageDuration: usageDuration as string,
                  customOfferStakeholders:
                    customOfferStakeholders as OfferStakeholders[],
                  dimensions: dimensions as Dimension[],
                  containerOfferServiceLength:
                    containerOfferServiceLength as string,
                },
                selectedProduct
              ),
            }
          : { dimensions }),
      }
    }
    case 'AZURE': {
      let privateOfferToBeSent = {} as Record<string, unknown>
      const {
        plans,
        crmOrderObjectId,
        variableStartDate,
        eulaFile,
        billingAccountId,
        companyName,
        metaData,
        offerRecipients,
        privateOfferName,
        emailCustomMessage,
        azurePricingType,
        privateOfferId,
        preparedBy,
      } = privateOffer
      const cloudProductId = selectedProduct.cloudMarketplaceProductId
      const updatedPlans = getPlansforPayload(
        plans,
        azurePricingType,
        selectedProduct
      )
      const offerStartDate = isEmpty(privateOffer.offerStartDate)
        ? null
        : DateTime.fromISO(privateOffer.offerStartDate!).toISO()

      const acceptBy = isEmpty(privateOffer.acceptBy)
        ? null
        : DateTime.fromISO(privateOffer.acceptBy!).toISO()

      const offerExpirationDate = isEmpty(privateOfferData.offerExpirationDate)
        ? null
        : DateTime.fromISO(privateOffer.offerExpirationDate!).toISO()

      privateOfferToBeSent = {
        crmOrderObjectId,
        privateOfferName,
        privateOfferType: 'customerPromotion',
        offerPricingType: 'saasNewCustomizedPlans',
        offerStartDate,
        offerExpirationDate,
        acceptBy,
        ...(preparedBy ? { preparedBy: preparedBy } : {}),
        eulaFiles: eulaFile,
        billingAccountId,
        cloudProductId,
        pricing: [],
        offerRecipients,
        companyName,
        emailCustomMessage,
        metaData,
        plans: [...updatedPlans],
        privateOfferId,
        labraProductId: selectedProduct.productId,
        productName: selectedProduct.productName,
        offerStakeholders: crmOpportunityId
          ? []
          : [
              {
                firstName: firstName || '',
                lastName: lastName || '',
                email: email || '',
                title: '',
              },
            ],
        ...(variableStartDate !== undefined ? { variableStartDate } : {}),
        ...(saveAsDraft
          ? { offerStatus: OfferStatus.PRIVATE_OFFER_IN_DRAFT }
          : { offerStatus: OfferStatus.PRIVATE_OFFER_CREATION_IN_PROGRESS }),
      }
      return privateOfferToBeSent
    }
    case 'GCP': {
      const {
        companyName,
        buyerCloudBillingId,
        eulaFile,
        autoRenew,
        emailCustomMessage,
        offerStakeholders,
        notes,
        eulaType,
        plan,
        renewalCount,
        privateOfferName,
        offerExpirationDate,
        offerRecipients,
        serviceStartDate,
        privateOfferId,
        labraPlanFeatures,
      } = privateOffer

      const tcvValue =
        plan?.planType !== PlanTypeGCP.USAGE
          ? {
              ...(plan?.paymentType &&
              GcpPostPaymentTypes.includes(plan?.paymentType) &&
              !isNil(plan?.serviceLength) &&
              !isNil(plan?.flatFeePrice)
                ? {
                    tcv: Number(
                      plan?.serviceLength * plan?.flatFeePrice
                    ).toFixed(2),
                  }
                : {}),
              ...(plan?.paymentType === PaymentType.CUSTOM
                ? { tcv: plan?.tcv }
                : {}),
            }
          : {}

      return {
        draft: saveAsDraft,
        crmOpportunityId,
        serviceStartDate,
        offerStatus: OfferStatus.PRIVATE_OFFER_CREATION_IN_PROGRESS,
        buyerCloudBillingId,
        privateOfferName,
        eulaType,
        paymentFrequency:
          plan?.planId && plan?.paymentType ? plan?.paymentType : undefined,
        companyName,
        eulaFiles: eulaFile,
        autoRenew,
        offerExpirationDate: DateTime.fromISO(
          offerExpirationDate as string
        ).toISO(),
        offerRecipients,
        offerStakeholders,
        offerMetadata: {
          notes,
          emailCustomMessage,
        },
        contractDuration: plan?.serviceLength,
        labraProductId: selectedProduct.productId,
        cloud: 'GCP',
        labraPlanId: plan?.planId ? plan?.planId : undefined,
        labraPlanFeatures,
        ...tcvValue,
        ...(plan?.installmentSchedule
          ? { installmentSchedule: upperCase(plan?.installmentSchedule) }
          : {}),
        individualDiscount: plan?.individualDiscount,
        plan:
          plan && plan?.planId
            ? getGCPPlanDataBasedOnType(plan, plan?.individualDiscount)
            : {},
        flexiblePaymentTerms: plan?.paymentType === PaymentType.CUSTOM,
        ...(privateOfferId
          ? { labraOfferId: privateOfferId, privateOfferId }
          : {}),
        ...(autoRenew ? { renewalCount: renewalCount } : {}),
      }
    }
    case 'REDHAT': {
      return {}
    }
  }
}

export const postOfferBasedOnCloud = async (
  cloud: CloudMarketplace,
  OfferPayload: {
    partnerId: string
    data: Record<string, unknown>
    saveAsDraft?: boolean
  }
) => {
  switch (cloud) {
    case 'AWS': {
      return await postPrivateOffer(OfferPayload)
    }
    case 'AZURE': {
      return await postAzurePrivateOffer(OfferPayload)
    }
    case 'GCP': {
      return await putGCPPrivateOffersData(OfferPayload)
    }
  }
}

export const patchOfferBasedOnCloud = async (
  cloud: CloudMarketplace,
  OfferPayload: {
    partnerId: string
    data: Record<string, unknown>
    saveAsDraft?: boolean
    privateOfferId: string
  }
) => {
  const { partnerId, data, saveAsDraft, privateOfferId } = OfferPayload
  switch (cloud) {
    case 'AWS': {
      return await patchPrivateOffersData(
        partnerId,
        privateOfferId as string,
        data,
        saveAsDraft
      )
    }
    case 'AZURE': {
      return await patchAzurePrivateOffersData(
        partnerId,
        privateOfferId as string,
        data,
        saveAsDraft
      )
    }
    case 'GCP': {
      return await putGCPPrivateOffersData({
        data,
        partnerId,
      })
    }
  }
}

export const sendPrivateOffer =
  ({
    partnerId,
    productId,
    saveAsDraft,
    crmOpportunityId,
    crmObjectLink,
    formikOffer,
    isV2,
    autoSave,
  }: {
    partnerId: string
    productId: string
    saveAsDraft?: boolean
    crmOpportunityId?: string
    crmObjectLink?: string
    formikOffer?: OffersWithErrors
    isV2?: boolean
    autoSave?: boolean
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if(!isV2){
      await dispatch(startLoading(LoadingTypes.GENERAL))
    }else{
      await dispatch(startLoading(LoadingTypes.SUBMIT_FLYOUT_Offer))
    }
    
    try {
      const selectedCloud = getState().productListingFilters.cloudMarketplace
      const privateOfferData =
        selectedCloud === 'GCP' || isV2
          ? formikOffer!
          : getState().privateOffersInCreation.currentPrivateOfferInCreation[
              productId
            ]
      const privateOfferToBeSent = getPayloadDataBasedOnCloud(
        selectedCloud,
        privateOfferData,
        productId,
        getState,
        saveAsDraft,
        crmOpportunityId
      )

      let crmMetadata = {} as Record<string, unknown>
      if (crmOpportunityId && crmObjectLink) {
        crmMetadata = {
          crmType: defaultCrmType,
          crmObjectLink,
        }
      }

      const { privateOfferId, ...privateOfferToBeSentWithoutId } =
        privateOfferToBeSent
      if (saveAsDraft) {
        privateOfferToBeSent.draft = true
      }

      if (isEmpty(privateOfferId)) {
        const payload = {
          partnerId,
          data: snakeize({
            ...privateOfferToBeSentWithoutId,
            ...(crmOpportunityId ? { crmMetadata } : {}),
          }),
          saveAsDraft,
        }
        const response = await postOfferBasedOnCloud(selectedCloud, payload)
        if (response && response.data) {
          if ((selectedCloud === 'GCP' || isV2) && formikOffer) {
            await updateFormikOfferAfterAPICall(
              formikOffer,
              dispatch,
              productId,
              0,
              camelize(response.data).privateOfferId ||
                (camelize(response.data).id as string)
            )
          } else {
            await dispatch(
              updatePrivateOfferInCreation(
                {
                  key: 'privateOfferId',
                  value:
                    camelize(response.data).privateOfferId ||
                    camelize(response.data).id,
                },
                productId
              ) as unknown as AnyAction
            )
          }
        }
      } else {
        const patchPayload = {
          partnerId,
          data: {
            ...snakeize({
              ...privateOfferToBeSentWithoutId,
              draft: saveAsDraft,
              ...(crmOpportunityId ? { crmMetadata } : {}),
              ...(selectedCloud === 'GCP'
                ? { labraOfferId: privateOfferId }
                : {}),
            }),
          },
          privateOfferId: privateOfferId as string,
          saveAsDraft,
        }
        await patchOfferBasedOnCloud(selectedCloud, patchPayload)
        if ((selectedCloud === 'GCP' || isV2) && formikOffer) {
          await updateFormikOfferAfterAPICall(
            formikOffer,
            dispatch,
            productId,
            2,
            privateOfferId as string
          )
        }
      }
      if (!saveAsDraft) {
        await dispatch(updateHasBeenSent(true, productId))
      } else {
        await dispatch(updateNoOfTimesSaved(productId))
      }
      dispatch(
        updateAppAlert({
          message: saveAsDraft
            ? privateOfferSaveSuccess
            : privateOfferCreationSuccess,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      newrelicErrLogger(error as Error, {
        message: error,
      })
    } finally {
      if(!isV2){
        await dispatch(stopLoading(LoadingTypes.GENERAL))
      }else{
        await dispatch(stopLoading(LoadingTypes.SUBMIT_FLYOUT_Offer))
      }
    }
  }

const updateFormikOfferAfterAPICall = async (
  formikOffer: OffersWithErrors,
  dispatch: AppDispatch,
  productId: string,
  noOfTimesSaved: number,
  privateOfferId?: string
) => {
  const { errors, ...remainingOffer } = formikOffer as OffersWithErrors
  await dispatch(
    setPrivateOfferInCreation({
      productId,
      data: { ...remainingOffer, privateOfferId: privateOfferId },
      errors: {},
      noOfTimesSaved,
    })
  )
}

export const updateHasBeenSent = (hasBeenSent: boolean, productId: string) => ({
  type: PrivateOfferCreationActionTypes.UPDTE_PRIVATE_OFFER_HAS_BEEN_SENT,
  payload: { hasBeenSent, productId },
})

export const initialPricesObject = {
  price1m: '',
  price12m: '',
  price24m: '',
  price36m: '',
  subscriptionPrice: '',
  additionalUsagePrice: '',
  price: '',
}
export const createOfferErrors = (
  data: Record<string, unknown>,
  arrayFieldsForOfferValidations: string[],
  isResale = false
) => {
  const errors = Object.keys(data).map(key => {
    if (arrayFieldsForOfferValidations.includes(key)) {
      const temp: Array<Record<string, unknown>> = [] as Array<
        Record<string, unknown>
      >
      if (data[key] !== null) {
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ;(data[key] as Array<Record<string, unknown>>).forEach(field => {
          if (isResale && key === 'dimensions') {
            temp.push({ ...initialPricesObject })
          } else if (key === 'plans') {
            temp.push({
              ...createErrorObjectForPlan(field as unknown as AzureOfferPlan),
            })
          } else {
            temp.push({
              ...getErrorObjectForAField(
                key,
                key === 'dimension'
                  ? (field as unknown as Dimension).type === 'custom'
                    ? 'custom'
                    : 'standard'
                  : ''
              ),
            })
          }
        })
        return temp
      } else {
        return []
      }
    } else return ''
  })
  const errorObject: Record<string, unknown> = {}
  Object.keys(data).forEach((key, index) => {
    errorObject[key] = errors[index]
  })
  return errorObject
}

export const clearPrivateOfferInCreation = (productId: string) => ({
  type: PrivateOfferCreationActionTypes.CLEAR_PRIVATE_OFFER_IN_CREATION,
  payload: productId,
})

export const updateProductPlanOnPrivateOffer = (
  index: number,
  productId: string,
  plan: AzureOfferPlan,
  keyForErrorCheck?: string,
  subIndex?: number,
  meter?: string
) => ({
  type: PrivateOfferCreationActionTypes.UPDATE_PRODUCT_PLAN_ON_PRIVATE_OFFER,
  payload: { index, productId, plan, keyForErrorCheck, subIndex, meter },
})

export const getDimensionFromProduct = (productPlan?: ProductPlan) => {
  if (productPlan) {
    return (productPlan?.dimensions as CommonDimensionField[])?.map(
      ({ currency, label, name, id, price }) => ({
        currency,
        label,
        name,
        id,
        price,
      })
    )
  }
  return []
}
