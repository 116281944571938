import { AxiosResponse } from 'axios'
import { camelize } from 'casing'
import { fetchOrganizationAccounts } from '../../../flyout/api/cmcm'
import { RootState, AppDispatch } from '../../../store'
import { actionTypeWrapper } from '../../utils/actionTypeWrapper'
import { getErrorMessages } from '../../utils/error'
import { errorLogger } from '../../utils/errorLogger'
import { isAdminRole } from '../../utils/isAdminRole'
import { updateAppAlert } from '../appAlert/actions'
import { startLoading, stopLoading } from '../loading/actions'
import { LoadingTypes } from '../loading/reducer'
import { PartnerType } from '../partner/action'
import { ProductType } from '../partner/reducer'

import { CloudType } from '../types'
import { Account } from './reducer'

export enum CLOUD_ACCOUNT_ACTIONS {
  SET_ACCOUNTS = 'SET_ACCOUNTS',
}

export const setAccounts = (accounts: Account[]) => ({
  type: CLOUD_ACCOUNT_ACTIONS.SET_ACCOUNTS,
  payload: accounts,
})

export const fetchAccounts =
  (cloudType: CloudType, productId: ProductType) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.CLOUD_ACCOUNTS))
    try {
      const state = getState()
      const isAdmin = isAdminRole(state.userProfile.userProfile)
      const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
      const organizationId =
        state.PartnerData?.[partnerType]?.partnerData?.partnerId || ''
      const response = await fetchOrganizationAccounts(
        organizationId,
        cloudType,
        productId
      )
      await dispatch(
        actionTypeWrapper(productId, setAccounts(camelize(response.data)))
      )
    } catch (e) {
      const errorMessage = getErrorMessages([
        'Unable to fetch organization accounts',
      ])(e as AxiosResponse<ErrorResponse>)

      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.CLOUD_ACCOUNTS))
    }
  }
