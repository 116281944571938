import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  MeteringSuccessMessage,
  RequestFailureMessage,
} from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { camelize } from 'casing'
import {
  Contract,
  ContractListingDimensionFields,
  ContractsListingResponse,
  Entitlement,
} from './reducer'
import { getContractsData, postContractsData } from '../../api/markeplace'
import { getErrorMessages } from '../../../common/utils/error'
import { PlanType } from '@labrav/react-components'
import { defaultNow, DefaultNowType } from '../../../common/utils/dateTimeNow'
import { errorLogger } from '../../../common/utils/errorLogger'
import { AppDispatch, RootState } from '../../../store'
import { CloudMarketplace } from '../productsListing/reducer'
import { getAzureContractsData, postAzureContractsData } from '../../api/azure'
import { getGCPContractsData, postGCPContractsData } from '../../api/gcp'
import { get } from 'lodash'

export enum ContractsListingActionTypes {
  SET_CONTRACTS = 'SET_CONTRACTS',
  UPDATE_ENTITLEMENTS_OR_DIMENSIONS = 'UPDATE_ENTITLEMENTS_OR_DIMENSIONS',
  CLEAR_CONTRACTS = 'CLEAR_CONTRACTS',
}

const getAPIBasedOnCloud = async (
  partnerId: string,
  productId: string,
  cloud: CloudMarketplace
) => {
  switch (cloud) {
    case 'AWS': {
      return getContractsData(partnerId, productId)
    }
    case 'AZURE': {
      return getAzureContractsData(partnerId, productId)
    }
    case 'GCP': {
      return getGCPContractsData(partnerId, productId)
    }
  }
}

const updateResponseDataForCloudProvider = (
  contracts: Contract[],
  cloudProvider: string
): Contract[] => {
  return contracts.map(contract => {
    if (cloudProvider === 'AWS' && contract.entitlements) {
      return {
        ...contract,
        entitlements: {
          ...contract.entitlements,
          rows: contract.entitlements.rows.map(row => ({
            ...row,
            metering_quantity_consumed: null,
          })),
        },
      }
    } else if (cloudProvider === 'AZURE' && contract.dimensions) {
      return {
        ...contract,
        dimensions: {
          ...contract.dimensions,
          rows: contract.dimensions.rows.map(row => ({
            ...row,
            metering_quantity_consumed: null,
          })),
        },
      }
    } else if (cloudProvider === 'GCP' && contract.plan) {
      return {
        ...contract,
        plan: {
          ...contract.plan,
          dimensions: contract.plan.dimensions.map(dimension => ({
            ...dimension,
            metering_quantity_consumed: null,
          })),
        },
      }
    } else {
      return contract
    }
  })
}

export const getContracts =
  (partnerId: string, productId: string, cloudProvider: CloudMarketplace) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(clearContracts())
    try {
      const response = await getAPIBasedOnCloud(
        partnerId,
        productId,
        cloudProvider
      )

      let responseData = response?.data
      if (cloudProvider === 'GCP') {
        responseData = {
          contracts: response?.data,
        }
      }
      const updatedContracts = updateResponseDataForCloudProvider(
        responseData.contracts.rows,
        cloudProvider
      )

      responseData = {
        ...responseData,
        contracts: {
          ...responseData.contracts,
          rows: updatedContracts,
        },
      }

      if (response?.data) {
        await dispatch(setContracts(camelize(responseData)))
      }
    } catch (error) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setContracts = (contracts: ContractsListingResponse) => ({
  type: ContractsListingActionTypes.SET_CONTRACTS,
  payload: contracts,
})

export const clearContracts = () => ({
  type: ContractsListingActionTypes.CLEAR_CONTRACTS,
})

export interface UpdateEntitlementsOrDimensionsValue {
  keyName: 'entitlements' | 'dimensions' | 'plan'
  values: string
  meteringId: string
}

export const updateEntitlementsOrDimensions = (
  contractId: string,
  { keyName, meteringId, values }: UpdateEntitlementsOrDimensionsValue
) => ({
  type: ContractsListingActionTypes.UPDATE_ENTITLEMENTS_OR_DIMENSIONS,
  payload: { contractId, keyName, meteringId, values },
})

const callSendContractsDataAPIBasedOnCloud = async (
  objectToSend: Record<string, unknown>,
  partnerId: string,
  selectedCloudProvider: CloudMarketplace
) => {
  switch (selectedCloudProvider) {
    case 'AWS': {
      return await postContractsData(objectToSend, partnerId)
    }
    case 'AZURE': {
      return await postAzureContractsData(objectToSend, partnerId)
    }
    case 'GCP': {
      return postGCPContractsData(objectToSend, partnerId)
    }
  }
}

const getSuccessMsgForMetering = (
  cloud: CloudMarketplace,
  response: AxiosResponse<any> | undefined
) => {
  switch (cloud) {
    case 'AWS': {
      return MeteringSuccessMessage
    }
    case 'AZURE': {
      return response?.data?.detail || MeteringSuccessMessage
    }
    case 'GCP': {
      return MeteringSuccessMessage
    }
    case 'REDHAT': {
      return ''
    }
  }
}

export const sendContractsData =
  (contractsData: Contract[], partnerId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const selectedCloudProvider = get(
        getState(),
        'productListingFilters.cloudMarketplace'
      )
      const additionalPayloadData =
        selectedCloudProvider === 'AWS' || selectedCloudProvider === 'GCP'
          ? {
              cloud_marketplace: get(contractsData, '[0].cloudMarketplace'),
              cloud_marketplace_product_id: get(
                contractsData,
                '[0].cloudMarketplaceProductId'
              ),
            }
          : {}
      const objectToSend: Record<string, unknown> = {
        product_id: get(contractsData, '[0].productId'),
        ...additionalPayloadData,
      }

      objectToSend.usage = getPayload(
        contractsData,
        defaultNow,
        selectedCloudProvider
      )
      const response = await callSendContractsDataAPIBasedOnCloud(
        objectToSend,
        partnerId,
        selectedCloudProvider
      )
      await dispatch(
        updateAppAlert({
          message: getSuccessMsgForMetering(selectedCloudProvider, response),
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      dispatch(
        getContracts(
          partnerId,
          contractsData[0].productId,
          selectedCloudProvider
        ) as never
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

const meteringCheck = (
  row: ContractListingDimensionFields | Entitlement,
  shouldCheckAdditional?: boolean
) => {
  const checkMeteringQunatity = !isNaN(row.meteringQuantityConsumed ?? NaN)
  return shouldCheckAdditional
    ? checkMeteringQunatity &&
        (row as Entitlement).dimensionIsAdditionalUsageAllowed
    : checkMeteringQunatity
}

const shouldIncludeMetering = (
  contract: Contract,
  keyName: 'entitlements' | 'dimensions',
  shouldCheckAdditional?: boolean
) => {
  const meteringRows = get(contract, keyName)?.rows || []
  return meteringRows.some(row => meteringCheck(row, shouldCheckAdditional))
}

const mappingContractUsage = (
  contract: Contract,
  keyName: 'entitlements' | 'dimensions',
  defaultNow: DefaultNowType,
  shouldCheckAdditional?: boolean
) => {
  return contract?.[keyName]?.rows
    .filter(row => meteringCheck(row, shouldCheckAdditional))
    .map(row => ({
      dimension_id: row.dimensionId,
      metering_quantity_consumed: row.meteringQuantityConsumed,
      metering_timestamp: defaultNow(),
    }))
}

export const getPayload = (
  tempObject: Contract[],
  defaultNow: DefaultNowType,
  selectedCloudProvider: CloudMarketplace
) => {
  const tempUsage: Record<string, unknown> = {}
  if (selectedCloudProvider === 'AWS') {
    for (const contract of tempObject) {
      if (contract.productType === PlanType.SAAS_SUBSCRIPTION) {
        /* istanbul ignore next */
        const shouldIncludeForMetering = shouldIncludeMetering(
          contract,
          'entitlements'
        )
        if (shouldIncludeForMetering) {
          tempUsage[contract.cloudMarketplaceContractId] = mappingContractUsage(
            contract,
            'entitlements',
            defaultNow
          )
        }
      } else if (contract.productType === PlanType.SAAS_CONTRACT) {
        /* istanbul ignore next */
        const shouldIncludeForMetering = shouldIncludeMetering(
          contract,
          'entitlements',
          true
        )
        if (shouldIncludeForMetering) {
          tempUsage[contract.cloudMarketplaceContractId] = mappingContractUsage(
            contract,
            'entitlements',
            defaultNow,
            true
          )
        }
      }
    }
  } else if (selectedCloudProvider === 'AZURE') {
    for (const contract of tempObject) {
      const shouldIncludeForMetering = shouldIncludeMetering(
        contract,
        'dimensions'
      )
      if (shouldIncludeForMetering) {
        tempUsage[contract.cloudMarketplaceContractId] = mappingContractUsage(
          contract,
          'dimensions',
          defaultNow
        )
      }
    }
  } else if (selectedCloudProvider === 'GCP') {
    for (const contract of tempObject) {
      const shouldIncludeForMetering = get(
        contract,
        'plan.dimensions',
        []
      ).some(
        dimensionItem => !isNaN(dimensionItem.meteringQuantityConsumed ?? NaN)
      )
      if (shouldIncludeForMetering && contract.cloudMarketplaceBuyerAccountId) {
        tempUsage[contract.cloudMarketplaceBuyerAccountId] =
          contract?.plan?.dimensions
            .filter(row => meteringCheck(row))
            .map(row => ({
              dimension_id: row.name,
              metering_quantity_consumed: `${row.meteringQuantityConsumed}`,
              metering_timestamp: defaultNow(),
            }))
      }
    }
  }

  return tempUsage
}
