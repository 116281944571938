import { API } from '../../common/api'
import {
  azureEngineServiceUrl,
  cloudAccountServiceUrl,
  crmServiceUrl,
} from '../../common/utils/constants'
import {
  AzureAccountDetails,
  AzureBackendAccountDetails,
} from '../modules/productAccount/reducer'

const mapAzBE2FE: (
  a: AzureBackendAccountDetails
) => AzureAccountDetails = details => ({
  id: details.id,
  name: details.company_name,
  MPNID: details.mpn_id,
  industries: details.industry,
  microsoftPartnerType: details.partner_type,
  ISV: details.is_isv,
  grantConsent: true,
  terms: true,
})

export const fetchAzureAccountDetails = (parnterId: string) =>
  API.get(`${azureEngineServiceUrl}/partners/${parnterId}`)
    .then(({ data }) => data as AzureBackendAccountDetails)
    .then(mapAzBE2FE)

export const deactivateAzureAccount = (
  partnerId: string,
  accountId: string,
  payload: Record<string, unknown>
) => {
  return API.put(
    `${cloudAccountServiceUrl}/azure/organizations/${partnerId}/accounts/${accountId}`,
    payload
  )
}

export const fetchCRMConfiguration = (crmId: string) =>
  API.get(`${crmServiceUrl}/crms/${crmId}`)
