import { camelize } from 'casing'
import {
  fetchUserProfile,
  patchUserProfile as oktaPatchUserProfile,
  patchUserProfileIdentityService,
} from '../../../common/api'
import { RootState, AppDispatch } from '../../../store'
import { errorLogger } from '../../utils/errorLogger'
import {
  UserUpdationFailureMessage,
  UserUpdationSuccessMessage,
} from '../../utils/messagesContants'
import { renameKey } from '../../utils/renameKey'
import { Role } from '../../utils/roles'
import { updateAppAlert } from '../appAlert/actions'
import { AppAlertState } from '../appAlert/reducer'
import { startLoading, stopLoading } from '../loading/actions'
import { LoadingTypes } from '../loading/reducer'
import { AuthProvider } from '../../../oppsync/modules/userList/action'
import { getErrorMessages } from '../../utils/error'
import { AxiosError } from 'axios'

export enum UserProfileActionTypes {
  UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE',
  UPDATE_USER_PROFILE_ERROR = 'UPDATE_USER_PROFILE_ERROR',
  SET_OKTA_USER_ID = 'SET_OKTA_USER_ID',
  SET_DEFAULT_TIMEZONE = 'SET_DEFAULT_TIMEZONE',
}

const errorMappings: Record<string, string> = {
  'Old Password is not correct': 'Current Password is not correct',
}
export interface UserProfile {
  id?: string
  sub?: string
  givenName?: string
  familyName?: string
  firstName?: string
  lastName?: string
  mobilePhone?: string
  email?: string
  timeZone?: string
  oldPassword?: string
  newPassword?: string
  roles?: Role[]
  defaultTimeZone?: string
  idpUserId?: string
}

export type IdentityServiceUser = {
  firstname: string
  lastname: string
  email: string
  timezone?: string
  id: string
  roles: Role[]
  idp_user_id: string
}

const mapProfileToIdentityServicePayload = (
  profile: UserProfile
): Partial<IdentityServiceUser> => ({
  firstname: profile.firstName || '',
  lastname: profile.lastName || '',
  email: profile.email || '',
  timezone: profile.timeZone || '',
})

const mapIdentityServicePayloadToProfile = (
  data: IdentityServiceUser
): UserProfile => ({
  firstName: data.firstname,
  givenName: data.firstname,
  familyName: data.lastname,
  lastName: data.lastname,
  email: data.email,
  timeZone: data.timezone,
  id: data.id,
  idpUserId: data.idp_user_id,
  roles: data.roles,
})

export const getUserProfile =
  (partnerId: string, userId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const userProfile = await fetchUserProfile(partnerId, userId)
      dispatch(setUserProfile(mapIdentityServicePayloadToProfile(userProfile)))
    } catch (e) {
      const error = e as AxiosError<ErrorResponse>
      const errorMessage = getErrorMessages(['Error getting user profile'])(
        error?.response
      )
      const newAlert: AppAlertState = {
        message: errorMessage,
        messageType: 'ERROR',
        autoClose: true,
      }
      dispatch(updateAppAlert(newAlert))

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const updateUserProfile =
  (
    partnerId: string,
    idpUserId: string,
    profile: UserProfile,
    authProvider: AuthProvider
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    const patchUserProfile =
      authProvider === AuthProvider.OKTA
        ? oktaPatchUserProfile
        : patchUserProfileIdentityService

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const payload =
      authProvider === AuthProvider.AUTH0
        ? mapProfileToIdentityServicePayload(profile)
        : profile
    return patchUserProfile(partnerId, idpUserId, payload)
      .then(res => {
        const updatedData = renameKey(res.data, 'timezone', 'time_zone')
        dispatch(setUserProfile(updatedData))
        const newAlert: AppAlertState = {
          message: UserUpdationSuccessMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        }
        dispatch(updateAppAlert(newAlert))
      })
      .catch((error: any) => {
        const errMessage =
          error.response.data?.message || UserUpdationFailureMessage
        dispatch(setUserProfileError(errorMappings[errMessage] || errMessage))
        const newAlert: AppAlertState = {
          message: errorMappings[errMessage] || errMessage,
          messageType: 'ERROR',
          autoClose: true,
        }
        dispatch(updateAppAlert(newAlert))

        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      })
      .finally(() => {
        dispatch(stopLoading(LoadingTypes.GENERAL))
      })
  }

export const setUserProfile = (data: UserProfile) => ({
  type: UserProfileActionTypes.UPDATE_USER_PROFILE,
  payload: camelize(data),
})

export const setOktaUserId = (id: string) => ({
  type: UserProfileActionTypes.SET_OKTA_USER_ID,
  payload: id,
})

export const setUserProfileError = (error: any) => ({
  type: UserProfileActionTypes.UPDATE_USER_PROFILE_ERROR,
  payload: error,
})

export const setDefaultTimeZone = (defaultTimeZone: string) => ({
  type: UserProfileActionTypes.SET_DEFAULT_TIMEZONE,
  payload: defaultTimeZone,
})
