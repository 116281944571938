import { PlanType } from '@labrav/react-components'
import { CloudMarketplace, Product } from '../productsListing/reducer'
import { FlyoutFunction } from './reducer'
import { AppDispatch, RootState } from '../../../store'
import { AnyAction } from 'redux'
import {
  clearResaleAuthorizations,
  setResaleAuthorizationsPageNumber,
} from '../../../admin/modules/resaleAuthorizations/actions'
import {
  clearAllRevenueData,
  setPageOneForRevenue,
} from '../revenueInsights/actions'
import { setPageNumber as setPageNumberForPrivateOffers } from '../privateOffer/actions'
import { clearPrivateOffers } from '../privateOffer/actions'
import { setSelectedProductOnListing } from '../productsListing/actions'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { isResaleProduct } from '../../utils/privateOfferOrResaleHelperFunctions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { startLoading, stopLoading } from '../../../common/modules/loading/actions'
export enum ProductListingFilterType {
  SET_CLOUD_MARKETPLACE = 'SET_CLOUD_MARKETPLACE',
}

export const setCloudMarketplaceProductListing = (cloud: CloudMarketplace) => ({
  type: ProductListingFilterType.SET_CLOUD_MARKETPLACE,
  payload: cloud,
})

const getProductFilterListData = (products: Product[]) => {
  return [
    {
      label: 'All products',
      value: 'allProducts',
      key: 'allProducts',
    },
    ...products.map(product => ({
      label: product.productName,
      value: product.productId,
      key: product.productId,
    })),
  ]
}

export const getFlyoutProductsListBasedOnFunction = (
  products: Product[],
  functionType: FlyoutFunction
) => {
  switch (functionType) {
    case FlyoutFunction.REVENUE:
    case FlyoutFunction.PRIVATE_OFFERS: {
      return getProductFilterListData(products)
    }
    case FlyoutFunction.RESALE_AUTHORIZATIONS: {
      return getProductFilterListData(
        products.filter(product => isResaleProduct(product))
      )
    }
    default: {
      return []
    }
  }
}

export const updateProductIdOnFilters =
  (
    productId: string,
    cloudType: CloudMarketplace,
    functionType?: FlyoutFunction
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (functionType) {
      const cloudFilter = CloudProductFilterConstructor(functionType)
      await dispatch(cloudFilter().clearData() as unknown as AnyAction)
      await dispatch(cloudFilter().setPageAsOne() as unknown as AnyAction)
    }
    await dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(
      actionTypeWrapper(cloudType, setSelectedProductOnListing(productId))
    )
    await dispatch(stopLoading(LoadingTypes.GENERAL))
  }

export type CloudProductFiltersFunctionType =
  | (() => (dispatch: AppDispatch) => Promise<void>)
  | (() => AnyAction)

interface CloudProductFilters {
  clearData: CloudProductFiltersFunctionType
  setPageAsOne: CloudProductFiltersFunctionType
}

export const CloudProductFilterConstructor = (functionType: FlyoutFunction) => {
  switch (functionType) {
    case FlyoutFunction.REVENUE:
      return getCloudProductFilterRevenueActions
    case FlyoutFunction.PRIVATE_OFFERS:
      return getCloudgetCloudProductFilterPrivateOfferActions
    case FlyoutFunction.RESALE_AUTHORIZATIONS:
      return getCloudProductFilterResaleActions
    default:
      return getCloudProductFilterRevenueActions
  }
}

const getCloudProductFilterResaleActions: () => CloudProductFilters = () => ({
  clearData: clearResaleAuthorizations,
  setPageAsOne: () => setResaleAuthorizationsPageNumber(1),
})

const getCloudProductFilterRevenueActions: () => CloudProductFilters = () => ({
  clearData: clearAllRevenueData,
  setPageAsOne: setPageOneForRevenue,
})

const getCloudgetCloudProductFilterPrivateOfferActions: () => CloudProductFilters =
  () => ({
    clearData: clearPrivateOffers,
    setPageAsOne: () => setPageNumberForPrivateOffers(1),
  })
