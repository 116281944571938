import { Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { camelize, snakeize } from 'casing'
import { UserList, UserState } from './reducer'
import {
  fetchUsers,
  fetchUsersFromIdentityService,
  updateUserData,
  updateUserFromIdentityService,
} from '../../../common/api/index'
import { RequestFailureMessage } from '../../.../../../common/utils/messagesContants'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { InviteUserUpdateRequestSuccess } from '../../.../../../common/utils/messagesContants'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { errorLogger } from '../../../common/utils/errorLogger'
import { RootState } from '../../../store'
import { getErrorMessages } from '../../../common/utils/error'
import { PartnerType } from '../../../common/modules/partner/action'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'

export enum AuthProvider {
  OKTA = 'OKTA',
  AUTH0 = 'AUTH0',
}

export enum UserListActionType {
  SET_USERS = 'SET_USERS',
  SET_USER = 'SET_USER',
  SET_USER_FOR_BE = 'SET_USER_FOR_BE',
  SET_CLEAR_USER_BE = 'SET_CLEAR_USER_BE',
  SET_ERROR_MESS = 'SET_ERROR_MESS',
  SET_USERS_LIMIT = 'SET_USER_LIMIT',
  SET_USERS_PAGE_NUMBER = 'SET_USER_PAGE_NUMBER',
  SET_OPEN_USER_PANEL = 'SET_OPEN_USER_PANEL',
  SET_CLOSE_USER_PANEL = 'SET_CLOSE_USER_PANEL',
  SET_START_USER_LOADING = 'SET_START_USER_LOADING',
  SET_STOP_USER_LOADING = 'SET_STOP_USER_LOADING',
}

export interface GetUsersParams {
  partnerId: string
  pageNumber?: number
  pageSize?: number
  authProvider: AuthProvider
}

const errMapping: Record<string, string> = {
  'The API returned an error: Api validation failed: suspendUser. Causes: errorSummary: Cannot suspend a user that is not active':
    'User already has the status provided.',
  'The API returned an error: Api validation failed: unsuspendUser. Causes: errorSummary: Cannot unsuspend a user that is not suspended':
    'User already has the status provided.',
}

export const getUsers =
  (args: GetUsersParams, partnerObjectType: PartnerType = PartnerType.User) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { pageNumber, pageSize } = args
      dispatch(startLoading(LoadingTypes.GENERAL))
      let responseData: UserList
      if (args.authProvider == AuthProvider.AUTH0) {
        const { data } = await fetchUsersFromIdentityService(args)
        responseData = {
          count: data?.metadata?.total || 0,
          users: data?.data?.map((user: any) => ({
            id: user?.id,
            name: (user?.firstname || '') + ' ' + (user?.lastname || ''),
            email: user?.email,
            idpUserId: user?.idp_user_id,
            roles: user?.roles,
            status: user?.status,
            invitationStatus: user?.invitation_status,
            createdAt: user?.created_at,
          })),
        }
      } else {
        const { data } = await fetchUsers(args)
        responseData = data
      }
      dispatch(
        setUsers(
          responseData,
          partnerObjectType,
          pageSize as number,
          pageNumber as number
        )
      )
    } catch (err: unknown) {
      const errMess = getErrorMessages([RequestFailureMessage])(
        (err as AxiosError<ErrorResponse>).response
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)

      dispatch(setUsersErrorMess(errMess,partnerObjectType))
      dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const updateUser =
  (
    partnerId: string,
    userId: string,
    data: Partial<UserState>,
    authProvider: AuthProvider,
    updateSuccessMsg?: string,
    partnerObjectType: PartnerType = PartnerType.User
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(startUserLoading())
      if (authProvider === AuthProvider.OKTA) {
        await updateUserData(partnerId, userId, snakeize(data))
      } else {
        await updateUserFromIdentityService(partnerId, snakeize(data), userId)
      }
      dispatch(
        updateAppAlert({
          message: updateSuccessMsg || InviteUserUpdateRequestSuccess,
          messageType: 'SUCCESS',
          autoClose: true,
          sidePanelAlert: true,
        })
      )
      return {
        success: true,
      }
    } catch (err) {
      const errMess =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((err as AxiosError)?.response?.data as any)?.message ||
        RequestFailureMessage

      const globalState = getState()
      errorLogger({ globalState })(err as Error)

      dispatch(setUsersErrorMess(errMapping[errMess] || errMess,partnerObjectType))
      dispatch(
        updateAppAlert({
          message: errMapping[errMess] || errMess,
          messageType: 'ERROR',
          autoClose: true,
          sidePanelAlert: true,
        })
      )
    } finally {
      dispatch(stopUserLoading())
    }
  }

export const setUsers = (
  userList: UserList,
  partnerType: PartnerType = PartnerType.User,
  pageSize?: number,
  pageNumber?: number
) =>
  actionTypeWrapper(
    partnerType,
    setUsersWithoutWrapper(userList, pageSize, pageNumber)
  )
export const setUsersWithoutWrapper = (
  userList: UserList,
  pageSize?: number,
  pageNumber?: number
) => ({
  type: UserListActionType.SET_USERS,
  payload: { userList: camelize(userList), pageSize, pageNumber },
})

export const setUser = (
  userId: string,
  partnerType: PartnerType = PartnerType.User
) => actionTypeWrapper(partnerType, setUserWithoutWrapper(userId))

export const setUserWithoutWrapper = (userId: string) => ({
  type: UserListActionType.SET_USER,
  payload: userId,
})

export const setUserForBE = (
  user: Partial<UserState>,
  partnerType: PartnerType = PartnerType.User
) => actionTypeWrapper(partnerType, setUserForBEWithoutWrapper(user))

export const setUserForBEWithoutWrapper = (user: Partial<UserState>) => ({
  type: UserListActionType.SET_USER_FOR_BE,
  payload: user,
})

export const setUsersErrorMess = (
  message: string,
  partnerType: PartnerType = PartnerType.User
) => actionTypeWrapper(partnerType, setUserErrorMessWithoutWrapper(message))

export const setUserErrorMessWithoutWrapper = (message: string) => ({
  type: UserListActionType.SET_ERROR_MESS,
  payload: message,
})

export const setUsersPageSize = (
  pageSize: number,
  partnerType: PartnerType = PartnerType.User
) => actionTypeWrapper(partnerType, setUserPageSizeWithoutWrapper(pageSize))

export const setUserPageSizeWithoutWrapper = (pageSize: number) => ({
  type: UserListActionType.SET_USERS_LIMIT,
  payload: pageSize,
})

export const setUsersPageNumber = (
  pageNumber: number,
  partnerType: PartnerType = PartnerType.User
) =>
  actionTypeWrapper(partnerType, setUsersPageNumberWithoutWrapper(pageNumber))

export const setUsersPageNumberWithoutWrapper = (pageNumber: number) => ({
  type: UserListActionType.SET_USERS_PAGE_NUMBER,
  payload: pageNumber,
})

export const showUserSidePanel = (
  partnerType: PartnerType = PartnerType.User
) => actionTypeWrapper(partnerType, showUserSidePanelWithoutWrapper())

export const showUserSidePanelWithoutWrapper = () => ({
  type: UserListActionType.SET_OPEN_USER_PANEL,
})

export const hideUserSidePanel = () => ({
  type: UserListActionType.SET_CLOSE_USER_PANEL,
})

export const startUserLoading = (
  partnerType: PartnerType = PartnerType.User
) => actionTypeWrapper(partnerType, startUserLoadingWithoutWrapper())

export const startUserLoadingWithoutWrapper = () => ({
  type: UserListActionType.SET_START_USER_LOADING,
})
export const stopUserLoading = () => ({
  type: UserListActionType.SET_STOP_USER_LOADING,
})

export const clearUserDataBE = () => ({
  type: UserListActionType.SET_CLEAR_USER_BE,
})
