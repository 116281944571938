import { AxiosResponse } from 'axios'
import { camelize, snakeize } from 'casing'
import { AnyAction } from 'redux'
import {
  fetchResellerAgreementData,
  getSingleResaleAuthorization,
  patchResellerData,
} from '../../../flyout/api/markeplace'
import { RootState, AppDispatch } from '../../../store'
import { getErrorMessages } from '../../../common/utils/error'
import {
  PatchResaleAuthorizationGeneralMessage,
  RequestFailureMessage,
  ResellerPatchSuccessMessage,
} from '../../../common/utils/messagesContants'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import {
  ResaleAuthorizationsResellerAgreements,
  ResaleAuthorizationsState,
  ResellerAgreementStatus,
} from './reducer'
import { errorLogger } from '../../../common/utils/errorLogger'

export enum ResaleAuthorizationsActionTypes {
  RESALE_SET_RESELLER_DATA = 'RESALE_SET_RESELLER_DATA',
  RESALE_SET_PAGE_NUMBER = 'RESALE_SET_PAGE_NUMBER',
  RESALE_SET_LIMIT = 'RESALE_SET_LIMIT',
  UPDATE_AGREEMENT_ID = 'UPDATE_AGREEMENT_ID',
  UPDATE_AGREEMENT_STATUS = 'UPDATE_AGREEMENT_STATUS',
  RESALE_CLEAR_RESELLER_DATA = 'RESALE_CLEAR_RESELLER_DATA',
  SET_CURRENT_RESALE_AUTHORIZATION = 'SET_CURRENT_RESALE_AUTHORIZATION',
  CLEAR_CURRENT_RESALE_AUTHORIZATION = 'CLEAR_CURRENT_RESALE_AUTHORIZATION',
}

export const getResellerAgreementData =
  ({
    partnerId,
    pageSize = 10,
    pageNumber = 1,
    productId,
    crmOpportunityId,
  }: {
    partnerId: string
    pageSize?: number
    pageNumber?: number
    productId?: string
    crmOpportunityId?: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const { data } = await fetchResellerAgreementData(
        partnerId,
        pageSize,
        pageNumber,
        'created_at',
        'desc',
        productId,
        crmOpportunityId
      )
      await dispatch(setResaleAuthorizations(camelize(data), pageSize, pageNumber))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setResaleAuthorizations = (
  agreements: ResaleAuthorizationsState,
  pageSize: number,
  pageNumber: number
) => ({
  type: ResaleAuthorizationsActionTypes.RESALE_SET_RESELLER_DATA,
  payload: { agreements, pageSize, pageNumber },
})

export const setResaleAuthorizationsPageNumber = (pageNumber: number) =>
  ({
    type: ResaleAuthorizationsActionTypes.RESALE_SET_PAGE_NUMBER,
    payload: pageNumber,
  } as unknown as AnyAction)

export const updateResaleAuthorizationsPageSize =
  ({
    partnerId,
    pageSize,
    productId,
  }: {
    partnerId: string
    pageSize: number
    productId?: string
    crmOpportunityId?: string
  }) =>
  async (dispatch: AppDispatch) => {
    await dispatch(setResaleAuthorizationsPageSize(pageSize))
    await dispatch(
      getResellerAgreementData({
        partnerId,
        pageSize,
        pageNumber: 1,
        productId,
      }) as unknown as AnyAction
    )
  }

export const setResaleAuthorizationsPageSize = (pageSize: number) => ({
  type: ResaleAuthorizationsActionTypes.RESALE_SET_LIMIT,
  payload: pageSize,
})

export type UpdateResellerDataToBeSent = {
  status: ResellerAgreementStatus
  awsAgreementId: string
}
export const updateResellerData =
  (partnerId: string, agreementId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const agreement =
        getState().cppoReseller.awsResellerAgreements.find(
          agreement => agreement.id === agreementId
        ) || ({} as ResaleAuthorizationsResellerAgreements)

      const dataToBeSent = {
        status: agreement.status || ResellerAgreementStatus.IN_PROGRESS,
        awsAgreementId: agreement.awsAgreementId,
      }
      await patchResellerData(partnerId, agreementId, snakeize(dataToBeSent))
      dispatch(
        updateAppAlert({
          message: ResellerPatchSuccessMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
export const updateAgreementId = (
  agreementId: string,
  awsAgreementId: string
) => ({
  type: ResaleAuthorizationsActionTypes.UPDATE_AGREEMENT_ID,
  payload: { agreementId, awsAgreementId },
})
export const updateAgreementStatus = (
  agreementId: string,
  resellerAgreementStatus: ResellerAgreementStatus
) => ({
  type: ResaleAuthorizationsActionTypes.UPDATE_AGREEMENT_STATUS,
  payload: { agreementId, resellerAgreementStatus },
})

export const clearResaleAuthorizations = () => ({
  type: ResaleAuthorizationsActionTypes.RESALE_CLEAR_RESELLER_DATA,
})

export const getCurrentResaleAuthorization =
  (
    partnerId: string,
    resaleAuthorizationId: string,
    crmOpportunityId?: string
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(clearResaleAuthorizations())
    try {
      const { data } = await getSingleResaleAuthorization(
        partnerId,
        resaleAuthorizationId,
        crmOpportunityId
      )
      dispatch(setCurrentResaleAuthorization(camelize(data)))
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setCurrentResaleAuthorization = (data: Record<string, unknown>) =>
  ({
    type: ResaleAuthorizationsActionTypes.SET_CURRENT_RESALE_AUTHORIZATION,
    payload: data,
  } as unknown as AnyAction)

export const clearCurrentResaleAuthorization = () =>
  ({
    type: ResaleAuthorizationsActionTypes.CLEAR_CURRENT_RESALE_AUTHORIZATION,
  } as unknown as AnyAction)

export const updateResaleAuthorizationForUser =
  (
    resaleAuthorizationId: string,
    data: Record<string, unknown>,
    crmOpportunityId?: string
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      await patchResellerData(
        partnerId,
        resaleAuthorizationId,
        snakeize({ crmOpportunityId, ...data }),
        false
      )
      dispatch(
        updateAppAlert({
          message: PatchResaleAuthorizationGeneralMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      await dispatch(
        getCurrentResaleAuthorization(
          partnerId,
          resaleAuthorizationId
        ) as unknown as AnyAction
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
