import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  clearDraftProductCreationFail,
  clearDraftProductCreationSuccess,
  draftProductCreationFail,
  draftProductCreationSuccess,
  productCreationSuccess,
  RequestFailureMessage,
} from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { camelize, snakeize } from 'casing'
import { getErrorMessages } from '../../../common/utils/error'
import { errorLogger } from '../../../common/utils/errorLogger'
import { AppDispatch, RootState } from '../../../store'
import initialProduct from '../../../mocks/initialProduct.json'
import { get, isEmpty, isEqual } from 'lodash'
import { CloudType } from '../../../common/modules/types'
import {
  BuyerFormFieldsBackend,
  FlyoutProductAddEditSectionName,
  FlyoutProductSections,
  pageMarkerType,
  ContractDimension,
  FlyoutProductBuyerFormFields,
  ProductObjectDetail,
  NotificationRecipients,
} from './reducer'
import { FlyOutAWSProductListingType } from '../productsListing/reducer'
import { FlyOutListingUserType } from '../../../admin/modules/flyOutSyncs/reducer'
import { convertRGBAAlpha } from '../../../common/utils/convertRGBAAlpha'
import { DateTime } from 'luxon'
import {
  putFlyOutProductFormData,
  getFlyOutProductFormData,
} from '../../api/markeplace'
import { PlanType } from '@labrav/react-components'
import flyOutProductAgreementTerms from '../../../mocks/flyOutProductAgreementTerms.json'
import { getProductPageMarkerMapping } from '../../../common/utils/getPageMarkerMapping'
import { autoSaveThresoldTime } from '../../../common/utils/constants'
import { v4 as uuidv4 } from 'uuid'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { clearProductsListing, getProducts } from '../productsListing/actions'
import { convertToKebabCase } from '../../../common/utils/convertToKebabCase'

export enum FlyoutProductForm {
  SET_FLYOUT_PRODUCT_FORM = 'SET_FLYOUT_PRODUCT_FORM',
  SET_FLYOUT_PRODUCT_DETAIL_FORM = 'SET_FLYOUT_PRODUCT_DETAIL_FORM',
  UPDATE_FLYOUT_PRODUCT_FORM = 'UPDATE_FLYOUT_PRODUCT_FORM',
  SET_FLYOUT_PRODUCT_LISTING_TYPE = 'SET_FLYOUT_PRODUCT_LISTING_TYPE',
  SET_ERROR_ON_FINAL_SUBMIT = 'SET_ERROR_ON_FINAL_SUBMIT',
}

const initialProductJson = { ...camelize(initialProduct) }

export const prePopulatedFieldLandingPage: Record<string, unknown> = {
  headingMessage: 'Welcome! Fill the information below to set up your account!',
  submissionSuccessMessage:
    'Thank you for completing the form. You will now be redirected to signup page.',
  emailCustomMessage:
    'Thank you for subscribing, we hope you enjoy using our product. If you have any questions, feel free to contact our customer support team!',
  existingBuyerMessage:
    'You are already a registered user in our system. Redirecting you to our login page.',
  existingBuyerRedirectSeconds: 5,
  redirectSeconds: 5,
}

export const PRODUCT_DIMENSIONS_ZERO_VALUE = {
  name: '',
  label: '',
  price: null,
  price1m: null,
  price12m: null,
  price24m: null,
  price36m: null,
  additionalUsagePrice: null,
  subscriptionPrice: null,
  labelDescription: '',
  isAdditionalUsageAllowed: false,
  additionalUsageDescription: '',
}

const getInitialProductBasedOnCloud = (
  cloudType: CloudType,
  awsProductType?: FlyOutAWSProductListingType,
  listingType?: FlyOutListingUserType
) => {
  switch (cloudType) {
    case 'AWS': {
      return {
        ...initialProductJson,
        productDetails: {
          ...initialProductJson.productDetails,
          ...(awsProductType === FlyOutAWSProductListingType.SAAS
            ? { productType: PlanType.SAAS_CONTRACT }
            : {}),
          ...(listingType === FlyOutListingUserType.MIGRATED
            ? { productType: PlanType.SAAS_CONTRACT }
            : {}),
          listingType: listingType,
        },
      }
    }
    case 'AZURE': {
      return {}
    }
    case 'GCP': {
      return {}
    }
    case 'REDHAT': {
      return {}
    }
  }
}

type GetProductInCreationArgs = {
  partnerId: string
  cloudType: CloudType
  productId?: string
  awsProductType?: FlyOutAWSProductListingType
  listingType: FlyOutListingUserType
}

export const getProductInCreation =
  ({
    partnerId,
    cloudType,
    productId,
    awsProductType,
    listingType,
  }: GetProductInCreationArgs) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.FLYOUT_PRODUCT_DETAIL))
    try {
      if (isEmpty(productId)) {
        const initialProduct = getInitialProductBasedOnCloud(
          cloudType,
          awsProductType,
          listingType
        )
        await dispatch(setProductFormListingType(listingType))
        await dispatch(setProductFormData(initialProduct))
      } else {
        const response = await getFlyOutProductFormData({
          partnerId,
          productId,
        })
        if (response) {
          const camelizedProductData = JSON.parse(
            JSON.stringify(get(camelize(response), 'data') || {}).replaceAll(
              '\\\\"',
              '"'
            )
          )
          const transformedProduct = await transformGetProductData(
            camelizedProductData.detailsJson,
            camelizedProductData.lockMetadata
          )
          const user = getState().userProfile
          const pageMarkerMapping: Record<string, string> =
            getProductPageMarkerMapping(false)
          const timeDiff = Math.ceil(
            DateTime.now().diff(
              transformedProduct?.lockMetadata?.lastActivityAt
                ? DateTime.fromISO(
                    transformedProduct?.lockMetadata?.lastActivityAt
                  )
                : DateTime.now(),
              ['seconds']
            ).seconds
          )
          if (
            timeDiff > autoSaveThresoldTime ||
            isEmpty(transformedProduct?.lockMetadata?.lastActivityAt)
          ) {
            const newWindowId = uuidv4()
            window.name = newWindowId
            transformedProduct.lockMetadata = {
              ...transformedProduct.lockMetadata,
              windowId: newWindowId,
              lockedBy: user.userProfile.id,
              lockedByName: `${user.userProfile.firstName} ${user.userProfile.lastName}`,
              lockByEmail: user.userProfile.email,
              lockAcquisitionProbe: true,
            }
            await dispatch(
              sendFlyoutProduct({
                partnerId,
                isAutoSave: true,
                productForm: transformedProduct,
                pageMarkerMapping,
                page: FlyoutProductAddEditSectionName.NOTIFICATIONS_RECIPIENTS,
                productId,
                status: 'lockAcquire',
              })
            )
          } else {
            await dispatch(setProductFormData(transformedProduct))
          }
        }
      }
    } catch (error) {
      await dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: false,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.FLYOUT_PRODUCT_DETAIL))
    }
  }

export const getProductDetail =
  ({ partnerId, productId }: GetProductInCreationArgs) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.FLYOUT_PRODUCT_DETAIL))
    try {
      const response = await getFlyOutProductFormData({
        partnerId,
        productId,
      })
      if (response) {
        const camelizedProductDetailData = JSON.parse(
          JSON.stringify(get(camelize(response), 'data') || {}).replaceAll(
            '\\\\"',
            '"'
          )
        )
        const transformedProductDetail =
          !isEmpty(camelizedProductDetailData.detailsJson) &&
          !isEmpty(camelizedProductDetailData.lockMetadata)
            ? await transformGetProductData(
                camelizedProductDetailData.detailsJson,
                camelizedProductDetailData.lockMetadata
              )
            : {}
        const listingType =
          transformedProductDetail?.productDetails?.listingType
        const status = camelizedProductDetailData?.status

        const auditJson = response?.data?.audit_json || {}
        const productName = camelizedProductDetailData?.productName
        const marketplaceUrl = camelizedProductDetailData?.marketplaceUrl
        await dispatch(
          setProductDetailFormData({
            productForm: transformedProductDetail,
            listingType: listingType,
            status: status,
            auditJson: auditJson,
            productName: productName,
            marketplaceUrl: marketplaceUrl,
          })
        )
      }
    } catch (error) {
      await dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: false,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.FLYOUT_PRODUCT_DETAIL))
    }
  }

export const setProductFormData = (
  productFormData: Record<string, unknown>
) => ({
  type: FlyoutProductForm.SET_FLYOUT_PRODUCT_FORM,
  payload: productFormData,
})
export const setProductDetailFormData = (
  productDetailFormData: ProductObjectDetail
) => ({
  type: FlyoutProductForm.SET_FLYOUT_PRODUCT_DETAIL_FORM,
  payload: productDetailFormData,
})
export type FlyOutProductFormChangeType = {
  sectionName: FlyoutProductAddEditSectionName
  keyName: string
  value: string | boolean
}
export const updateProductFormData = (data: FlyOutProductFormChangeType) => ({
  type: FlyoutProductForm.UPDATE_FLYOUT_PRODUCT_FORM,
  payload: data,
})
export const setProductFormListingType = (type: FlyOutListingUserType) => ({
  type: FlyoutProductForm.SET_FLYOUT_PRODUCT_LISTING_TYPE,
  payload: type,
})
export const setErrorOnFinalSubmit = (data: string) => ({
  type: FlyoutProductForm.SET_ERROR_ON_FINAL_SUBMIT,
  payload: data,
})

interface SendFlyoutProductProps {
  isAutoSave: boolean
  productForm: FlyoutProductSections
  pageMarkerMapping: Record<string, string>
  page: FlyoutProductAddEditSectionName
  partnerId: string
  productId?: string
  status?: string
  callBackFlyoutProduct?: (productId: string) => void
  isClear?: boolean
  redirectToListing?: () => void
}

export const sendFlyoutProduct =
  ({
    partnerId,
    isAutoSave,
    productForm,
    pageMarkerMapping,
    page,
    productId,
    status,
    callBackFlyoutProduct,
    isClear,
    redirectToListing,
  }: SendFlyoutProductProps) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      !isAutoSave &&
        (await dispatch(
          startLoading(LoadingTypes.SUBMIT_FLYOUT_PRODUCT_DETAIL)
        ))
      const getProductStateData =
        getState()?.flyoutProductInCreation.currentProductInCreation.productForm
      const isFormUpdated = isChangeInProductData(
        productForm,
        getProductStateData
      )
      const sendProductPayload = processFlyoutProduct({
        productForm,
        isAutoSave,
        pageMarkerMapping,
        page,
        isFormUpdated,
        status,
        productId,
      })
      const productPayload = JSON.parse(
        JSON.stringify(sendProductPayload).replaceAll('\\"', '\\\\\\"')
      )

      const response = await putFlyOutProductFormData({
        data: productPayload,
        partnerId,
        productId,
      })
      if (response) {
        const camelizedProductData = JSON.parse(
          JSON.stringify(get(camelize(response), 'data') || {}).replaceAll(
            '\\\\"',
            '"'
          )
        )
        const transformedProduct = await transformGetProductData(
          camelizedProductData.detailsJson,
          camelizedProductData.lockMetadata
        )
        await dispatch(setProductFormData(transformedProduct))

        const successMessage = transformedProduct?.completed?.value
          ? productCreationSuccess
          : draftProductCreationSuccess
        if (!isAutoSave || (isAutoSave && isClear)) {
          await dispatch(
            updateAppAlert({
              message: isClear
                ? clearDraftProductCreationSuccess
                : successMessage,
              messageType: 'SUCCESS',
              autoClose: true,
            })
          )
        }
        if (transformedProduct?.completed?.value && redirectToListing) {
          await dispatch(
            actionTypeWrapper(CloudType.AWS, clearProductsListing())
          )
          await dispatch(getProducts(partnerId, CloudType.AWS))
          await redirectToListing()
        }
      }

      if (
        (isEmpty(productId) || status === 'lockAcquire') &&
        response &&
        callBackFlyoutProduct
      ) {
        await callBackFlyoutProduct(response.data.id)
      }
    } catch (error: any) {
      const allOtherPageMarkersTrue = getAllOtherPageMarkersTrue({
        sectionName: page,
        pageMarkerMapping,
        pageMarker: productForm[FlyoutProductAddEditSectionName.PAGE_MARKER],
        isMigrated:
          productForm[FlyoutProductAddEditSectionName.CLOUD_PRODUCT_DETAILS]
            .listingType === FlyOutListingUserType.MIGRATED,
      })
      if (!isAutoSave && allOtherPageMarkersTrue) {
        const errorMessage = getErrorMessages(['error Occured '])(
          error.response as AxiosResponse<ErrorResponse>
        )
        await dispatch(setErrorOnFinalSubmit(errorMessage))
      }
      if (!isAutoSave) {
        const errMessage: string = isClear
          ? clearDraftProductCreationFail
          : draftProductCreationFail
        const errorMessage = getErrorMessages([errMessage])(
          error.response as AxiosResponse<ErrorResponse>
        )

        await dispatch(stopLoading(LoadingTypes.SUBMIT_FLYOUT_PRODUCT_DETAIL))
        if (isEmpty(status)) {
          await dispatch(
            updateAppAlert({
              message: errorMessage,
              messageType: 'ERROR',
              autoClose: false,
            })
          )
        }
        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      } else {
        return error
      }
    } finally {
      !isAutoSave &&
        (await dispatch(stopLoading(LoadingTypes.SUBMIT_FLYOUT_PRODUCT_DETAIL)))
    }
  }

export const processFlyoutProduct = ({
  productForm,
  isAutoSave,
  pageMarkerMapping,
  page,
  isFormUpdated,
  status,
  productId,
}: {
  productForm: FlyoutProductSections
  isAutoSave: boolean
  pageMarkerMapping: Record<string, string>
  page: FlyoutProductAddEditSectionName
  isFormUpdated: boolean
  status?: string
  productId?: string
}) => {
  const {
    bgColor,
    buttonColor,
    buttonTextColor,
    buyerFormFields,
    ...restInfoCollectionData
  } = productForm[FlyoutProductAddEditSectionName.LANDING_PAGE_DETAILS]

  const { dimensions, additionalDimensions, ...restProductDimensions } =
    productForm[FlyoutProductAddEditSectionName.PRODUCT_DIMENSIONS]

  const updatedSupportResources =
    productForm.generalInformation?.resourceUrls?.filter(
      (resource: any) => resource.Text !== '' && resource.Url !== ''
    )

  const updatedDimensions = dimensions
    .filter((dimension: any) => dimension.name !== '')
    .map((dimension: any) => {
      const { checkBoxAdditionalUsageAllowed, ...updatedDimension } = dimension
      return {
        ...updatedDimension,
      }
    })

  const updatedAdditionalDimensions = (additionalDimensions || []).map(
    (dimension: any) => {
      const {
        id,
        checkBoxAdditionalUsageAllowed,
        ...aditionalDimensionsUpdated
      } = dimension
      return { ...aditionalDimensionsUpdated }
    }
  )

  const transformedBuyerField = buyerFormFields?.reduce((acc, field, index) => {
    const {
      title,
      dataType,
      isRequired,
      validationType,
      minLength,
      maxLength,
    } = field

    acc[title] = {
      displayOrder: index + 4,
      title,
      placeHolder: title,
      dataType,
      isCustom: true,
      isRequired,
      validationType,
      maxLength,
      minLength,
    }
    return acc
  }, {} as Record<string, BuyerFormFieldsBackend>)

  const allOtherPageMarkersTrue = getAllOtherPageMarkersTrue({
    sectionName: page,
    pageMarkerMapping,
    pageMarker: productForm[FlyoutProductAddEditSectionName.PAGE_MARKER],
    isMigrated:
      productForm[FlyoutProductAddEditSectionName.CLOUD_PRODUCT_DETAILS]
        .listingType === FlyOutListingUserType.MIGRATED,
  })

  const camelizeProductData = {
    ...productForm,
    generalInformation: {
      ...productForm.generalInformation,
      resourceUrls: updatedSupportResources,
    },
    infoCollectionFormMetaData: {
      ...restInfoCollectionData,
      bgColor: convertRGBAAlpha(bgColor, true),
      buttonColor: convertRGBAAlpha(buttonColor, true),
      buttonTextColor: convertRGBAAlpha(buttonTextColor, true),
    },
    buyerFormFields: {
      ...initialProductJson.buyerFormFields,
      ...transformedBuyerField,
    },
    productDimensions: {
      ...restProductDimensions,
      dimensions: [...updatedDimensions, ...updatedAdditionalDimensions],
    },
    lockMetadata: {
      ...productForm.lockMetadata,
      ...(isFormUpdated || status === 'lockAcquire' || isEmpty(productId)
        ? { lastActivityAt: DateTime.now().toUTC().toISO() }
        : {}),
      editingEnabled: status === 'lockAcquire' || isEmpty(productId),
    },
    ...(!isAutoSave && {
      pageMarker: {
        ...productForm.pageMarker,
        [camelize(pageMarkerMapping[page])]: true,
      },
      completed: {
        value: allOtherPageMarkersTrue,
      },
    }),
  }

  const { productDimensions, generalInformation, notificationRecipients } =
    camelizeProductData

  let updatedNotificationRecipients = {}
  Object.keys(notificationRecipients).forEach(keyName => {
    const kebabCaseKeyName = convertToKebabCase(keyName)
    updatedNotificationRecipients = {
      ...updatedNotificationRecipients,
      [kebabCaseKeyName]:
        notificationRecipients[
          keyName as string as keyof NotificationRecipients
        ],
    }
  })

  const snakeizeDimensions = productDimensions?.dimensions
    ? productDimensions?.dimensions.map((dimension: any) => {
        const { price1m, price12m, price24m, price36m, ...restProps } =
          dimension
        return {
          ...snakeize(restProps),
          price_1m: price1m || null,
          price_12m: price12m || null,
          price_24m: price24m || null,
          price_36m: price36m || null,
        }
      })
    : []

  const updatedResourceUrls = generalInformation.resourceUrls
    ? generalInformation.resourceUrls.map((resources: any) => {
        const { Text, Url } = resources
        return {
          Text: Text,
          Url: Url,
        }
      })
    : []
  const snakeizeProductInfo = { ...snakeize(camelizeProductData) }
  return {
    ...snakeizeProductInfo,
    product_dimensions: {
      ...snakeize(productDimensions),
      dimensions: snakeizeDimensions,
    },
    general_information: {
      ...snakeizeProductInfo.general_information,
      resource_urls: updatedResourceUrls,
    },
    notification_recipients: {
      ...updatedNotificationRecipients,
    },
  }
}

export const getAllOtherPageMarkersTrue = ({
  pageMarker,
  pageMarkerMapping,
  sectionName,
  isMigrated,
}: {
  pageMarker?: pageMarkerType
  pageMarkerMapping: Record<string, string>
  sectionName: FlyoutProductAddEditSectionName
  isMigrated: boolean
}) =>
  (pageMarker ? Object.keys(pageMarker) : []).every(marker => {
    const currentSectionPageMarker = camelize(pageMarkerMapping[sectionName])

    if (isMigrated) {
      return marker !== currentSectionPageMarker && marker !== 'page2'
        ? pageMarker && pageMarker[marker]
        : true
    }

    return marker !== currentSectionPageMarker
      ? pageMarker && pageMarker[marker]
      : true
  })

const isChangeInProductData = (
  productData: FlyoutProductSections,
  getProductStateData: FlyoutProductSections
) => {
  const { lockMetadata: metaData, ...restProductData } = productData
  const { lockMetadata, ...restGetProductStateData } = getProductStateData
  return !isEqual(restProductData, restGetProductStateData)
}

const transformGetProductData = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  flyoutProductData: any,
  lockMetadata: any
) => {
  const { buyerFormFields } = flyoutProductData
  const excludedFields = ['email', 'firstName', 'companyName']
  const customFields = Object.keys(buyerFormFields || {})
    .filter(
      fieldName =>
        typeof buyerFormFields[fieldName] === 'object' &&
        buyerFormFields[fieldName] !== null &&
        !excludedFields.includes(fieldName)
    )
    .map(fieldName => {
      const typedFieldInfo = buyerFormFields[
        fieldName
      ] as FlyoutProductBuyerFormFields
      return {
        title: typedFieldInfo?.title,
        dataType: typedFieldInfo?.dataType,
        isRequired: typedFieldInfo?.isRequired,
        validationType: typedFieldInfo?.validationType,
        minLength: typedFieldInfo?.minLength,
        maxLength: typedFieldInfo?.maxLength,
      }
    })

  const selectedProductType = flyoutProductData?.productDetails?.productType
  const isSubscription = selectedProductType === PlanType.SAAS_SUBSCRIPTION

  const dimensions = flyoutProductData?.productDimensions?.dimensions || []

  const additionalUsageDimensionsList = dimensions.filter(
    (dimension: ContractDimension) =>
      !isSubscription ? dimension.isAdditionalUsageAllowed : true
  )
  const contractDimensionsList = isSubscription
    ? []
    : dimensions.filter(
        (dimension: ContractDimension) => !dimension.isAdditionalUsageAllowed
      )

  const contractDimensionsListWithCheckbox = contractDimensionsList.map(
    (dimension: ContractDimension) => {
      const foundDimension = additionalUsageDimensionsList.find(
        (d: any) => d.name === dimension.name
      )
      return { ...dimension, checkBoxAdditionalUsageAllowed: !!foundDimension }
    }
  )

  const additionalUsageDimensionsListWithCheckbox =
    additionalUsageDimensionsList.map((dimension: ContractDimension) => {
      const foundDimension = contractDimensionsList.find(
        (d: any) => d.name === dimension.name
      )
      return { ...dimension, checkBoxAdditionalUsageAllowed: !!foundDimension }
    })

  const infoCollection = { ...flyoutProductData.infoCollectionFormMetaData }
  const { bgColor, buttonColor, buttonTextColor } =
    flyoutProductData.infoCollectionFormMetaData
  Object.keys(infoCollection).forEach(key => {
    if (
      Object.keys(prePopulatedFieldLandingPage).includes(key) &&
      !Boolean(infoCollection[key])
    ) {
      infoCollection[key] = prePopulatedFieldLandingPage[key]
    }
  })

  return {
    ...flyoutProductData,
    infoCollectionFormMetaData: {
      ...flyoutProductData.infoCollectionFormMetaData,
      ...infoCollection,
      buyerFormFields: customFields,
      bgColor: convertRGBAAlpha(bgColor),
      buttonColor: convertRGBAAlpha(buttonColor),
      buttonTextColor: convertRGBAAlpha(buttonTextColor),
    },
    agreement: {
      ...(!isEmpty(flyoutProductData?.agreement?.terms)
        ? flyoutProductData?.agreement
        : camelize(flyOutProductAgreementTerms)),
    },
    productDimensions: {
      ...flyoutProductData.productDimensions,
      dimensions: contractDimensionsListWithCheckbox?.length
        ? contractDimensionsListWithCheckbox
        : [PRODUCT_DIMENSIONS_ZERO_VALUE],
      additionalDimensions: additionalUsageDimensionsListWithCheckbox,
    },
    lockMetadata: {
      ...lockMetadata,
    },
  }
}
