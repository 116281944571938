import React, { useEffect, useMemo } from 'react'

import { useLocation } from 'react-router-dom'
import { Product, setCurrentProduct } from '../../modules/partner/action'
import { ProductType } from '../../modules/partner/reducer'
import { useDispatch } from '../../../store'
import { actionTypeWrapper } from '../../utils/actionTypeWrapper'
import { ProductRoutingMap } from '../../utils/productRoutingMap'

export type CurrentProductWrapperProps = {
  children: React.ReactNode
  activeProducts: Product[]
}

export const CurrentProductWrapper: React.FC<CurrentProductWrapperProps> = ({
  children,
}) => {
  const location = useLocation().pathname
  const queryString = useLocation().search
  const dispatch = useDispatch()
  const productFromUrl = new URLSearchParams(queryString)
    .get('product')
    ?.toLowerCase()

  const currentSettingProduct = useMemo(
    () =>
      Object.keys(ProductRoutingMap).find(p =>
        location.toLowerCase().includes(p.toLowerCase())
      ),

    [location]
  )
  const isProductOnPath = !!currentSettingProduct

  const isSettingsPage = location.indexOf('settings') > -1
  useEffect(() => {
    if ((isProductOnPath || productFromUrl) && !isSettingsPage) {
      dispatch(
        actionTypeWrapper(
          'user',
          setCurrentProduct(
            (currentSettingProduct || productFromUrl) as ProductType
          )
        )
      )
    }
  }, [currentSettingProduct, isSettingsPage])

  return <>{children}</>
}
