import { Reducer } from 'redux'
import { FlyOutSyncsActionTypes } from './actions'

export enum FlyOutListingUserType {
  'NEW' = 'NEW',
  'MIGRATED' = 'MIGRATED',
  'MANAGED' = 'MANAGED',
}

export interface AwsFlyoutSellerMetadata {
  awsRoleArn: string
  awsCasS3BucketName: string
  awsCasSnsTopic: string
  awsCasRoleArn?: string
}

export interface FlyOutListingData {
  origin: FlyOutListingUserType
  sellerMetadata: AwsFlyoutSellerMetadata
  onboarded?: boolean
  awsAccountId?: string
  awsAccountName?: string
}
export const initialState = {
  onboarded: undefined,
  origin: '' as FlyOutListingUserType,
  sellerMetadata: {
    awsRoleArn: '',
    awsCasS3BucketName: '',
    awsCasSnsTopic: '',
    awsCasRoleArn: '',
  } as AwsFlyoutSellerMetadata,
  awsAccountId: '',
  awsAccountName: '',
}
type actionType = {
  type: FlyOutSyncsActionTypes.SET_FLYOUT_LISTING_DATA
  payload: {
    onboarded: boolean
    origin: FlyOutListingUserType
    sellerMetadata: AwsFlyoutSellerMetadata
    awsAccountId?: string
    awsAccountName?: string
  }
}

export const reducer: Reducer<FlyOutListingData, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FlyOutSyncsActionTypes.SET_FLYOUT_LISTING_DATA: {
      return {
        ...state,
        origin: action.payload.origin,
        sellerMetadata: action.payload?.sellerMetadata,
        onboarded: action.payload?.onboarded,
        awsAccountId: action.payload?.awsAccountId,
        awsAccountName: action.payload?.awsAccountName,
      }
    }
    default: {
      return state
    }
  }
}
