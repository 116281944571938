import { Divider, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import { useSelector } from '../../../store'
import { HeaderAlertComponent } from '../HeaderAlert/HeaderAlert'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { useStyles } from './HeaderWrapper.styles'
import clsx from 'clsx'

type HeaderWrapperProps = {
  title?: string
  titleIcon?: React.ReactNode
  actions?: React.FC
  pageYOffset?: number
  hideTitle?: boolean
}

const HeaderWrapper: React.FC<HeaderWrapperProps> = ({
  title,
  actions: Actions,
  titleIcon,
  pageYOffset = 0,
  hideTitle = false,
}) => {
  const [isSticky, setIsSticky] = useState(false)
  const titleHeaderEl = useRef<HTMLDivElement>(null)
  const loading = useSelector<boolean>(state =>
    Object.values(state.loading).some(count => count > 0)
  )
  const sidePanelAlert = useSelector<boolean | undefined>(
    state => state.appAlert.sidePanelAlert
  )
  useEffect(() => {
    if (titleHeaderEl && titleHeaderEl.current) {
      const offsetTopElement = titleHeaderEl.current.offsetTop

      if (pageYOffset > offsetTopElement) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }
  }, [titleHeaderEl, pageYOffset])

  const classes = useStyles()
  return (
    <>
      {!hideTitle && (
        <div className={clsx(classes.titleHeader, classes.stickyContainer)}>
          <div className={classes.appTitle}>
            <div className={classes.appTitleHeading}>
              {title ? (
                <>
                  <div className={classes.appTitleIcon}>{titleIcon}</div>
                  <Typography
                    variant="h1"
                    data-testid="heading"
                    className={classes.appTitleHeadingText}
                  >
                    {title}
                  </Typography>
                </>
              ) : null}
            </div>
            <div data-testid="actions" className={classes.appTitleActions}>
              {Actions ? <Actions /> : null}
            </div>
          </div>
          <Divider className={classes.divider} />
        </div>
      )}
      <div
        className={
          isSticky
            ? clsx(classes.titleHeader, classes.sticky)
            : classes.fixedContainer
        }
        ref={titleHeaderEl}
      >
        {loading && <ProgressBar width={'100%'} />}
        {!sidePanelAlert ? <HeaderAlertComponent /> : ''}
      </div>
    </>
  )
}

export default HeaderWrapper
